import React, { Component } from 'react'
import { Pagination, Modal,Icon} from 'antd';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import styles from './index.css'
import NHFetch from "../../../../utils/NHFetch";
// import { getFunctinoName } from "@/config/commonService";
// import { getHashParam } from "@/utils/utils";
import classNames from 'classnames'
export default class VideoColumn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            navList: [],//视频中心侧边导航list
            currentIndex: -1,//目前点击的左边导航
            currentPkid: "",//目前点击的左边导航的pkid
            title: "",//右侧部分主要标题
            tip: "",//每个视频的介绍说明
            total: "",//视频中心每一个侧边标题下的视频总数目
            pageSize: 9,//默认每页大小
            page: 1,//当前页,默认当前为第一页
            videoList: [],//当前页需要展示的视频列表，包含缩略图，视频地址，视频类型等信息
            currentSlt: -1,//右侧目前鼠标正悬浮的视频封面图
            currentVideo: -1,//右侧目前鼠标点击的视频
            videoVisible: false,//播放视频的modal
            videoPath: "",//当前播放视频的地址
            videoName: "",//当前播放视频的视频名称
        }
    }

    //首次渲染需要将左边导航以及右边标题等信息展示，当Pagination发生变化时，这些信息不用重新设置
    UNSAFE_componentWillMount() {
         //请求视频集
         const { page, pageSize } = this.state;
                let params = {
                    page:page-1,
                    size:pageSize,
                    // pkid:pkid,
                }
         NHFetch('/gdlm/xysp/getVideoByPage', 'get',params).then(res1 => {

            if (res1 && res1.code == 200) {
                let tip = res1.data.spjsm;//右边显示视频说明 
                this.setState({ 
                    currentIndex: 0, 
                    tip,
                    total:res1.data.totalElements,
                    videoList:res1.data.content
                })

            }


        })

    }


    //公共函数,当当前页以及每页大小改变时使用，只需要改变右边视频区域的视频列表以及当前Pagination相关的total
    commonVideo = () => {
        const { currentPkid: pkid, page, pageSize } = this.state;
        console.log("调用公共函数,当前页是:", page, "当前pkid是:", pkid);
        let params = {
            page:page-1,
            size:pageSize,
            // pkid:pkid,
        }
        //请求,根据当前选择的导航信息以及当前页和当前每页大小请求获取相应的视频列表信息
        NHFetch('/gdlm/xysp/getVideoByPage', 'get',params).then(res1 => {

            if (res1 && res1.code == 200) {
                let tip = res1.data.spjsm;//右边显示视频说明

                const { totalElements, content } = res1.data; //totalElements:总数 content:视频列表信息

                this.setState({
                    total:totalElements,
                    videoList:content,
                })

            }

        })
    }


    //动态生成显示图片部分
    generateVideoDiv = (videoList) => {
        console.log("videoList", videoList);
        const pre = `zuul/docrepo/download/file?attachmentId=`
        const { currentSlt, currentVideo, videoVisible,videoPath,videoName} = this.state;
        return videoList.map((item, index) => {
            return (
                <div
                    className={styles.video_display_div}
                    key={index}
                    onMouseOver={() => {
                        this.setState({
                            currentSlt: index
                        })
                    }
                    }
                    onMouseOut={() => {
                        this.setState({
                            currentSlt: -1
                        })
                    }
                    }
                    onClick={() => { this.playVideo(index, item.spdz, item.spmc, item.splx) }}

                >

                    { item.videoFlag ?<video
                                src={videoPath}
                                controls
                                style={{ width: "100%"}}
                            >
                                您的浏览器不支持 video 标签。
                </video>:<img width="100%" height="100%" src={`${pre}${item.slt}`} />} 
                            {item.videoFlag &&<div className={styles.video_explain}>
                                 {videoName}
                            </div>}
                    {
                        !item.videoFlag ? (
                            <span className={styles.playWarp}>
                                <span className={styles.playIcon}></span>
                            </span>
                        ) : null
                    }    
                    {
                        !item.videoFlag ? (
                            <div className={styles.video_title} >
                                {item.spmc}
                            </div>
                        ) : null
                    }
                </div>
            )
        })
    }
    //处理鼠标悬浮在图片上
    handleShowTitile = (index) => {
        console.log("鼠标悬浮", index);
        this.setState({
            currentSlt: index
        })
    }
    //处理鼠标移开某个图片
    handleNotShowTitle = () => {
        console.log("鼠标移出", index);
        this.setState({
            currentSlt: -1
        })
    }
    //处理播放点击的视频
    playVideo = (index, videoPath, videoName, splx) => {
        const {videoList} = this.state;
       
        console.log(index);
        //先配置再遍历
        if (splx == '1') {
            window.open(videoPath);
            return;
        }
        videoList.map((item,index1)=>{
            if(index==index1){
                item.videoFlag = true;
            }else{
                item.videoFlag = false;
            }
        })
        let videoIndex = (splx == '1' ? videoPath : `zuul/docrepo/download/file?attachmentId=${videoPath}`)
        this.setState({
            currentVideo: index,
            videoVisible: true,
            videoPath: videoIndex,
            videoName,
            videoList
        })
    }

    //处理关闭modal
    handleCancel = (e) => {
        console.log("关闭");
        this.setState({
            videoVisible: false,
        })
    }

    //处理当前页码改变
    handlePageChange = (page, pageSize) => {
        console.log("处理当前页码改变page-pageSize", page, pageSize, "当前pkid", this.state.currentPkid);
        this.setState({
            page,
        }, () => {
            this.commonVideo()
        })

    }
    //处理每页大小改变
    handleShowSizeChange = (current, pageSize) => {
        console.log("处理每页大小改变,current-size", current, pageSize, "当前pkid", this.state.currentPkid);
        this.setState({
            pageSize
        }, () => {
            this.commonVideo()
        })
    }
    render() {
        const {
            navList,
            title,
            tip,
            total,
            pageSize,
            videoList,
            videoVisible,
            videoPath,
            videoName,
            getFunctinoName
        } = this.state;

        return (
        <div className={styles.body}>
               <div className={styles.body}>
                <div className={classNames(styles.right_content,'subConentRight')}>
                    <div className={classNames(styles.boxTitle,'titleWarp')} >
                        <a className={classNames('titleWord')}>{title}</a>
                    </div>
                    <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '校园视频',url:'/xysp'}
                            ]}
                        />
                    <div className={styles.right_tip}>
                        <span> {tip}</span>
                    </div>
                    <div className={styles.video_display}>
                        {/* 动态生成视频展示部分*/}
                        {this.generateVideoDiv(videoList)}
                            
                    </div>
                    <div className={styles.right_pagination}>
                        <div className={styles.right_pageTip}>
                            每页{pageSize}条记录，共{total}条记录
                         </div>
                        <div className={styles.right_pagenation_page}>
                            <Pagination
                                size="small"
                                total={total}
                                showSizeChanger
                                showQuickJumper
                                defaultPageSize={pageSize}
                                onChange={this.handlePageChange}
                                pageSizeOptions={['9']}
                                onShowSizeChange={this.handleShowSizeChange}
                            />
                        </div>
                    </div>
                </div>

            </div>

            </div>
        )
    }
}
