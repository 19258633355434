import React from 'react'
import { DatePicker, Table, Upload, Icon, Form, message,Modal } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import '../index.css';
import { Flex } from '@components';
import { getLoginUser } from "@utils/NHCore";
import NHFetch from '@utils/NHFetch';
import NHImage from '../../../../components/common/NHImage';

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"
const FormItem = Form.Item

class IdentityCardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadImg: false,
            previewVisible: false,
            previewImage:'',
            txscListData: [],
            txData: '',
            identityCardFrontUrl: '',   //身份证正面
            identityCardOppositeUrl: '',   //身份证反面
            identityCardFrontOriginUrl:'',    //身份证正面源数据
            identityCardOppositeOriginUrl:'',   //身份证反面源数据

        }
    }
    componentDidMount() {
        this.props.setRef(this);
        this.getStudentPhotoBase64('70');
        this.getStudentPhotoBase64('71');
    }

    submitCheck =(flag) => {
        const submitCheck = this.props.submitCheck;
        if(typeof submitCheck ==="function"){
            submitCheck(flag);
        }
    }

    // 获取学生头像
    getStudentPhotoBase64 = (type) => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', {xsid: userId, type: type})
                .then(res => {
                    if (res && res.code === 200&&res.data){
                        if (type === '70'){
                            this.setState({
                                identityCardOppositeUrl: `data:image/jpeg;base64,${res.data}`,
                                identityCardOppositeOriginUrl: `data:image/jpeg;base64,${res.data}`,
                            })
                        }

                        if (type === '71'){
                            this.setState({
                                identityCardFrontUrl: `data:image/jpeg;base64,${res.data}`,
                                identityCardFrontOriginUrl: `data:image/jpeg;base64,${res.data}`,
                            })
                        }

                    }
                });

        }
    };

    beforeUpload = (file) => {

        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJPG) {
            message.error("You can only upload JPG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJPG && isLt2M;
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    // 上传图片
    handleUploadChange = (info,type) => {
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                console.log(`photoUUID-${type}`);
                this.setState({
                    [`photoUUID-${type}`] : photoUUID
                })
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>{
                if (type === 'front') {
                    this.setState({ identityCardFrontUrl:imageUrl })
                }
                if (type === 'opposite') {
                    this.setState({ identityCardOppositeUrl:imageUrl })
                }
            }
        );
    }

    onSaveIdentityCardFormData = (cb) => {
        cb(true);
        let data = [];
        console.log(this.state);
        if (this.state[`photoUUID-front`]) {
            data.push({ photoUUID: this.state[`photoUUID-front`],type: 71 })
        }
        if (this.state[`photoUUID-opposite`]) {
            data.push({ photoUUID: this.state[`photoUUID-opposite`],type: 70 })
        }
        this.submitCheck(true);
        console.log(data)
        this.props.cacheFormData('sfzjzsc',data,null, 1)
    }

    showPreview = url => {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    };

    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });
    render() {
        const { identityCardOppositeUrl,identityCardFrontUrl,previewVisible,previewImage  } = this.state;
        const { editable, isRequired, txscInitData, form } = this.props
        const userId = getLoginUser().userId;
        return (
            <Form layout="inline">
                <TitleTop
                    extra={editable}
                    title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>上传身份证件照</span>}
                    onEdit={type => {
                        this.setState({
                            uploadImg: true
                        });
                        this.submitCheck(false);
                    }}
                    onSave={cb => {
                        this.onSaveIdentityCardFormData(cb);
                    }}
                    onClose={() => {
                        this.setState({
                            identityCardFrontUrl: this.state.identityCardFrontOriginUrl,
                            identityCardOppositeUrl: this.state.identityCardOppositeOriginUrl,
                            uploadImg: false
                        });
                        this.submitCheck(true);
                    }}
                />
                <div styleName="sfzjzsc" className="_bg">
                    <Flex>
                        <Flex.Item>
                            <div>
                                <Upload
                                    name="photo"
                                    accept={'image/*'}
                                    showUploadList={false}
                                    action={uploadUrl + "?xsid=" + userId}
                                    beforeUpload={this.beforeUpload}
                                    onChange={(file)=> this.handleUploadChange(file,"front")}
                                >
                                    {
                                        identityCardFrontUrl ?

                                            <img
                                                src={identityCardFrontUrl}
                                                alt=""
                                                className='avatar'
                                                title="上传身份证件国徽面"
                                                //onClick={() => {this.showPreview(imageUrl)}}
                                            />

                                            : (
                                                <div styleName="upload-box">
                                                    <div styleName="upload-title">
                                                      上传身份证件国徽面
                                                    </div>
                                                </div>
                                            )
                                    }
                                </Upload>
                            </div>
                        </Flex.Item>
                        <Flex.Item>
                            <div>
                                <Upload
                                    name="photo"
                                    accept={'image/*'}
                                    showUploadList={false}
                                    action={uploadUrl + "?xsid=" + userId}
                                    beforeUpload={this.beforeUpload}
                                    onChange={(file)=> this.handleUploadChange(file,"opposite")}
                                >
                                    {
                                        identityCardOppositeUrl ?

                                            <img
                                                src={identityCardOppositeUrl}
                                                alt=""
                                                className="avatar"
                                                title="上传身份证件人像面"
                                            />

                                            : (
                                                <div styleName="upload-box">
                                                    <div styleName="upload-title">
                                                     上传身份证件人像面
                                                    </div>
                                                </div>
                                            )
                                    }
                                </Upload>
                            </div>
                        </Flex.Item>
                    </Flex>
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt='example' style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Form>
        )
    }
}
export default Form.create()(IdentityCardForm)