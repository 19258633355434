import React from 'react'
import { Table, Input, Icon, Form, Modal, message } from 'antd'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import TitleTop from '@pages/Assembly/titleTop'
import NHInputRender from '@components/common/NHInputPicker/render'
import { createUuid } from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import css from '../index.css'

const dmkmcList = {}

class OneOnMany extends React.Component {
  state = {
    isEdit: false,
    columns: [],
    dataSource: this.props.initData?.oneToManyData || [],
    dataSourceTemplate: {},
    kjcsMap: {}, //各字段的控件参数
    previewImage: '',
    previewVisible: false
  }

  componentDidMount() {
    this.buildColumns(this.props.initData)
  }

  componentDidUpdate(preProps, preState) {
    if(this.state.isEdit !== preState.isEdit) {
      this.buildColumns(this.props.initData)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initData !== this.props.initData) {
      this.buildColumns(nextProps.initData)
      this.setState({ dataSource: nextProps.initData?.oneToManyData || []})
    }
  }

  submitCheck = flag => {
    const submitCheck = this.props.submitCheck
    if (typeof submitCheck === 'function') {
      submitCheck(flag)
    }
  }

  formatData = dataList => {
    const { kjcsMap } = this.state
    const newdataList = dataList.map(item => {
      const newItem = { ...item }
      for (let key in newItem) {
        const { type, format } = JSON.parse(kjcsMap[key] || '{}')
        if (type === 'datepicker') {
          newItem[key] = moment(newItem[key]).format(format)
        } else if (type === 'oldupload' && newItem[key] instanceof Array) {
          // 格式化图片附件数据
          let isFileUpload = false
          let array = []
          for (let item of newItem[key]) {
            if (item.hasOwnProperty('uuid') && item['uuid']) {
              isFileUpload = true
              array.push(item['uuid'])
            }
          }
          if (isFileUpload) {
            newItem[key] = array
          }
        }
      }
      return newItem
    })
    return newdataList
  }

  onSave = cb => {
    const {
      cacheFormData,
      initData,
      form: { validateFields }
    } = this.props
    const { dataSource } = this.state

    validateFields((error, values) => {
      if (error) {
        message.info('请填写必填项')
      } else {
        // 依据rowId(PKID)和字段组装数据
        const tempData = {}
        for (let key in values) {
          const [PKID, zd] = key.split(':')
          if(!tempData[PKID] ) {
            tempData[PKID] = {}

          }
          tempData[PKID][zd] = values[key]
          tempData[PKID]['PKID'] = PKID
        }

        // 转换数据格式
        const tableData = this.formatData(Object.values(tempData))

        // 分类必填时，校验条数限制的最小值和最大值时，未维护条数限制的最小值和最大值时，至少要填写一条。
        if(initData.FLSFBT === '1') {
          if(typeof initData.MIN === 'number' &&  tableData.length < initData.MIN) {
            message.info(initData.FLMC + '至少需要填写' + initData.MIN + '条')
            return
          }
        }

        // 组装数据，格式如下
        /* 
          [
            {
              del: '0', // 删除标识
              flzdxx: [
                // 分类字段信息，可以从查询接口中获取
                { ZD: '该字段的标识，数据库对应的字段', BM: '表名', GLZD: '与xsjbxx表的pkid关联的字段，如XSID', SFBT: '是否必填 0/1 0 非必填 1 必填', ZDM: '字段名称' }
              ],
              records: [
                // 对于新增或修改的一对多记录保存在这里，对于删除的记录不需要添加进来
                {
                  PKID: 'xxxxxx', // 一对多记录的pkid，从查询接口中获取，存在就携带该参数，表示修改内容，不存在则是新增记录
                  xxx: '其他字段对应的内容',
                  xxx: '其他字段对应的内容'
                }
              ]
            }
          ]
        */
        const saveData = [
          {
            del: '0',
            flzdxx: initData.VALUE.filter((item) => item.SFKBJ === '1'),
            records: tableData.map((item) => {
              const preItem = dataSource.find((_item) => _item.PKID === item.PKID)
              return preItem ? {...preItem, ...item} : item
            })
          }
        ]
        cacheFormData([initData.FLDM], saveData, null, 3)
        cb(true)
        this.setState({ isEdit: false })
      }
    })
  }

  onAdd = () => {
    const { dataSourceTemplate, dataSource } = this.state
    this.setState({ dataSource: [...dataSource, { ...dataSourceTemplate, PKID: createUuid() }] })
  }

  onDelete = (e, index) => {
    e.preventDefault()
    const { dataSource } = this.state
    const newDataSource = cloneDeep(dataSource)
    newDataSource.splice(index, 1)
    this.setState({ dataSource: newDataSource })
  }

  // 文件展示
  fileLists = fileLists => {
    {
      let items = []
      for (let key in fileLists) {
        let fileList = typeof fileLists[key] == 'string' ? fileLists[key].split(';') : fileLists[key]
        items.push(
          <div>
            <a
              onClick={() => {
                this.handlePreview('file', fileList[0])
              }}
            >
              {fileList[1]}
            </a>
          </div>
        )
      }
      return items
    }
  }

  //获取下拉列表value对应的名称
  getDmkMc = dmbz => {
    dmkmcList[dmbz] = '请求中' //用于防止重复请求
    NHFetch('/proData/selectDataList', 'GET', { sign: dmbz }).then(res => {
      if (res) {
        if (res.data) {
          dmkmcList[dmbz] = res.data
          this.forceUpdate()
        }
      }
    })
  }
  //获取联级下拉列表value对应的名称
  getCascaderDmkMc = (level, sign, cascaderValue) => {
    dmkmcList[level + sign + cascaderValue] = '请求中' //用于防止重复请求
    NHFetch('/proData/selectCascaderList', 'GET', { level: level, sign: sign, cascaderValue: cascaderValue }).then(res => {
      if (res) {
        if (res.data) {
          dmkmcList[level + sign + cascaderValue] = res.data
          console.log(dmkmcList)

          this.forceUpdate()
        }
      }
    })
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = (fjmc, fjid) => {
    if (fjmc === 'isImage') {
      this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    } else {
      window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`)
    }
  }

  //打开图片弹窗
  showPreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true
    })
  }
  // 关闭图片弹窗
  closeModal = () => this.setState({ previewVisible: false })

  //图片展示
  imageLists = imageLists => {
    {
      let items = []
      for (let key in imageLists) {
        let imageList = typeof imageLists[key] == 'string' ? imageLists[key].split(';') : imageLists[key]
        items.push(
          <span>
            <img
              style={{ height: '80px', width: '80px' }}
              src={'anonymity/docrepo/download/file?attachmentId=' + imageList}
              onClick={() => {
                this.handlePreview('isImage', imageList[0])
              }}
            />
          </span>
        )
      }
      return items
    }
  }

  renderCell = (fieldInfo, text, record) => {
    const { form, editable, isRequired, initData } = this.props
    const { isEdit, formData } = this.state
    //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
    // let { zdbz, zdmc, kjcs, zdz, sfbt , mrz, zdzt} = fieldArray[key]; //表单字段
    // zdbz == ZD、 zdmc == ZDM zdz == CSZ sfbt == SFBT mrz == defaultValue zdzt == SFKBJ
    //字段标识、字段名称、字段值、控件参数、是否必填、字段是否可编辑、只读视图
    let { ZD, ZDM, KJCS, SFBT, SFKBJ, ZDST } = fieldInfo
    let CSZ = text
    let zdzmc = ''
    const zdstCom = { type: 'input' }
    KJCS = KJCS || JSON.stringify(zdstCom)
    let kjcsjson = JSON.parse(KJCS || '{}')
    let { defaultValue } = kjcsjson //默认值
    if (formData && formData[ZD] != null) {
      CSZ = formData[ZD]
    }
    if (kjcsjson.type === 'datepicker' || kjcsjson.type === 'datetimepicker' || kjcsjson.type === 'timepicker' || kjcsjson.type === 'monthpicker' || kjcsjson.type === 'rangepicker') {
      //日期值展示阶段，从moment转为文字
      if (CSZ) {
        zdzmc = CSZ && moment(CSZ).format(kjcsjson.format)
      }
    } else if (kjcsjson.type === 'select') {
      if (kjcsjson.dataTag && !dmkmcList[kjcsjson.dataTag]) {
        this.getDmkMc(kjcsjson.dataTag)
      }

      let list = dmkmcList[kjcsjson.dataTag]
      list &&
        list instanceof Array &&
        list.map(item => {
          if (item.VALUE === CSZ) {
            zdzmc = item.LABEL
          }
        })
    } else if (kjcsjson.type === 'multiselect') {
      if (kjcsjson.dataTag && !dmkmcList[kjcsjson.dataTag]) {
        this.getDmkMc(kjcsjson.dataTag)
      }

      let list = dmkmcList[kjcsjson.dataTag]
      if (CSZ) {
        let multiselect = typeof CSZ == 'string' ? CSZ.split(',') : CSZ
        let multiselectList = []
        for (let i in multiselect) {
          list &&
            list instanceof Array &&
            list.map(item => {
              if (item.VALUE === multiselect[i]) {
                multiselectList.push(item.LABEL)
              }
            })
        }
        zdzmc = multiselectList.join(',')
      }
    } else if (kjcsjson.type === 'nhcascader') {
      if (CSZ) {
        let nhcascaderList = typeof CSZ == 'string' ? CSZ.slice(1, -1).split(/\s*,\s*/) : CSZ
        let nhcascaderLists = []
        for (let i in nhcascaderList) {
          // i 是字符串
          let index = Number.parseInt(i)
          let level = index + 1
          if (kjcsjson.dataTag) {
            if (index === 0) {
              if (!dmkmcList[level + kjcsjson.dataTag]) {
                this.getCascaderDmkMc(level, kjcsjson.dataTag, '')
              }
            } else {
              if (!dmkmcList[level + kjcsjson.dataTag + nhcascaderList[index - 1]]) {
                this.getCascaderDmkMc(level, kjcsjson.dataTag, nhcascaderList[index - 1])
              }
            }
          }

          let list = dmkmcList[level + kjcsjson.dataTag + (index === 0 ? '' : nhcascaderList[index - 1])]
          list &&
            list instanceof Array &&
            list.map(item => {
              if (item.value === nhcascaderList[index]) {
                nhcascaderLists.push(item.label)
              }
            })
        }
        zdzmc = nhcascaderLists.join('/')
      }
    } else if (kjcsjson.type === 'switch') {
      zdzmc = CSZ === '1' || CSZ === true ? kjcsjson.checkedText : kjcsjson.uncheckedText
    } else if (kjcsjson.type === 'radio') {
      kjcsjson.options &&
        kjcsjson.options.map((item, key) => {
          if (item.value === CSZ) {
            zdzmc = item.label
          }
        })
    } else if (kjcsjson.fileType === 'file' && CSZ) {
      let fileLists = typeof CSZ == 'string' ? CSZ.split(',') : CSZ
      zdzmc = this.fileLists(fileLists)
    } else if (kjcsjson.fileType === 'image' && CSZ) {
      console.log('image', ZD, CSZ)

      if (typeof CSZ === 'object') {
      } else {
        CSZ = CSZ.slice(1, -1)
        CSZ = CSZ.split(/\s*,\s*/)
        if (CSZ.length === 1 && CSZ[0].length === 0) {
          CSZ = []
        }
        console.log('IMAGE', ZD, CSZ)
      }
      zdzmc = this.imageLists(CSZ)
    } else {
      zdzmc = CSZ
    }
    let required = SFBT === '1' && !Boolean(ZDST)
    let readOnly = SFKBJ !== '1' || Boolean(ZDST) //是否只读
    CSZ = formData ? formData[ZD] : CSZ

    const formItem =
      isEdit && !readOnly ? (
        <NHInputRender
          key={record.PKID + ':' + ZD}
          id={record.PKID + ':' + ZD}
          label={''}
          defaultValue={defaultValue}
          value={kjcsjson.validationValueType === 'array' ? (CSZ && typeof CSZ == 'string' ? CSZ.slice(1, -1).split(/\s*,\s*/) : CSZ ? CSZ : []) : CSZ}
          required={required}
          disabled={isEdit ? false : true}
          form={form}
          formLayout={'vertical'}
          feature={KJCS}
        />
      ) : (
        <div style={{maxWidth: 200, margin: '0 auto'}}>{zdzmc}</div>
      )

    return formItem
  }

  buildColumns = initData => {
    const { isEdit } = this.state
    const columns = []
    const dataSourceTemplate = {}
    const kjcsMap = {}
    //信息采集字段分类
    if (initData) {
      //表单信息、表单字段信息
      if (initData.VALUE != null) {
        const fieldArray = initData.VALUE
        if (fieldArray.length > 0) {
          //显示表单字段信息
          for (let key in fieldArray) {
            //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
            // let { zdbz, zdmc, kjcs, zdz, sfbt , mrz, zdzt} = fieldArray[key]; //表单字段
            // zdbz == ZD、 zdmc == ZDM zdz == CSZ sfbt == SFBT mrz == defaultValue zdzt == SFKBJ
            //字段标识、字段名称、字段值、控件参数、是否必填、字段是否可编辑、只读视图
            let { ZD, ZDM, CSZ, KJCS, SFBT, SFKBJ, ZDST } = fieldArray[key]
            let required = SFBT === '1' && !Boolean(ZDST)
            let readOnly = SFKBJ !== '1' || Boolean(ZDST) //是否只读

            const column = {
              title: required && isEdit && !readOnly ? <><span style={{color: 'red'}}>*</span>{ZDM}</> : ZDM,
              dataIndex: ZD,
              render: this.renderCell.bind(this, fieldArray[key])
            }
            columns.push(column)

            // 整理其他数据
            dataSourceTemplate[ZD] = ''
            kjcsMap[ZD] = KJCS
          }
        }
      }
    }

    this.setState({ columns, dataSourceTemplate, kjcsMap })
  }

  // 是否含有多行文本，用于动态确定ant-form-item-control-wrapper的高度
  isIncludeTextarea = () => {
    const { initData } = this.props
    const fieldArray = initData.VALUE
    return fieldArray && fieldArray.length && fieldArray.some(fieldInfo => JSON.parse(fieldInfo.KJCS || '{}').type === 'textarea')
  }

  render() {
    const { form, editable, isRequired, initData } = this.props
    const { isEdit, formData, columns, dataSource, previewVisible, previewImage } = this.state

    const actionColumn = {
      title: '删除',
      dataIndex: 'operation',
      width: 80,
      fixed: 'right',
      render: (text, record, index) => {
        if (isEdit) {
          return (
            <div className="editable-row-operations">
              <a href="#" onClick={e => this.onDelete(e, index)}>
                <Icon type="close" style={{ color: 'red' }} />
              </a>
            </div>
          )
        }
        return (
          <a href="#" onClick={e => this.onDelete(e, index)}>
            <Icon type="delete" style={{ color: 'red' }} />
          </a>
        )
      }
    }

    let isShowAdd = isEdit
    
    // 分类必填时，校验条数限制的最小值和最大值时，未维护条数限制的最小值和最大值时，至少要填写一条。
    if(initData.FLSFBT === '1') {
      if(typeof initData.MAX === 'number' &&  dataSource.length >= initData.MAX) {
        isShowAdd = false
      }
    }

    return (
      <div className={`${css.oneOnMany} ${this.isIncludeTextarea() ? css.oneOnManyIncludeTextarea : ''}`}>
        <TitleTop
          extra={editable}
          title={
            <span>
              <span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>
              {initData.FLMC}
            </span>
          }
          onEdit={() => {
            this.setState({
              isEdit: true
            })
            this.submitCheck(false)
          }}
          onSave={cb => {
            this.onSave(cb)
          }}
          onClose={() => {
            this.setState({
              isEdit: false,
              dataSource: initData?.oneToManyData || []
            })
            this.submitCheck(true)
          }}
        />
        <div className="p-sw" style={{ width: 1136 }}>
          <Table bordered size="middle" pagination={false} dataSource={dataSource} columns={isEdit ? columns.concat(actionColumn) : columns} rowKey='PKID' scroll={{x: true}}/>
          {isShowAdd && (
            <div className="mt-10" styleName="btn_add" onClick={this.onAdd}>
              添加{initData.FLMC}
            </div>
          )}
        </div>
        <Modal visible={previewVisible} footer={null} onCancel={this.closeModal}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    )
  }
}
export default Form.create()(OneOnMany)
