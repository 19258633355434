import 'isomorphic-fetch';
import { Modal,message} from 'antd';
import {getLoginUser} from './NHCore';
import moment from 'moment';
//请求默认路径
const pathUrl = "api/zhxg-yxwz";

const hasClass = (node,className)=> {
    let exist=false;
    if (node.getAttribute('class')) {  // 存class属性
        // 式1
        if (node.getAttribute('class').indexOf(className) > -1) {
            exist=true;
        }
        // 式2
        if (node.className.indexOf(className) > -1) {
            exist=true;
        }
    }
    return exist;
}

const parents = (obj,className) => {
    if(obj.tagName==='BODY'){
        return undefined;
    }
    if(hasClass(obj,className)){
        return obj;
    }else{
        return parents(obj.parentNode,className);
    }
}

const getTitles = (fields)=> {
    let errorInfo="";
    for(let i=0;i<fields.length;i++){
        let obj=document.getElementById(fields[i]);
        if(obj){
            //获取标签的上级第一个存在ant-form-item-control-wrapper类的标签
            let parentObj=parents(obj,'ant-form-item-control-wrapper');
            if(parentObj){
                let prevObj=parentObj.previousSibling;
                if(prevObj){
                    let labelObj=prevObj.getElementsByTagName("label");
                    if(labelObj && labelObj.length>0){
                        let title=labelObj[0].attributes["title"].nodeValue;
                        if(title){
                            errorInfo+=","+title;
                        }
                    }
                }
            }
        }
    }
    return errorInfo?errorInfo.substring(1):"";
}

/**
 * 唯一性约束错误提示
 */
const onlyDataErrorTip = (res) => {
    let fieldStr=res.message.substring(14,res.message.length-1);
    let fields=fieldStr.split(",");
    let errorInfo=getTitles(fields);
    if(errorInfo){
        message.info(errorInfo+"已经存在！");
    }else{
        message.info("存在数据违反了唯一性约束，请检查之后再提交！");
    }
}

/**
 * 数据太长错误提示
 */
const dataToLongErrorTip = (res) => {
    let fieldStr=res.message.substring(16,res.message.length-1);
    let fields=fieldStr.split(",");
    let errorInfo=getTitles([fields[0]]);
    if(errorInfo){
        message.info(errorInfo+"数据太长(要求长度："+fields[2]+"，实际长度："+fields[1]+")！");
    }else{
        message.info("存在数据值太长，请检查之后再提交！");
    }
}

/**
 * 数据不能为空
 */
const columnNotNullTip = (res) => {
    let fieldStr=res.message.substring(14,res.message.length-1);
    let fields=fieldStr.split(",");
    let errorInfo=getTitles(fields);
    if(errorInfo){
        message.info(errorInfo+"不能为空！");
    }else{
        message.info("存在数据不能为空，请检查之后再提交！");
    }
}

const NHFetch =  (pUrl,pMethod,params,errorFunc) => {
    let opts = {};
    let tmpHeader = "";
    let url = pUrl;
    let method = "";

    if(!url.startsWith("api/") && !url.startsWith("front/")){
        url=pathUrl+url;
    }

    //默认的请求方式是POST
    if (pMethod === undefined || pMethod === "" ) {
        method = "POST";
    }else{
        method = pMethod;
    }

    if(method.toLowerCase()==='get'){//如果是get方式，则把参数拼凑到url路径上面
        if(params !== undefined && params !== ""){
            //如果params是Map对象，则需要先转换成字符串
            if((typeof params)==='string'){
                url = url+"?"+params;
            }else{//不是字符串就一定是map对象
                let paramsStr="";
                for(let key in params){
                    if((typeof params[key])==='object'){
                        let params2=params[key];
                        for(let key2 in params2){
                            paramsStr+="&"+key+"."+key2+"="+params2[key2];
                        }
                    }else{
                        paramsStr+="&"+key+"="+params[key];
                    }
                }
                url = url+"?"+paramsStr;
            }
        }
        tmpHeader = "application/x-www-form-urlencoded;charset=UTF-8";
    }else{//除了get，所有方法改为post
        method = "POST";
        tmpHeader = "application/json;charset=UTF-8";
        if(params !== undefined && params !== ""){
            opts.body = JSON.stringify(params);
        }
    }
    opts.method = method;
    //  credentials: 'include',
    opts.headers = {"Content-Type": tmpHeader};

    let token=window.sessionStorage.getItem("access_token");
    if (token){
        opts.headers.Authorization=token;
    }
    const nodeEnv = process.env.NODE_ENV || 'development';
    if(nodeEnv==='development'){
        opts.headers.loginUserId=getLoginUser().userId;
    }

    opts.credentials='include';
    return fetch(url, opts)
    .then((response) => {
        if(response.status === 200){
            if(pMethod==='nbpost' || pMethod==='NBPOST'){
                return response.blob();
            }else{
                if (window.gateway_version === '2') {   //新网关
                    sessionStorage.removeItem('user_last_operation_time');
                    //记录用户最后一次操作时间
                    let userLastOperationTime = moment().format('YYYY-MM-DD HH:mm:ss');
                    sessionStorage.setItem('user_last_operation_time', userLastOperationTime);
                }
                return response.json();
            }
        }else if(response.status === 302){
            if (window.gateway_version === '2') {   //新网关
                let userLogin =  sessionStorage.getItem('userLogin') ? JSON.parse(sessionStorage.getItem('userLogin')) : {};
                let token = sessionStorage.getItem('refresh_token');
                let userLastOperationTime = sessionStorage.getItem('user_last_operation_time');
                let expiresIn = userLogin.expiresIn || 1800;
                let userLastOperationTimeStr = userLastOperationTime.replace("/-/g", "/");
                //用户最后一次操作时间
                let userLastOperationTimeDate = new Date(userLastOperationTimeStr);
                //当前时间
                let nowDate = new Date();
                //在token超时阈值范围内用户没有任何操作，则直接退出登录，重新登录
                let differDate = getDifferDate(userLastOperationTimeDate, nowDate);
                if (differDate >= expiresIn) {
                    userLogout();
                } else {
                    //在token超时阈值范围内用户有操作，则重新生成accessToken
                    refreshToken(token, newAccessToken => {
                        sessionStorage.removeItem("access_token");
                        sessionStorage.setItem("access_token", newAccessToken);
                        let userLastOperationTime = moment().format('YYYY-MM-DD HH:mm:ss');
                        sessionStorage.setItem('user_last_operation_time', userLastOperationTime);
                    });
                }
            } else {
                message.error("未登录或登录已过期,请刷新页面重新登录！");
                sessionStorage.removeItem("userLogin");
                sessionStorage.removeItem("access_token");
            }
        }else if(response.status === 401){
            message.error("您正在尝试访问未授权的功能!");
        }else{
            //如果存在错误的回调方法，则调用回调方法，否则提示网络请求异常
            if(errorFunc){
                errorFunc();
            }else{
                message.error('系统出现异常，请联系管理员！');
            }
        }
        return undefined;
    })
    .then((res) => {
        if(res === undefined){
            return undefined;
        }else if((res.meta && res.meta.statusCode===401) || res.code===302){
            //判断是否没有权限
            message.error("您正在尝试访问未授权的功能!");
            return undefined;
        }else if(res.meta && res.meta.statusCode===500){
            message.error('系统出现异常，请联系管理员！');
            return undefined;
        }else if(res && res.code && res.code !==200 ){//判断出现的问题
            let msg=res.message;
            if(msg.startsWith("businessLogicError[")){//自定义的提示（错误）
                //Modal.error({ title: '错误提示', content: res.message.substring(19,res.message.length-1),});
                message.info(res.message.substring(19,res.message.length-1));
            }else if(msg.startsWith("businessLogicWarm[")){//自定义的提示（警告）
                //Modal.warning({ title: '警告提示', content: res.message.substring(19,res.message.length-1),});
                message.info(res.message.substring(18,res.message.length-1));
            }else if(msg.startsWith("businessLogicInfo[")){//自定义的提示（消息）
                //Modal.info({ title: '消息提示', content: ,});
                message.info(res.message.substring(19,res.message.length-1));
            }else if(msg.startsWith("onlyDataError[")){//数据唯一性异常
                onlyDataErrorTip(res);
            }else if(msg.startsWith("dataToLongError[")){//数据太长
                dataToLongErrorTip(res);
            }else if(msg.startsWith("columnNotNull[")){//数据不能为空异常
                columnNotNullTip(res);
            }else{
                message.error('系统出现异常，请联系管理员！');
            }
            return undefined;
        }else if(res.status && res.status===404){
            message.error('系统出现异常，请联系管理员！');
            return undefined;
        }else if(res && res.code && res.code ===200 ){//返回的是正确的结果,公司通用放回格式
            return res;
        }else if(res && res.meta && res.meta.statusCode===200){//返回的是正确的结果，四部返回格式
            return res;
        }else{//剩下的情况都是有错误
            message.error('系统出现异常，请联系管理员！');
        }
        //在此处统一对后台抛出来的异常进行处理
        return undefined;
    })
    .catch((error) => {
        // if((error+'').indexOf("NetworkError when attempting to fetch resource")>-1){
        //     //出现这种错误是因为登录过期了，系统自动跳转到统一登录界面，但是系统中统一登录不存在就出现当前错误
        //     sessionStorage.removeItem("userLogin");
        //     sessionStorage.removeItem("access_token");
        // }else if(error.status===302){
        //     message.error("未登录或登录已过期,请刷新页面重新登录！");
        //     sessionStorage.removeItem("userLogin");
        //     sessionStorage.removeItem("access_token");
        // }else{
        //     sessionStorage.removeItem("userLogin");
        //     sessionStorage.removeItem("access_token");

        //     Modal.error({ title: '错误提示', content: '网络请求异常,请联系管理员'});
        // }
        sessionStorage.removeItem("userLogin");
        sessionStorage.removeItem("access_token");
        return error;
    })

}
/**
 * 刷新Token
 * @param refreshToken 当前登录用户的刷新token值
 * @param callback 回调方法
 */
 export const refreshToken = (refreshToken, callback) => {
    fetch(`api/refreshToken?refreshToken=${refreshToken}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": "XMLHttpRequest",
            "appId": "ly-zhxg"
        }
    }).then(function (response) {
        return response.json();
    }).then(function (res) {
        if (res.meta && res.meta.statusCode === 200) {
            if (callback && typeof callback === 'function') {
                callback(res.data);
            }
        } else {
            //刷新token失败,跳转到登录页面重新登录
            userLogout();
        }
        return res;
    }).catch(function (error) {
        //刷新token失败,跳转到登录页面重新登录
        userLogout();
    });
};
export default NHFetch;
