import React from 'react'
import { DatePicker, Table, Upload, Icon, Form, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import '../index.css';
import { getLoginUser } from "@utils/NHCore";
import NHFetch from '@utils/NHFetch';
import NHImage from '../../../../components/common/NHImage';

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"
const FormItem = Form.Item

class SinglePhotoForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uploadImg: false,
            imageUrl: '',
            txscListData: [],
            txData: '',
            originUrl:'',

        }
    }
    componentDidMount() {
        this.props.setRef(this)
        this.getStudentPhotoBase64()
    }

    submitCheck =(flag) => {
        const submitCheck = this.props.submitCheck;
        if(typeof submitCheck ==="function"){
            submitCheck(flag);
        }
    }

   // 获取学生头像
   getStudentPhotoBase64 = () => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined;
    if (userId) {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            this.setState({
                xsid: userId,
                imageUrl: `data:image/jpeg;base64,${res.data}`,
                originUrl: `data:image/jpeg;base64,${res.data}`,
            })
        });
       
    }
   
}

    beforeUpload = (file) => {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJPG) {
            message.error("只能上传.png,.jpg,.jpeg格式的文件");
        }

        let minSize = 0, maxSize = 2 * 1024
        try {
            if(/\d/.test(window.photoMinSize)) {
                minSize = Number(window.photoMinSize)
            }
            if(/\d/.test(window.photoMaxSize)) {
                maxSize = Number(window.photoMaxSize)
            }
        } catch (e) {
            message.error('PHOTOMINSIZE或PHOTOMAXSIZE环境变量错误，请设置为数字')
            minSize = 0
            maxSize = 2 * 1024
        }

        const isSizeLimit = file.size >= minSize * 1024 && file.size <= maxSize * 1024
        if (!isSizeLimit) {
            const uploadElement = document.getElementById('photoUpload')
            if(uploadElement) {
                uploadElement.value = ''
            }
            const minMsg = minSize >= 1024 ? `${(minSize / 1024).toFixed(1)}MB` : `${minSize}KB`
            const maxMsg = maxSize >= 1024 ? `${(maxSize / 1024).toFixed(1)}MB` : `${maxSize}KB`
            message.warning(`图片大小应满足${minMsg}-${maxMsg}`);
        }

        return isJPG && isSizeLimit;
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                // let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                // NHFetch(url, 'POST')
                //     .then(res => {
                //         if (res && res.data) {
                //             message.success("上传成功!")
                //         }
                //     });
                this.setState({
                    photoUUID
                })
            }
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({ imageUrl })
            );
        }
    }

    onSaveTxscFormData = (cb) => {
        cb(true);
        this.props.cacheFormData('txsc',[{photoUUID: this.state.photoUUID,type: 10}],null, 1)
    }


    render() {
        const { uploadImg, imageUrl, tempUrl, txscListData } = this.state;
        const { editable, isRequired, txscInitData, form } = this.props
        const userId = getLoginUser().userId;
        return (
            <Form layout="inline">
                <TitleTop
                    extra={editable}
                    title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
                    onEdit={type => {
                        this.setState({
                            uploadImg: true
                        });
                        this.submitCheck(false);
                    }}
                    onSave={cb => {
                        this.setState({uploadImg: false})
                        this.submitCheck(true);
                        this.onSaveTxscFormData(cb);
                    }}
                    onClose={() => {
                        this.setState({
                            imageUrl: this.state.originUrl,
                            uploadImg: false
                        });
                        this.submitCheck(true);
                    }}
                />
                <div styleName="txsc" className="_bg">
                    {uploadImg && (
                            <Upload
                                id='photoUpload'
                                name="photo"
                                accept={'image/*'}
                                showUploadList={false}
                                disabled={!uploadImg}
                                action={uploadUrl + "?xsid=" + userId}
                                beforeUpload={this.beforeUpload}
                                onChange={this.handleUploadChange}
                            >
                                {
                                    imageUrl ?

                                        <img
                                            src={imageUrl}
                                            alt=""
                                            className="avatar"
                                        />

                                        : (
                                            <div styleName="upload-box">
                                                <div styleName="upload-title">
                                                    上传图片
                                                </div>
                                            </div>
                                        )
                                }
                            </Upload>
                        )
                    }

                    {!uploadImg && (
                        <img
                            src={imageUrl}
                            alt=""
                            className="avatar"
                        />
                    )}
                </div>
            </Form>
        )
    }
}
export default Form.create()(SinglePhotoForm)