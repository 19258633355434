import React from 'react'
import { connect } from 'react-redux'
import { Route, Router, Link, Redirect, Switch } from 'react-router-dom'
import classNames from 'classnames';
import { Layout, Icon, Divider } from 'antd';
import createHistory from 'history/createHashHistory'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import { clearUserInfo } from "@redux/actions/login"

import { Scrollbars } from 'react-custom-scrollbars';
import { getSize } from '@utils/globalUtil'

/*公共模块*/
import Header from '@plugins/header';
import Footer from '@plugins/footer';
/*页面*/
import Index from '@pages/index'
/*公告*/
import Notice from '@pages/notice/index'
import NoticeDetail from '@pages/notice/noticeDetail'
/*自助报到*/
import Education from '@pages/SelfHelp/education';
//视频预览
import ViewVideo from '@pages/viewVideo/myVideo/index';
import ReportSheetViewer from '@pages/reportSheetViewer/index'
// import Autobiography from '@pages/SelfHelp/autobiography'
import SelfRoute from '@pages/SelfHelp/route'
import UpdateInfo from '@pages/SelfHelp/updateInfo'
import UpdateInfoV10 from '@pages/SelfHelp/updateInfoV_10'
import UpdateInfoV10_1 from '@pages/SelfHelp/updateInfoV_10_1'
import InfoGdnhgs from '@pages/SelfHelp/infoGdnhgs'
import UpdateInfoFg from '@pages/SelfHelp/updateInfoFg'
import Buy from '@pages/SelfHelp/buy'
import BuyFa from '@pages/SelfHelp/buyFa'
import Size from '@pages/SelfHelp/size'
import SizeJzykd from '@pages/SelfHelp/SizeJzykd'
import SizeXatlzyjsxy from '@pages/SelfHelp/SizeXatlzyjsxy'
import SizeJxgczyjsxy from '@pages/SelfHelp/SizeJxgczyjsxy'
import SizeSdcsjszyxy from '@pages/SelfHelp/SizeSdcsjszyxy'
import SchoolUniform from '@pages/SelfHelp/xfcm'
import Report from '@pages/SelfHelp/report'
import ReportHndysf from '@pages/SelfHelp/reportHndysf'
import ReportGdnhgs from '@pages/SelfHelp/reportGdnhgs'
import ReportCzxy from '@pages/SelfHelp/reportCzxy'
import ReportWhrj from '@pages/SelfHelp/reportWhrj'
import ReportBjwlxy from '@pages/SelfHelp/bjwlxyReport'
import ReportFjsw from '@pages/SelfHelp/reportFjsw'
import ReportWydx from '@pages/SelfHelp/reportWydx'
import JfxxHzczy from '@pages/SelfHelp/jfxxHzczy';
import PayGD008_8 from '@pages/SelfHelp/payGd008_12';
import Channel from '@pages/SelfHelp/channel'
import ChannelCzxy from '@pages/SelfHelp/channelCzxy'
import ChannelFb from '@pages/SelfHelp/channelFb'
import ChannelJxcmzyxy from '@pages/SelfHelp/channelJxcmzyxy'
import ChannelWydx from '@pages/SelfHelp/ChannelWydx'
import ChannelJxkjsfdx from '@pages/SelfHelp/channelJxkjsfdx'
import SszyChannel from '@pages/SelfHelp/sszychannel'
import SzjdChannel from '@pages/SelfHelp/szjdChannel'
import ChannelZtxy from '@pages/SelfHelp/channelZtxy'
import ChannelHbnzxy from '@pages/SelfHelp/channelHbnzxy'
import Major from '@pages/SelfHelp/major'
import MajorFb from '@pages/SelfHelp/majorFb'
import MajorFa from '@pages/SelfHelp/majorFa'
import Pay from '@pages/SelfHelp/pay'
import NonTaxPayIndex from '@pages/SelfHelp/nonTaxPay'
import SszyxfPay from '@pages/SelfHelp/sszyxfpay'
import SszyssfPay from '@pages/SelfHelp/sszyssfpay'
import Print from '@pages/SelfHelp/print'
import Dorm from '@pages/SelfHelp/dorm'
import DormV2 from '@pages/SelfHelp/dormV2'
import DormV3 from '@pages/SelfHelp/dormV3'
import ZjkjxyDormV2 from '@pages/SelfHelp/ZjkjxyDormV2'
import CheckDormitory from '@pages/SelfHelp/checkDormitory'
import AllotDormitory from '@pages/SelfHelp/allotDormitory'
import ViewDorm from '@pages/SelfHelp/viewDorm'
import SszyDorm from '@pages/SelfHelp/sszydorm'
import CustomLink from '@pages/SelfHelp/customLink'
import ChoosePhoneNum from '@pages/SelfHelp/choosePhoneNum'
import ScjtChooseDor from '@pages/SelfHelp/scjtChooseDor'
import CdzyjsxyChooseDor from '@pages/SelfHelp/cdzyjsxyChooseDor'
import WhrjChooseDor from '@pages/SelfHelp/whrjChooseDor'
/*在线问答*/
import OnlineQAndA from '@pages/onlineQAndA/index'
/*默认页面*/
import IndexRoute from "@pages/index/IndexRoute";
/*导航链接*/
import LinkDhlj from "@pages/link";
import AuthorizedRoute from "./AuthorizedRoute";
import { RouterComponents } from '@utils/Router'
/* 个人信息（简易） */
import Info from '@pages/SelfHelp/info'
/*缴费环节，商联*/
import PayFxxy from '@pages/SelfHelp/payFxxy'
/* 缴费环节，微信*/
import WxChatPay from '@pages/SelfHelp/wxChatPay'
/* 录取查询 */
import Lqcx from '@pages/lqcx'
/* 个人信息（微调版） */
import PInfo from '@pages/SelfHelp/pInfo'
/* 个人信息（新版）*/
import NewInfo from '@pages/SelfHelp/newInfo'
/* 自助报到>入学考试 */
import Examination from '@pages/SelfHelp/examination'
/* 个人信息深圳技大 */
import szjdInfo from '@pages/SelfHelp/commonInfo'
// 个人信息湖南城市
import InfoHncs from '@pages/SelfHelp/infoHncs'
// 报到湖南城市
import ReportHncs from '@pages/SelfHelp/reportHncs'
import DormInfo from '@pages/dormInfo'
import ProvinceInfo from '@pages/provinceInfo'
import ApplyDorm from '@pages/SelfHelp/applyDorm'
import ViewStudentId from '@pages/SelfHelp/viewStudentId'
import GetStudentId from '@pages/SelfHelp/getStudentId'
// 铜陵学院绿色通道
import ChannelTlxy from '@pages/SelfHelp/channelTlxy';
import MentalHealthSurvey from '@pages/SelfHelp/mentalHealthSurvey'
/* 绿色通道-安徽工商 */
import AnLv from '@pages/SelfHelp/anLv'
/* 武汉软件防疫登记 */
import Antiepidemic from '@pages/SelfHelp/antiepidemic'
/* 大理护理(防疫登记和信息比对) */
import AntiepidemicDlhl from '@pages/SelfHelp/antiepidemicDlhl'
import ConfirmInfoDlhl from '@pages/SelfHelp/confirmInfoDlhl'
/* 是否缴纳医疗保险（湖南城市学院）*/
import MedicalInsurance from '@pages/SelfHelp/medicalInsurance'
/* 在线缴费（微信、支付宝）*/
import PayOnline from '@pages/SelfHelp/payOnline';
/* 在线缴费（无锡商院）*/
import OrderPayOnline from '@pages/SelfHelp/orderPayOnline';
/* 信息查询*/
import InformationSearch from '@pages/InformationSearch';
//绿色通道(宝鸡文理)
import ChannelBjwl from '@pages/SelfHelp/channelBjwl';
/* 自选手机号（吉首大学张家界学院）*/
import ChoosePhone from '@pages/SelfHelp/choosePhone';
//照片上传
import PhotoUpload from '@pages/SelfHelp/photoUpload';
import StuModifyPic from '@pages/SelfHelp/stuModifyPic';
//照片上传-快捷
import PhotoUploadQuick from '@pages/SelfHelp/photoUploadQuick';
//照片上传（广西生态职院）
import PhotoUploadGxstzy from '@pages/SelfHelp/photoUploadGxstzy';

/* 训服和床上用品缴费 */
import XfcsypPay from '@pages/SelfHelp/xfcsypPay';
/* 校园号预充值 */
import Xyhycz from '@pages/SelfHelp/xyhycz';
/* 人脸身份信息核验 */
import Rlsfxxhy from '@pages/SelfHelp/rlsfxxhy';
/* 一卡通核验人脸采集 */
import Ykthyrlcj from '@pages/SelfHelp/ykthyrlcj';
// 身份核验
import IdentityVerification from '@pages/SelfHelp/identityVerification';
/* 财务缴费 */
import FinancialPayment from '@pages/SelfHelp/financialPayment';

/* 身份核验(吉首) */
import JsSfhy from '@pages/SelfHelp/jsSfhy';
import jfPayFees from '@pages/SelfHelp/jfPayFees'
// 宽带缴费（湛江科技）
import broadBand from '@pages/SelfHelp/broadBand'
/* 办理学生平安保险 */
import Insurance from '@pages/SelfHelp/insurance'
/* 查看宿舍 */
import ViewDormitory from '@pages/SelfHelp/viewDormitory'

/* 入学教育-三峡 */
import EnrollmentEducation from '@pages/SelfHelp/enrollmentEducation'

const { Content } = Layout;

const history = createHistory()

@connect(store => {
    let { login, dispatch } = store
    return { login, dispatch }
})
export default class TotalRoutes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "admin",
            casLogoutUrl: '',
        }
    }

    componentWillMount() {
        // this.doLogin();
    }

    //修改icon
    changeIcon = () => {
        let link = document.head.querySelector("link[rel=icon]");
        let { href } = window.location;
        link.href = `${href.substring(0, href.indexOf('/', 8))}/front/zhxg-unauth/xtglSchoolMng/downloadSchoolPictureByType?type=ico`;
    };

    componentDidMount() {
        // this.setState({ clientHeight: getSize().windowH });
        // window.onresize =  () => {
        //     this.setState({
        //         clientHeight: getSize().windowH,
        //     })
        // }

        // let skin = window.localStorage.getItem("skin");
        // if(skin) {
        //     this.setState({skin})
        // }

        // if(window.localStorage.getItem('access_user')){
        //     let name=JSON.parse(window.localStorage.getItem('access_user')).userName;
        //     this.setState({
        //         name:name
        //     })
        // }
        //this.changeIcon();

    }

    componentDidUpdate() {
        // this.doLogin();
    }

    doLogin = () => {
        if (!window.localStorage.getItem('access_user')) {
            history.push('/login');
        }
    }


    render() {
        return (
            <Router history={history}>
                <Route render={() => {
                    return (
                        // 页面切换动画设置
                        <CSSTransitionGroup
                            transitionName=''
                            transitionEnter={true}
                            transitionLeave={true}
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={500}
                        >
                            <Switch>
                                {/*<Route path="/login" component={Login} />*/}
                                <Route render={() => {
                                    return (
                                        <Layout>
                                            {/* 头部 */}
                                            <Header />
                                            {/* 内容区域 */}
                                            <Content>
                                                <div className="content-box">
                                                    {/* 路由设置 */}
                                                    <Switch>
                                                        {/* 报到单预览 */}
                                                        <Route path="/rxbdd" component={ReportSheetViewer} />
                                                        {/*在线问答*/}
                                                        <Route path="/onlineQAndA" component={OnlineQAndA} />
                                                        {/* 入学教育-三峡 */}
                                                        <AuthorizedRoute path="/enrollmentEducation" component={EnrollmentEducation} />
                                                        {/* 入学教育 */}
                                                        <AuthorizedRoute path="/education" component={Education} />
                                                        {/*自助报到>入学考试*/}
                                                        <AuthorizedRoute path="/examination" component={Examination} />
                                                        {/* 校园网办理 */}
                                                        {/* <AuthorizedRoute path="/autobiography" component={Autobiography}/> */}
                                                        {/*自助报到>入学报到单打印*/}
                                                        {/* <AuthorizedRoute path="/print" component={Print} /> */}
                                                        {/*自助报到>网上缴费*/}
                                                        <AuthorizedRoute path="/onlinePayment" component={Pay} />
                                                        {/*自助报到>松山职院网上缴学费*/}
                                                        <AuthorizedRoute path="/sszywsjxf" component={SszyxfPay} />
                                                        {/*自助报到>松山职院网上缴宿舍费*/}
                                                        <AuthorizedRoute path="/ssxzywsjssf" component={SszyssfPay} />
                                                        {/*自助报到>专业调整*/}
                                                        <AuthorizedRoute path="/major" component={Major} />
                                                        {/*自助报到>专业调整(安徽工商)*/}
                                                        <AuthorizedRoute path="/majorFb" component={MajorFb} />
                                                        {/*自助报到>绿色通道(安徽工商)*/}
                                                        <AuthorizedRoute path="/ahGsLsTd" component={AnLv} />
                                                        {/*自助报到(江汉)>专业调整*/}
                                                        <AuthorizedRoute path="/majorFa" component={MajorFa} />
                                                        {/*自助报到>绿色通道申请*/}
                                                        <AuthorizedRoute path="/channel" component={Channel} />
                                                        {/*自助报到>绿色通道申请（滁州学院）*/}
                                                        <AuthorizedRoute path="/ChannelCzxy" component={ChannelCzxy} />
                                                        {/*自助报到>绿色通道申请(江汉艺术学院)*/}
                                                        <AuthorizedRoute path="/channelFb" component={ChannelFb} />
                                                        {/*自助报到>绿色通道申请(江西传媒职业学院)*/}
                                                        <AuthorizedRoute path="/channelJxcmzyxy" component={ChannelJxcmzyxy} />
                                                        {/*自助报到>绿色通道申请(五邑大学)*/}
                                                        <AuthorizedRoute path="/channelWydx" component={ChannelWydx} />
                                                        <AuthorizedRoute path="/channelJxkjsfdx" component={ChannelJxkjsfdx} />
                                                        {/*自助报到>松山职院绿色通道申请*/}
                                                        <AuthorizedRoute path="/sszychannel" component={SszyChannel} />
                                                        {/*自助报到>报到情况填写*/}
                                                        <AuthorizedRoute path="/report" component={Report} />
                                                        {/*自助报到>报到情况填写-湖南第一师范*/}
                                                        <AuthorizedRoute path="/reportHndysf" component={ReportHndysf} />
                                                        {/*自助报到>深圳技大绿色通道申请*/}
                                                        <AuthorizedRoute path="/szjdChannel" component={SzjdChannel} />
                                                        {/*自助报到>昭通学院*/}
                                                        <AuthorizedRoute path="/channelZtxy" component={ChannelZtxy} />
                                                        {/*自助报到>河北女子学院*/}
                                                        <AuthorizedRoute path="/channelHbnzxy" component={ChannelHbnzxy} />
                                                        {/*自助报到>广东南华工商*/}
                                                        <AuthorizedRoute path="/reportGdnhgs" component={ReportGdnhgs} />
                                                        {/*自助报到>湖南城市*/}
                                                        <AuthorizedRoute path="/reportHncs" component={ReportHncs} />
                                                        {/*自助报到>滁州学院*/}
                                                        <AuthorizedRoute path="/reportCzxy" component={ReportCzxy} />
                                                        {/*自助报到>武汉软件*/}
                                                        <AuthorizedRoute path="/reportWhrj" component={ReportWhrj} />
                                                        {/*自助报到>报到情况填写>宝鸡文理学院*/}
                                                        <AuthorizedRoute path="/reportBjwlxy" component={ReportBjwlxy} />
                                                        {/*自助报到>报到情况填写>福建生物*/}
                                                        <AuthorizedRoute path="/reportFjsw" component={ReportFjsw} />
                                                        {/*自助报到>报到情况登记>五邑大学*/}
                                                        <AuthorizedRoute path="/reportWydx" component={ReportWydx} />
                                                        {/*自助报到>报到情况登记>五邑大学*/}
                                                        <AuthorizedRoute path="/jfxxHzczy" component={JfxxHzczy} />
                                                        {/*自助报到>校园e卡缴费>永州职院*/}
                                                        <AuthorizedRoute path="/payGD008_12" component={PayGD008_8} />

                                                        {/*自助报到>自选宿舍*/}
                                                        <AuthorizedRoute path="/chooseDormitory" component={Dorm} />
                                                        {/* 自助报到>自选宿舍(四川交通) */}
                                                        <AuthorizedRoute path="/scjtChooseDor" component={ScjtChooseDor} />
                                                        {/*自助报到>查看宿舍信息(江汉艺术学院)*/}
                                                        <AuthorizedRoute path="/checkDormitory" component={CheckDormitory} />
                                                        {/*自助报到>自动分配宿舍(滁州学院)*/}
                                                        <AuthorizedRoute path="/allotDormitory" component={AllotDormitory} />
                                                        {/*自助报到>查看住宿信息*/}
                                                        <AuthorizedRoute path="/viewDorm" component={ViewDorm} />
                                                        {/* 自助报到>自选宿舍(成都职业技术学院) */}
                                                        <AuthorizedRoute path="/cdzyjsxyChooseDor" component={CdzyjsxyChooseDor} />
                                                        {/* 自助报到>自选宿舍(武汉软件) */}
                                                        <AuthorizedRoute path="/whrjChooseDor" component={WhrjChooseDor} />
                                                        {/*自助报到>自选宿舍V2*/}
                                                        <AuthorizedRoute path="/chooseDormitoryV2" component={DormV2} />
                                                        <AuthorizedRoute path="/chooseDormitoryV3" component={DormV3} />
                                                        {/*自助报到>自选宿舍V2（湛江科技学院）*/}
                                                        <AuthorizedRoute path="/zjkjxyChooseDormitoryV2" component={ZjkjxyDormV2} />
                                                        {/*自助报到>松山职院宿舍分配*/}
                                                        <AuthorizedRoute path="/sszyssfp" component={SszyDorm} />
                                                        {/*自助报到>军训服装尺码*/}
                                                        <AuthorizedRoute path="/clothingSize" component={Size} />
                                                        {/*自助报到>军训服装尺码(锦州医科大学)*/}
                                                        <AuthorizedRoute path="/clothingSizeJzykd" component={SizeJzykd} />
                                                        {/*自助报到>军训服装尺码(西安铁路职业技术学院)*/}
                                                        <AuthorizedRoute path="/clothingSizeXatlzyjsxy" component={SizeXatlzyjsxy} />
                                                        {/*自助报到>军训服装尺码(江西工业工程职业技术学院)*/}
                                                        <AuthorizedRoute path="/clothingSizeJxgczyjsxy" component={SizeJxgczyjsxy} />
                                                        {/*自助报到>军训服装尺码(山东城市建设职业学院)*/}
                                                        <AuthorizedRoute path="/clothingSizeSdcsjszyxy" component={SizeSdcsjszyxy} />
                                                        {/*自助报到>校服尺码*/}
                                                        <AuthorizedRoute path="/schoolUniform" component={SchoolUniform} />
                                                        {/*自助报到>物品购买*/}
                                                        <AuthorizedRoute path="/buy" component={Buy} />
                                                        {/*自助报到>物品购买（新）*/}
                                                        <AuthorizedRoute path="/buyFa" component={BuyFa} />
                                                        {/*自助报到>网上缴费（非税平台）*/}
                                                        <AuthorizedRoute path="/nonTaxPay" component={NonTaxPayIndex} />
                                                        {/*自助报到>自选手机号码*/}
                                                        <AuthorizedRoute path="/choosePhoneNum" component={ChoosePhoneNum} />
                                                        {/*自助报到>自选手机号码(吉首大学张家界学院)*/}
                                                        <AuthorizedRoute path="/choosePhone" component={ChoosePhone} />
                                                        {/*自助报到>报到单自动切换*/}
                                                        {/* <AuthorizedRoute path="/print" component={RouterComponents} /> */}
                                                        {/*自助报到>办理学生平安保险*/}
                                                        <AuthorizedRoute path="/insurance" component={Insurance} />
                                                        {/*自助报到>查看宿舍*/}
                                                        <AuthorizedRoute path="/viewDormitory" component={ViewDormitory} />
                                                        {RouterComponents()}
                                                        {/*自助报到*/}
                                                        <Route path="/register" component={SelfRoute} />
                                                        {/*个人信息填写*/}
                                                        <AuthorizedRoute path="/personalInformation" component={UpdateInfo} />
                                                        {/*个人信息填写V_10*/}
                                                        <AuthorizedRoute path="/personalInformationv10" component={UpdateInfoV10} />
                                                        {/*个人信息填写V_10_1*/}
                                                        <AuthorizedRoute path="/personalInformationv10_1" component={UpdateInfoV10_1} />
                                                        {/*广东南华工商个人信息填写*/}
                                                        <AuthorizedRoute path="/infoGdnhgs" component={InfoGdnhgs} />
                                                        {/*湖南城市个人信息填写*/}
                                                        <AuthorizedRoute path="/infoHncs" component={InfoHncs} />
                                                        {/*江汉艺术职业学院个人信息填写*/}
                                                        <AuthorizedRoute path="/personalInformationFg" component={UpdateInfoFg} />
                                                        {/*自定义环节*/}
                                                        <AuthorizedRoute path="/customLink" component={CustomLink} />
                                                        {/*自助报到>是否住宿申请（江汉艺术学院）*/}
                                                        <AuthorizedRoute path="/applyDorm" component={ApplyDorm} />
                                                        {/*自助报到>查看学号（江汉艺术学院）*/}
                                                        <AuthorizedRoute path="/viewStudentId" component={ViewStudentId} />
                                                        {/*自助报到>绿色通道(铜陵学院)*/}
                                                        <AuthorizedRoute path="/channelTlxy" component={ChannelTlxy} />
                                                        {/*自助报到>心理健康普查（江汉艺术学院）*/}
                                                        <AuthorizedRoute path="/mentalHealthSurvey" component={MentalHealthSurvey} />
                                                        {/*自助报到>防疫登记（武汉软件）*/}
                                                        <AuthorizedRoute path="/antiepidemic" component={Antiepidemic} />
                                                        {/*自助报到>防疫登记（大理护理）*/}
                                                        <AuthorizedRoute path="/antiepidemicDlhl" component={AntiepidemicDlhl} />
                                                        {/*自助报到>信息比对（大理护理）*/}
                                                        <AuthorizedRoute path="/confirmInfoDlhl" component={ConfirmInfoDlhl} />
                                                        {/*自助报到>是否缴纳医疗保险(湖南城市学院)*/}
                                                        <AuthorizedRoute path="/medicalInsurance" component={MedicalInsurance} />
                                                        {/*自助报到>在线缴费(微信、支付宝)*/}
                                                        <AuthorizedRoute path="/payOnline" component={PayOnline}/>
                                                        {/*自助报到>在线缴费(生成订单支付)*/}
                                                        <AuthorizedRoute path="/orderPayOnline" component={OrderPayOnline}/>
                                                         {/*自助报到>绿色通道(宝鸡文理)*/}
                                                         <AuthorizedRoute path="/channelBjwl" component={ChannelBjwl}/>
                                                         {/*自助报到>查看学号（云南机电学院）*/}
                                                        <AuthorizedRoute path="/GetStudentId" component={GetStudentId} />
                                                         {/*学生照片上传页面*/}
                                                         <AuthorizedRoute path="/photoUpload" component={PhotoUpload}/>
                                                         <AuthorizedRoute path="/stuModifyPic" component={StuModifyPic}/>
                                                         {/*学生照片上传页面-快捷*/}
                                                         <AuthorizedRoute path="/photoUploadQuick" component={PhotoUploadQuick}/>
                                                         {/*学生照片上传页面（广西生态职院）*/}
                                                         <AuthorizedRoute path="/photoUploadGxstzy" component={PhotoUploadGxstzy}/>


                                                        {/* 训服和床上用品缴费 */}
                                                        <AuthorizedRoute path="/xfcsypPay" component={XfcsypPay} />
                                                        {/* 校园号预充值 */}
                                                        <AuthorizedRoute path="/xyhycz" component={Xyhycz} />
                                                        {/* 人脸身份信息核验 */}
                                                        <AuthorizedRoute path="/rlsfxxhy" component={Rlsfxxhy} />
                                                        {/* 一卡通核验人脸采集 */}
                                                        <AuthorizedRoute path="/ykthyrlcj" component={Ykthyrlcj} />
                                                        {/* 身份核验-湖南工商 */}
                                                        <AuthorizedRoute path="/identityVerification" component={IdentityVerification} />
                                                        {/* 财务缴费 */}
                                                        <AuthorizedRoute path="/financialPayment" component={FinancialPayment} />


                                                        {/* 身份核验(吉首) */}
                                                        <AuthorizedRoute path="/jsSfhy" component={JsSfhy} />
                                                        <AuthorizedRoute path="/jfPayFees" component={jfPayFees} />

                                                        <AuthorizedRoute path="/broadBand" component={broadBand} />

                                                        {/*通知公告详情*/}
                                                        <Route path="/notice/detail" component={NoticeDetail} />
                                                        {/*通知公告*/}
                                                        <Route path="/notice" component={Notice} />
                                                        {/*首页链接*/}
                                                        <Route path="/link" component={LinkDhlj} />
                                                        {/*个人信息（简易）*/}
                                                        <Route path="/info" component={Info} />
                                                        {/*个人信息（微调）*/}
                                                        <Route path="/pInfo" component={PInfo} />
                                                        {/*个人信息（新版）*/}
                                                        <Route path="/newInfo" component={NewInfo} />
                                                        {/* 个人信息（深圳技大） */}
                                                        <Route path="/szjdInfo" component={szjdInfo} />
                                                        {/*自助报到>网上缴费(阜新学院商联)*/}
                                                        <AuthorizedRoute path="/onlinePaymentFxxy" component={PayFxxy} />
                                                        {/*自助报到>网上缴费(微信)*/}
                                                        <AuthorizedRoute path="/wxChatPay" component={WxChatPay} />
                                                        {/* 同寝信息 */}
                                                        <Route path="/dormInfo" component={DormInfo} />
                                                        {/* 同省信息 */}
                                                        <Route path="/provinceInfo" component={ProvinceInfo} />
                                                        {/*视频栏目*/}
                                                        <Route path="/xysp" component={ViewVideo} />
                                                        {/*信息查询*/}
                                                        <Route path="/informationSearch" component={InformationSearch} />
                                                        {/* 录取查询 */}
                                                        <Route path="/lqcx" component={Lqcx} />
                                                        {/*首页*/}
                                                        <Route path="/index" component={Index} />
                                                        {/*默认页面*/}
                                                        <Route path="/" component={IndexRoute} />


                                                    </Switch>
                                                </div>
                                            </Content>
                                            <Footer />
                                        </Layout>
                                    )
                                }} />
                            </Switch>
                        </CSSTransitionGroup>
                    )
                }} />
            </Router>
        );
    }
}