import { RECEIVE_USERINFO, CLEAR_USERINFO } from '../actions/login'

const initState = window.localStorage.getItem('access_user') ? {user:JSON.parse(window.localStorage.getItem('access_user'))} : {
    user: null
}

const login = (state = initState, action) => {
    switch (action.type) {
        case RECEIVE_USERINFO:
            return { ...state, loading: false, user: action.user }
        case CLEAR_USERINFO:
            return {...state, loading: false, user: null}
        default:
            return state
    }

}

export default login