import React from 'react'
import { Flex } from '@components'
import { Form, Button, Modal } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import { message } from 'antd/lib/index'
import NHFetch from '../../../../utils/NHFetch'
import NHSignDraw from '@components/common/NHSignDraw'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { getLoginUser } from '@utils/NHCore'
import CommonTip from '../common'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import { Scrollbars } from 'react-custom-scrollbars'
import html2canvas from 'html2canvas'
import Roommate from './Roommate'
import css from './index.css'
import '../../../../../assets/css/viewDormitory/index.css'

const basicUrl = 'api/zhxg-yxwz'
const { confirm } = Modal

@Form.create()
export default class ViewDormitory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      xsid: getLoginUser().userId,
      zzbdhjid: this.getUrlParams('pkid'),
      mkbz: 'GD020_1',
      hjmc: '',
      editable: false,
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      modalVisible: false,
      signature: '', //签名对话框
      notification: '', //告知书富文本
      dormitoryInfo: null, //已办理后获取的宿舍信息
      roommateDetail: [], //舍友信息
      isViewRoommate: false, //是否在查看舍友
      roomDetailLoading: false,
      xssfytj: false //学生是否提交过告知书
    }
  }

  //渲染前获取信息
  componentDidMount() {
    this.queryQztjAndYlhj()
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({ nonOperationalData: res.data })
        if ((kfsj && kfsj['YXSJ'] != '1') || (ylhj && ylhj.length > 0) || (qzhj && qzhj.length > 0) || nohj) {
          this.setState({ operable: false })
        } else {
          this.getGzsPz()
        }
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: ''
      })
    }
  }

  getUrlParams = param => {
    return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  // 获取告知书
  getGzsPz = () => {
    const { xsid } = this.state
    NHFetch(`${basicUrl}/zzbd/rzgzs/getGzsPz?xsid=${xsid}`, 'GET').then(res => {
      if (res && res.code === 200) {
        this.setState({
          notification: res.data.nr,
          xssfytj: res.data.xssfytj
        })

        if (res.data.xssfytj === '0') {
          this.setState({ modalVisible: true })
        } else {
          this.getSelectedDetail()
        }
      }
    })
  }

  // 提交入住告知书
  handleSubmit = e => {
    e.preventDefault()
    const { signature } = this.state

    if (signature) {
      confirm({
        title: '确定提交?',
        onOk: () => {
          html2canvas(document.getElementById('signature'), { allowTaint: true, useCORS: true, scale: 1 }).then(canvas => {
            canvas.toBlob(blob => {
              this.saveXsGzs({ gzs: blob })
            })
          })
        }
      })
    } else {
      message.warning('请签名')
    }
  }

  // 保存告知书
  saveXsGzs = params => {
    const { xsid, zzbdhjid } = this.state
    const formData = new FormData()
    const token = window.sessionStorage.getItem('access_token')

    params.xsid = xsid
    params.zzbdhjid = zzbdhjid
    params.czpt = '1' //操作平台：PC端

    for (let key in params) {
      formData.append(key, params[key])
    }

    fetch(`${basicUrl}/zzbd/rzgzs/saveXsGzs`, {
      method: 'POST',
      headers: { token: token, loginUserId: xsid },
      body: formData
    })
      .then(async response => {
        const res = await response.json()
        if (res && res.code === 200) {
          if (res.data == '1') {
            message.success('信息保存成功！')
            this.getGzsPz()
            this.reloadMenu()
            this.setState({ editable: false, modalVisible: false })
          } else {
            message.error('信息保存失败，请重试！')
          }
        }
      })
      .catch(err => {
        message.error('信息保存失败，请重试！')
      })
  }

  // 已办理时获取宿舍信息
  getSelectedDetail = () => {
    NHFetch('/ssfp/getStuSsrzByXsid', 'GET').then(res => {
      if (res && res.code === 200) {
        this.setState({ dormitoryInfo: res.data })
      } else {
        this.setState({ dormitoryInfo: null })
      }
    })
  }

  // 查看舍友
  toCheckRoommate = () => {
    this.setState({ isViewRoommate: true })

    const { dormitoryInfo } = this.state
    this.setState({ isViewRoommate: true, roomDetailLoading: true })
    NHFetch('/ssfp/getRoommateInfor', 'get', {
      fjid: dormitoryInfo.FJID
    }).then(res => {
      if (res && res.code === 200) {
        this.setState({ roomDetailLoading: false })
        const result = res.data.sort((a, b) => a.CWH - b.CWH)
        this.setState({ roommateDetail: result })
      } else {
        message.error('获取舍友信息失败')
      }
    })
  }

  backFromRoommate = () => {
    this.setState({ isViewRoommate: false })
  }

  render() {
    const { modalVisible, signature, notification, xssfytj } = this.state
    const date = new Date()
    const { dormitoryInfo, roommateDetail, isViewRoommate, roomDetailLoading, xsid } = this.state

    return (
      <div className="viewDormitory">
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu ref={'siderMenu'} setMenuList={this.setMenuList} title="自助报到" mkbz={this.state.mkbz} menuType="自助报到" />
            <Flex.Item className="page_con_r">
              <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
              <div className="box" style={{ position: 'relative', overflow: 'hidden' }}>
                <Hint />
                <TitleTop title={this.state.hjmc} extra={false} />

                {xssfytj === '1' && (
                  <Form onSubmit={this.handleSubmit}>
                    <div className="p-sw">
                      {dormitoryInfo && (
                        <div className="selected-result">
                          <div className="title">您的床位</div>
                          <div className="selected-result-detail">
                            <table>
                              <tr>
                                <td>校区</td>
                                <td>{dormitoryInfo.XQMC}</td>
                              </tr>
                              <tr>
                                <td>楼栋</td>
                                <td>{dormitoryInfo.LDMC}</td>
                              </tr>
                              <tr>
                                <td>楼层</td>
                                <td>{dormitoryInfo.LCMC}</td>
                              </tr>
                              <tr>
                                <td>房号</td>
                                <td>{dormitoryInfo.FJ}</td>
                              </tr>
                              <tr>
                                <td>床号</td>
                                <td>{dormitoryInfo.CWH}</td>
                              </tr>
                            </table>
                          </div>
                          <Roommate roommateDetail={roommateDetail} isViewRoommate={isViewRoommate} back={this.backFromRoommate} loading={roomDetailLoading} selfId={xsid} />
                        </div>
                      )}

                      {!dormitoryInfo && <h1>暂无宿舍信息</h1>}

                      <div className="text-center mt-50">
                        {!isViewRoommate && dormitoryInfo && (
                          <Button type="primary" onClick={this.toCheckRoommate}>
                            查看舍友
                          </Button>
                        )}

                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                      </div>
                    </div>
                  </Form>
                )}

                {xssfytj === '0' && (
                  <div className={css.toSign}>
                    <a
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ modalVisible: true })
                      }}
                    >
                      请先签署告知书
                    </a>
                  </div>
                )}
              </div>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip hjmc={'查看宿舍'} key={'GD020_1'} mkbz="GD020_1" nonOperationalData={this.state.nonOperationalData} />
        )}

        {/* 用于生成告知书 */}
        <div id="signature" className={css.signature} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: 800, padding: '72px 68px' }}>
          <article dangerouslySetInnerHTML={{ __html: notification }} />
          <div style={{ paddingLeft: 450 }}>
            <div>
              签名：
              <img src={signature} alt="签名" style={{ width: 150 }} />
            </div>
            <div>
              {date.getFullYear()} 年 {date.getMonth() + 1} 月 {date.getDate()} 日
            </div>
          </div>
        </div>

        <Modal title="请阅读告知书后签名" visible={modalVisible} destroyOnClose width="max-content" okText="提交" onOk={this.handleSubmit} onCancel={() => this.setState({ modalVisible: false })}>
          <Scrollbars style={{ height: 380, width: 800 }}>
            <div className={css.signature} style={{ width: 800, padding: '72px 68px' }}>
              <article dangerouslySetInnerHTML={{ __html: notification }} />
              <NHSignDraw label={'签名'} onChange={value => this.setState({ signature: value?.signImg || '' })} />
            </div>
          </Scrollbars>
        </Modal>
      </div>
    )
  }
}
