import React from 'react';
import { Flex } from '@components';
import createHistory from 'history/createHashHistory';
const history = createHistory();
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import {Button, message, Table, Descriptions, Divider} from 'antd'
import  './index.css';
import NHFetch from "../../../utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";

//请求默认路径
const pathUrl = "api/zhxg-yxxt";

export default class DormInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roommateInfoList:[]
            // flmid:'',//父栏目id
        }
    }

    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getDormInfo(userId);
        }else{
            history.push({
                pathname: '/index',
            });
            message.error('未获取登录信息')
            return false;
        }
      }
    
      //加载室友信息
      getDormInfo = (xsid) => {
        NHFetch('/gdlm/qssy/getDormInfoByXsid', 'GET', {xsid: xsid})
          .then(res => {
            if (res && res.data) {
              this.setState({
                roommateInfoList: res.data,
              })
            }
          });
      }

    render() {
        const roommateInfoList = this.state.roommateInfoList  
        const columns = [
            //   render: text => <a>{text}</a>,
            {title: '姓名',dataIndex: 'XM',key: 'XM',},
            {title: '性别',dataIndex: 'XB',key: 'XB',},
            {title: '院系',dataIndex: 'BMMC',key: 'BMMC',},
            {title: '专业',dataIndex: 'ZYMC',key: 'ZYMC',},
            {title: '班级',dataIndex: 'BJMC',key: 'BJMC',},
            {title: '省市',dataIndex: 'JG',key: 'JG',},
          ];
      //     const roommateInfoList = [
      //       {
      //           key: 1,
      //           XM: '张伟',
      //           XB:'男',
      //           BMMC:'信科院',
      //           ZYMC:'通信工程',
      //           BJMC:'110',
      //           JG:'广东省广州市'
      //         },
      // ];
                   
        return (
            <div>
                <Flex className="w1180 mt-20" align="start">
                    <Flex.Item styleName="con_r">
                        <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '同寝信息',url:'/dormInfo'}
                            ]}
                        />
                <Table columns={columns} dataSource={roommateInfoList} pagination={false}/>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
