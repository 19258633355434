import React from 'react'
import { Table, Input, Icon, Form, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import '../index.css';
import NHSelect from '@components/common/NHSelect'
import NHFetch from '@utils/NHFetch'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'
import NHFormItem from "@components/common/NHFormItem";

const FormItem = Form.Item

class JtcyInitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jtcy: false,
      jtcyAdd: false,
      jtcyData: [],
      zzmmmList: [],
      tempStr: '',
      jtcyList: [], //家庭成员数据加载
      errorFiled: false,
      flag: false,
      showJtcyData: [],
    }
  }

  componentDidMount() {
    NHFetch(
      'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XTGL_ZZMMM',
      'GET'
    ).then(res => {
      if (res && res.data) {
        let zzmmmList = []
        for (let obj of res.data) {
          zzmmmList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ zzmmmList: zzmmmList })
      }
    })
    NHFetch(
      'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JTCYGX',
      'GET'
    ).then(res => {
      if (res && res.data) {
        let jtcyList = []
        for (let obj of res.data) {
          jtcyList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ jtcyList: jtcyList })
      }
    })
    this.setState({
      jtcyData: this.props.jtcyInitData ? [...this.props.jtcyInitData] : [],
      showJtcyData: this.props.jtcyInitData ? [...this.props.jtcyInitData] : []
    })
  }

  submitCheck =(flag) => {
    const submitCheck = this.props.submitCheck;
    if(typeof submitCheck ==="function"){
        submitCheck(flag);
    }
}

  // componentWillReceiveProps(nextProps) {
  //   if ('jtcyInitData' in nextProps && nextProps.jtcyInitData) {
  //     if (nextProps.jtcyInitData instanceof Array) {
  //       let temp = [...nextProps.jtcyInitData]
  //       if (!this.state.flag && temp.length > 0)
  //         this.setState({ jtcyData: temp, flag: true,showJtcyData:[...temp] })
  //     }
  //   }
  // }
  componentDidUpdate(preProps) {
    if (preProps.refresh !== this.props.refresh) {
      
      if (this.props.jtcyInitData && this.props.jtcyInitData.length > 0) {
        let temp = [...this.props.jtcyInitData]
        this.setState({ jtcyData: temp, flag: true, showJtcyData: [...temp] })
      }else {
        this.setState({ jtcyData: [], flag: true, showJtcyData: [] })
      }

    }
  }

  cacheJtcyData = cb => {
    this.props.form.validateFields((err, values) => {
      const { jtcyData, errorFiled, showJtcyData } = this.state
      console.log('cache', jtcyData)
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let errorPhone = ''
      let errorList = []
      let dataList = []
      let j =0
      for(let i =0 ; i<jtcyData.length;){
        let orign = jtcyData[i]
        let newItem = showJtcyData[j] || {}
        if(orign.pkid === newItem.pkid){
          dataList.push({
            ...newItem
          })
          i++;
          j++;
        }else {
          // 旧的被删掉了
          dataList.push({
            ...orign,
            del: '1'
          })
          i++
        }
      }
      for(;j<showJtcyData.length;j++){
        let newItem = showJtcyData[j]
        if (!newItem.gx && !newItem.xm && !newItem.lxfs){
          return message.error(`请完善第${j + 1}行信息再保存`)
          
        }
        dataList.push({
          ...newItem,
          del: '0'
        })
      }
     
    
      const { cacheFormData } = this.props
      cacheFormData('jtcy', dataList, true, 1)
      cb(true)
      this.setState({
        jtcy: false,
        jtcyAdd: false,
      })
    })
  }

  // 家庭成员 添加
  onjtcyAdd = () => {
    let { jtcyAdd, jtcyData, showJtcyData } = this.state
    if (!jtcyData) {
      jtcyData = []
    }
    if (!jtcyAdd) return message.error('操作逻辑异常!')
    let key = createUuid()
    showJtcyData.push({
      key: key,
      gx: '',
      xm: '',
      gzxxdw: '',
      zzmmm: '',
      lxfs: '',
      operation: '',
      qq: '',
      wx: '',
      errorFiled: false,
      del: '0',
    })
    
    this.setState({ jtcyData, showJtcyData })
  }
  // 家庭成员 关闭添加
  onjtcyClose = (e, index) => {
    e.preventDefault()
    let { jtcyData, showJtcyData } = this.state
   

    showJtcyData.splice(index, 1)
    console.log('showJtcyData', showJtcyData)
    this.setState({
      jtcyData: jtcyData,
      jtcyAdd: true,
      showJtcyData
    })
  }

  handleChange = (value, index, key) => {
    const errorFiled = key === 'lxfs' && !regPhone(value)
    let { showJtcyData, jtcyData } = this.state
    showJtcyData[index][key] = value
    showJtcyData[index]['errorFiled'] = errorFiled
    this.setState({ jtcyData, errorFiled, showJtcyData })
  }

  getzzmmmStr = value => {
    let temp = ''
    this.state.zzmmmList.forEach(i => {
      if (i.value === value) {
        temp = i.label
      }
    })
    return temp
  }

  render() {
    const { jtcy, jtcyData, jtcyAdd, jtcyList, showJtcyData,zzmmmList } = this.state
    const { form, isRequired, editable } = this.props
    let jtcyTransalate = {},zzmmTransalate={}; //家庭成员翻译
    for (let i = 0; i < jtcyList.length; i++) {
      jtcyTransalate[jtcyList[i].value] = jtcyList[i].label
    }
    for (let i = 0; i < zzmmmList.length; i++) {
      zzmmTransalate[zzmmmList[i].value] = zzmmmList[i].label
    }
    console.log('jtcyData', jtcyData)
    const jtcyColumns = [
      {
        title: '关系',
        key: 'gx',
        dataIndex: 'gx',
        render: (text, record, index) => {
          return jtcy ? (
            <NHSelect
              style={{ width: 100 }}
              required={true}
              sign='DMK_XSXX_JTCYGX'
              value={text}
              onChange={value => {
                this.handleChange(value, index, 'gx')
              }}
            />
          ) : (
            jtcyTransalate[text]
          )
        },
      },
      {
        title: '姓名',
        dataIndex: 'xm',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'xm')
              }}
              style={{ width: 100 }}
              placeholder='请填写姓名'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '政治面貌',
        dataIndex: 'zzmmm',
        render: (text, record, index) => {
          return jtcy ? (
              <NHSelect
                  style={{ width: 100 }}
                  required={true}
                  sign='DMK_XTGL_ZZMMM'
                  value={text}
                  dropdownMatchSelectWidth={false}
                  onChange={value => {
                    this.handleChange(value, index, 'zzmmm')
                  }}
              />
          ) : (
              zzmmTransalate[text]
          )
        },
      },
      {
        title: '身份证号码',
        dataIndex: 'sfzh',
        render: (text, record, index) => {
          return jtcy ? (
              <Input
                  value={text}
                  onChange={e => {
                    this.handleChange(e.target.value, index, 'sfzh')
                  }}
                  style={{ width: 100 }}
                  placeholder='请填写身份证号码'
              />
          ) : (
            text
          )
        },
      },
      {
        title: '工作单位及职务',
        dataIndex: 'gzxxdw',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'gzxxdw')
              }}
              style={{ width: 140 }}
              placeholder='请填写单位和职务'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '联系电话',
        dataIndex: 'lxfs',
        render: (text, record, index) => {
          return jtcy ? (
            <FormItem
              form={form}
              style={{ marginBottom: '0px', width: '140px' }}
            >
              <Input
                value={text}
                maxLength={11}
                onChange={e => {
                  this.handleChange(e.target.value, index, 'lxfs')
                }}
                style={{ width: 110 }}
                placeholder='请填写电话'
              />
              <div
                style={{
                  display: record.errorFiled ? 'block' : 'none',
                  color: '#ff4d4f',
                }}
              >
                {text ? FormRules.MOBILE_PHONE_NUMBER.message : '请填写电话'}
              </div>
            </FormItem>
          ) : (
            text
          )
        },
      },
      {
        title: 'QQ',
        dataIndex: 'qq',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'qq')
              }}
              style={{ width: 100 }}
              placeholder='请填QQ号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '微信',
        dataIndex: 'wx',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'wx')
              }}
              style={{ width: 100 }}
              placeholder='请填微信号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (jtcy) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onjtcyClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onjtcyClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]
    if (!jtcy) {
      jtcyColumns.pop()
    }
    return (
      <div>
        <TitleTop
          extra={editable}
          title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
          onEdit={() => {
            this.setState({
              jtcy: true,
              jtcyAdd: true,
              cacheData: deepCopy(jtcyData),
            })
            this.submitCheck(false);
          }}
          onSave={cb => {
            this.cacheJtcyData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              jtcy: false,
              jtcyAdd: false,
              jtcyData: deepCopy(this.state.cacheData),
            })
            this.submitCheck(true);
          }}
        />

        <Form>
          <div className='p-sw'>
            <Table
              bordered
              size='middle'
              pagination={false}
              dataSource={showJtcyData}
              columns={jtcyColumns}
            />
            {jtcyAdd && (
              <div
                className='mt-10'
                styleName='btn_add'
                onClick={this.onjtcyAdd}
              >
                添加家庭成员
              </div>
            )}
          </div>
        </Form>
      </div>
    )
  }
}

const JtcyForm = Form.create()(JtcyInitForm)
export default JtcyForm
