import React from 'react'
import {Carousel} from 'antd';
import { Route, Router, Link } from 'react-router-dom'
import createHistory from 'history/createHashHistory';
const history = createHistory();
import './index.css';
import NHFetch from "../../utils/NHFetch";
import {getLoginUser} from '@utils/NHCore';
const baseUrl = 'api/zhxg-yxwz/';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sytp:[],//首页图片
            dhlm:[],//导航栏目
            settings:{},
            logoUUID:'', //LOGO图(对应文档库的UUID)
        }
    }

    componentDidMount(){
        this.getYxwzLogo();
        this.getSytpList();
        this.getDhlmList();
        setTimeout(()=>{
            let settings = {
                effect:'fade',
                dots: true,
                speed: 10000,
            }
            this.setState({
                settings:settings
            })
        },2000);
    }

    //获取迎新网站LOGO
    getYxwzLogo = () => {
        NHFetch("api/zhxg-yxwz/dhlm/getLogo","GET")
            .then(resData => {
                if(resData && resData.data){
                    this.setState({
                        logoUUID:resData.data
                    });
                }
            });
    };

    getDhlmList=()=>{
        let params = {}
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(xsid){
            params={
                xsfs:2,
            }
        }
        NHFetch("api/zhxg-yxwz/dhlm/getDhlmList","GET",params)
            .then(resData => {
                if(resData && resData.data){
                    this.setState({dhlm:resData.data});
                }
            });
    }

    getSytpList=()=>{
        NHFetch("api/zhxg-yxwz/sytp/getSytpList", "GET")
            .then(resData => {
                if(resData && resData.data){
                    this.setState({sytp:resData.data});
                }
            });
    }

    click=(dhlm)=>{
        let lmlx = dhlm['lmlx'];
        let flbz = dhlm['flbz'];
        let mkbz = dhlm['mkbz'];
        let flmid = dhlm['flmid'];
        let lmlj = dhlm['lmlj'];
        let pkid = dhlm['pkid'];
        let count= dhlm['childCount'];
        let isUnique= dhlm['isUnique'];

        if(lmlx=='3'){
            history.push({
                    pathname:'/'+mkbz,
                }
            );
        }else if(lmlx=='1'){
            if (isUnique === '1') {
                history.push({
                        pathname: '/notice/detail',
                        search: 'pkid=' + pkid,
                    }
                );
            } else {
                history.push({
                        pathname:'/notice',
                        search:'flbz='+flbz,
                    }
                );
            }
        }else if(lmlx=='2'){
                if(count>0){
                    history.push({
                            pathname:'/link',
                            search:'pkid='+pkid,
                        }
                    );
                }else{
                    window.open(lmlj);
                }
        }

    }

    render() {
        const settings = this.state.settings;
        const {logoUUID} = this.state;
        let show = false;
        if(JSON.stringify(settings)==="{}"){
            show = false
        }else{
            show = true;
        }
        return (
            <div>
                <div styleName="top">
                    <div styleName="top_con">
                        <img src={logoUUID ? "anonymity/docrepo/download?attachmentId=" + logoUUID : ''} alt=""/>
                    </div>
                </div>
                <div styleName="list">
                    <div className="w1180">
                        {
                            this.state.dhlm.map((elem, index) => {
                            return (
                                <span key={index} styleName="item" onClick={()=>{
                                    this.click(elem);
                                }}>
                                    {elem.lmmc}
                                    {elem.sfrdlm_dm == "1" && <img src={require(`../../../assets/images/hot.png`)} style={{position:"relative",top:"-8px"}}/>}
                                    </span>
                                )})
                        }
                    </div>
                </div>
                <div className="_bg" styleName="banner">
                    {show?<Carousel {...settings} autoplay>
                        {this.state.sytp.map((elem, index) => {
                            let pkid = elem['PKID'];
                            return(
                                <div styleName="banner" key={index}>
                                    <img style={{width:'100%',height:'100%'}} src={"api/zhxg-yxwz/sytp/getPicture/"+pkid} alt=""/>
                                </div>
                            )
                        })}
                    </Carousel>:
                        this.state.sytp.length>0&&
                            <div styleName="banner">
                                 <img style={{width: '100%', height: '100%'}} src={"api/zhxg-yxwz/sytp/getPicture/" + this.state.sytp[0].PKID} alt=""/>
                            </div>
                    }
                </div>
            </div>
        )
    }
}