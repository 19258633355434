import React, { Component } from "react";
import { Form, Icon, Input, Button, message, Row, Col } from "antd";
import NHFetch from "@utils/NHFetch";
const Item = Form.Item;
class Lqcx extends Component {
  // 初始化state
  state = {
    result_status: 0,
    data: null,
    xm: '',
  };
  handleSubmit = (event) => {
    // 阻止事件的默认行为:此处是阻止表单默认提交给服务器的行为;
    event.preventDefault();
    // 对所有表单字段进行检验
    this.props.form.validateFields(async (err, values) => {
      // 检验成功
      if (err) return false;
      let params = values;
      let url = "/newStudent/getStudentLqInfo ";
      NHFetch(url, "POST", params)
        .then(res => {
          if (res) {
            console.log('后端数据:', res)
            if (!res.data) {
              // 录取失败
              this.setState({
                result_status: -1,
                xm: values.xm
              })
              // return message.info("暂无结果")
              return false;
            }
            // 录取成功
            // message.success("查询成功")
            this.setState({
              result_status: 1,
              data: res.data
              // data: {
              //   XH: res.data.XH,
              //   KSH: res.data.KSH,
              //   XM: res.data.XM,
              //   BDSJ: res.data.BDSJ,
              //   SFZJH: res.data.SFZJH,
              //   ZYMC: res.data.ZYMC,
              //   BJMC: res.data.BJMC,
              //   EMSH: res.data.EMSH,
              //   XB: res.data.XB,
              //   RXXZ: res.data.RXXZ,
              //   ZSDH: res.data.ZSDH,
              //   KSLX: res.data.KSLX
              // }
            })
          }
        }).catch((res) => {
          console.log('测试:', res)
          message.error("系统繁忙")
        });
    });
  };
  // 返回查询
  goBack = () => {
    this.setState({ result_status: 0 })
  }
  render() {
    // 得到具强大功能的form对象
    const form = this.props.form;
    const { getFieldDecorator } = form; //用于和表单进行双向绑定
    const { result_status, data, xm } = this.state
    // console.log('测试111:', result_status, data)
    return (
      <div
        className="lq-page"
        style={{ minHeight: "550px", backgroundColor: "#f8f8f9" }}
      >
        {/* 查询界面 */}
        {
          result_status === 0 ?
            <section
              className="lq-content"
              style={{
                width: "500px",
                margin: "10px auto",
                padding: "20px 30px",
                border: "2px solid #eaeeed",
                boxShadow: "1px 1px 20px #999",
              }}
            >
              <h2 style={{ textAlign: "center" }}>新生录取查询</h2>
              <Form
                onSubmit={this.handleSubmit}
                className="lq-form"
              // labelCol={{ span: 5 }}
              // wrapperCol={{ span: 19 }}
              >
                <Item label="考生号:" style={{ marginBottom: '6px' }}>
                  {/* 表单验证：考生号 */}
                  {getFieldDecorator("ksh", {
                    // 配置对象: 属性名是特定的一些名称，声明式验证: 直接使用别人定义好的验证规则进行验证
                    rules: [
                      {
                        required: true,
                        message: "请输入考生号",
                      },
                    ],
                    validateTrigger: "onBlur", //失去焦点时触发验证
                    // initialValue: "8888", // 初始值
                    initialValue: "", // 初始值
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="考生号"
                    />
                  )}
                </Item>
                <Item label="身份证号:" style={{ marginBottom: '6px' }}>
                  {/* 表单验证：身份证号 */}
                  {getFieldDecorator("sfzjh", {
                    // 配置对象: 属性名是特定的一些名称，声明式验证: 直接使用别人定义好的验证规则进行验证
                    rules: [
                      {
                        required: true,
                        message: "请输入身份证号",
                      },
                      { min: 18, message: "身份证号为18位" },
                      { max: 18, message: "身份证号为18位" },
                    ],
                    validateTrigger: "onBlur", //失去焦点时触发验证
                    // initialValue: "430855122702169852", // 初始值
                    initialValue: "", // 初始值
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="身份证号"
                    />
                  )}
                </Item>
                <Item label="姓名:" style={{ marginBottom: '6px' }}>
                  {/* 表单验证：姓名  */}
                  {getFieldDecorator("xm", {
                    // 配置对象: 属性名是特定的一些名称，声明式验证: 直接使用别人定义好的验证规则进行验证
                    rules: [
                      {
                        required: true,
                        message: "请输入姓名",
                      },
                    ],
                    validateTrigger: "onBlur", //失去焦点时触发验证
                    // initialValue: "宁荣荣", // 初始值
                    initialValue: "", // 初始值
                  })(
                    <Input
                      prefix={
                        <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="姓名"
                    />
                  )}
                </Item>
                <Item style={{ marginBottom: '6px' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="lq-form-button"
                    style={{ width: "100%" }}
                  >
                    查询
              </Button>
                </Item>
              </Form>
            </section>
            : null
        }
        {/* 查询成功 */}
        {
          result_status === 1 ?
            <section
              className="jg-content"
              style={{
                width: "900px",
                minHeight: "500px",
                margin: "10px auto",
                padding: "20px 30px",
                border: "2px solid #eaeeed",
                boxShadow: "1px 1px 20px #999",
              }}
            >
              <h2>录取结果:</h2>
              <hr />
              <h3 style={{ fontWeight: 700, fontSize: "18px", color: "#f0323b" }}>
                恭喜你被我校录取！请仔细核对以下录取信息！{data && data.XH ? `请牢记你的学号：${data.XH}` : ''}
              </h3>
              <div style={{ fontSize: "16px", color: "#0d1119" }}>
                <p>
                  <span>考生号：{data && data.KSH ? data.KSH : ''}，</span>
                  <span>姓名：{data && data.XM ? data.XM : ''}</span>
                </p>
                <p>
                  <span>身份证号：{data && data.SFZJH ? data.SFZJH : ''}，</span>
                  <span>性别：{data && data.XB ? data.XB : ''}</span>
                </p>
                <p>您在参加{data.KSLX}考试中，成绩符合录取规定，</p>
                <p>
                  被我校正式录取，就读 <span>{data && data.ZYMC ? data.ZYMC : ''}</span> 专业，请
              <span>{data && data.BDSJ ? data.BDSJ : ''}</span>到我校报到！
            </p>
                <p>
                  录取通知书已寄发，EMS编码为：
              <span style={{ color: "#f0323b" }}>{data && data.EMSH ? data.EMSH : ''}</span>
              ；您可以在
              <a
                    href="https://www.ems.com.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    邮政速递物流网
              </a>
              查询录取通知书状态！
            </p>
                <p>如有疑问，您可以拨打招生办的电话咨询：{data && data.ZSDH ? data.ZSDH : ''}</p>
                <p style={{ display: 'none' }}>
                  <a
                    href={data && data.RXXZ ? data.RXXZ : '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: 700, fontSize: "18px", color: "#f0323b" }}
                  >
                    入学须知
            </a>
                </p>
                <Button type="link" style={{ width: "100%", fontSize: "18px" }} onClick={this.goBack}>返回查询</Button>
              </div>
            </section>
            : null
        }
        {/* 查询失败 */}
        {result_status === -1 ?
          <section
            className="jg-content"
            style={{
              width: "900px",
              minHeight: "500px",
              margin: "10px auto",
              padding: "20px 30px",
              border: "2px solid #eaeeed",
              boxShadow: "1px 1px 20px #999",
            }}
          >
            <h2>未被录取:</h2>
            <hr />
            <h3 style={{ fontWeight: 700, fontSize: "18px", color: "#f0323b" }}>
              {xm ? xm : ''}同学，暂未查询到您的录取信息！
        </h3>
            <Button type="link" style={{ width: "100%", marginTop: "100px", fontSize: "18px" }} onClick={this.goBack}>返回查询</Button>
          </section>
          : null}
      </div>
    );
  }
}
/*
包装Form组件生成一个新的组件: Form(Login)
新组件会向Form组件传递一个强大的对象属性: form
 */
const WrapLqcx = Form.create()(Lqcx);
export default WrapLqcx;
/*  */
