import React from 'react'
import { Flex } from '@components'
import { Form, Button } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import { message } from 'antd/lib/index'
import NHFetch from '../../../../utils/NHFetch'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { getLoginUser } from '@utils/NHCore'
import CommonTip from '../common'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import '../../../../../assets/css/viewDormitory/index.css'

@Form.create()
export default class ViewDorm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            xsid: getLoginUser().userId,
            zzbdhjid: this.getUrlParams('pkid'),
            mkbz: 'GD006_8',
            hjmc: '',
            editable: false,
            nextUrl: '', //左侧菜单加载完后传进来
            search: '',
            operable: true, //是否显示环节
            nonOperationalData: {}, //不可操作的数据
            modalVisible: false,
            dormitoryInfo: null, //已办理后获取的宿舍信息
            isTransact: false,     //环节是否已办理
        }
    }

    //渲染前获取信息
    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj()
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.code == 200) {
                    if (res.data) {
                        this.setState({
                            isTransact: true
                        });
                    }
                } else {
                    message.error("检查环节办理接口调用失败，请稍后再试")
                }
            });
    };

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : ''
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false
            })
            return
        }
        let zzbdhjid = this.getQueryString('pkid')
        NHFetch('/zzbd/queryQztjAndYlhj', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
            if (res && res.data) {
                let kfsj = res.data['kfsj']
                let ylhj = res.data['ylhj']
                let qzhj = res.data['qzhj']
                let nohj = res.data['nohj']
                this.setState({ nonOperationalData: res.data })
                if ((kfsj && kfsj['YXSJ'] != '1') || (ylhj && ylhj.length > 0) || (qzhj && qzhj.length > 0) || nohj) {
                    this.setState({ operable: false })
                } else {
                    this.getSelectedDetail()
                }
            }
        })
    }

    getQueryString = name => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = menuList => {
        let nextCount = 0
        let bz = this.state.mkbz
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC
                this.setState({ hjmc: hjmc })
                nextCount = index + 1
                return
            }
        })
        let menu = menuList[nextCount]
        if (menu) {
            let hjdm = menu.HJDM
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm]
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = '/customLink'
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: ''
            })
        }
    }

    getUrlParams = param => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //确认入住
    handleConfirmSubmit = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : "";
        let zzbdhjid = this.getQueryString("pkid");
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res) {
                    if (res.data === 1) {
                        this.setState({ isTransact: true });
                        this.reloadMenu();
                    }
                } else {
                    message.error("办理环节失败，请稍后重试！");
                }
            }
        );
    }

    // 已办理时获取宿舍信息
    getSelectedDetail = () => {
        NHFetch('/ssfp/getStuSsrzByXsid', 'GET').then(res => {
            if (res && res.code === 200) {
                this.setState({ dormitoryInfo: res.data })
            } else {
                this.setState({ dormitoryInfo: null })
            }
        })
    }


    render() {
        const { dormitoryInfo, isTransact } = this.state

        return (
            <div className="viewDormitory">
                {this.state.operable ? (
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu ref={'siderMenu'} setMenuList={this.setMenuList} title="自助报到" mkbz={this.state.mkbz} menuType="自助报到" />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
                            <div className="box" style={{ position: 'relative', overflow: 'hidden' }}>
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />

                                <Form onSubmit={this.handleSubmit}>
                                    <div className="p-sw">
                                        {dormitoryInfo && (
                                            <div className="selected-result">
                                                <div className="title">您的床位</div>
                                                <div className="selected-result-detail">
                                                    <table>
                                                        <tr>
                                                            <td>校区</td>
                                                            <td>{dormitoryInfo.XQMC}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>楼栋</td>
                                                            <td>{dormitoryInfo.LDMC}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>楼层</td>
                                                            <td>{dormitoryInfo.LCMC}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>房号</td>
                                                            <td>{dormitoryInfo.FJ}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>床号</td>
                                                            <td>{dormitoryInfo.CWH}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        )}

                                        {!dormitoryInfo && <h1>暂无宿舍信息</h1>}

                                        <div className="text-center mt-50">
                                            {!isTransact && dormitoryInfo && (
                                                <Button type="primary" onClick={this.handleConfirmSubmit}>
                                                    确认入住
                                                </Button>
                                            )}

                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Flex.Item>
                    </Flex>
                ) : (
                    <CommonTip hjmc={'查看宿舍'} key={'GD006_8'} mkbz="GD006_8" nonOperationalData={this.state.nonOperationalData} />
                )}

            </div>
        )
    }
}
