/**
 * @description: 校验开头为1的11位手机号，正确返回true,错误返回false
 * @param {*} params:需要校验的值
 * @return {*} Boolean
 * @author: 琴时
 */
const regPhone = (params) => {
    const reg = /^1\d{10}$/
    return reg.test(params)
};

/**
 * @description: 设置表单验证规则
 * @param {*}
 * @return {*}
 * @author: 琴时
 */
const FormRules = {
    MOBILE_PHONE_NUMBER: {
        pattern: /^1\d{10}$/,//校验开头为1的11位手机号
        message: "请输入正确的手机号码"
    },
    ID_CARD_NUMBER: {
        pattern: /^\d{15}|\d{17}[0-9X]$/,//校验15位或18位身份证号
        message: "请输入正确的身份证号"
    },
}

module.exports = {
    regPhone,
    FormRules
}