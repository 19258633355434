import React from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import styles from './index.css'
import { Flex } from '@components';
import { getLoginUser } from '@utils/NHCore';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import { Button, message } from 'antd';
import NHFetch from "@utils/NHFetch";
import { printHtml } from '@utils/printHtml.js'
import createHistory from 'history/createHashHistory';
const history = createHistory();
pdfjs.GlobalWorkerOptions.workerSrc = `${window.location.origin}${window.location.pathname}public/js/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            numPages: 0,
            pdfLoading: true,
            flag: true,
            bddPdf:''
        }
    }

    componentDidMount() {
        let xsid = getLoginUser() ? getLoginUser().userId : undefined;
        if (xsid) {
            NHFetch("/bdd/exportResult", "GET", { xspkid: xsid })
                .then((res) => {
                    if (res) {
                        if (res.data === '1') {
                            this.setState({
                                flag: true,
                            })
                        } else {
                            this.setState({
                                flag: false,
                                tips: res.data
                            })
                        }
                    }
                })
            
            this.getBddPdf()
        } else {
            message.error('未获取登录信息')
            history.push({
                pathname: '/index',
            });
            return false;
        }

    }

    //打印报到单回调
    handlePrint = () => {
        console.log(222)
        let $dom = document.getElementById('pdf-viewer')
        console.log('dom', $dom)
        printHtml($dom)
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages)
        console.log(11111)
        this.setState({
            numPages,
            pdfLoading: false
        })
    }

    // 获取报到单pdf
    getBddPdf = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : ''
        let url = `${window.location.origin}${window.location.pathname}api/zhxg-yxwz/bdd/exportXsXxShPDF?xspkid=${xsid}`
        fetch(url)
            .then(function (response) {
            return response.blob()
            })
            .then(responseBlob => {
            const headers = new Headers()
            headers.set('Content-Type', 'application/pdf')
            let newResponse = new Response(responseBlob, { headers })
            return newResponse.blob()
            })
            .then(res => {
            const bddPdf = URL.createObjectURL(res)
            this.setState({ bddPdf })
            })
    }

    //通过iframe打印打印报到单回调
    handlePrintNew = () => {
        const iframeWindow = document.getElementById('bddPdfIframe').contentWindow
        iframeWindow.print()
    }

    render() {
        const { numPages, flag, bddPdf } = this.state

        let xsid = getLoginUser() ? getLoginUser().userId : '';
        return (
            <div> 
                <Flex className="w1180 mt-20" align="start">

                    <Flex.Item className="page_con_r">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '入学报到单', url: '/rxbdd' },
                            ]}
                        />
                        {this.state.flag ?
                            <div className="box">
                                <Button type="primary" loading={this.state.pdfLoading} ghost style={{ marginRight: '10px', float: 'right' }} onClick={this.handlePrintNew}>打印报到单</Button>
                                <Button type="primary" ghost style={{ marginRight: '10px', float: 'right' }}>
                                    <a href={`/api/zhxg-yxwz/bdd/exportXsXxShPDF?xspkid=${xsid}`}>导出报到单</a></Button>
                                    <div style={{ width: '1200px', position:'absolute',visibility:'hidden',zIndex: -999 }}>
                                        <iframe src={bddPdf} id='bddPdfIframe'  height="900px;" width="800px" />
                                    </div>
                                <div id='pdf-viewer' style={{ marginTop: '50px' }}>
                                    <Document file={bddPdf}
                                        options={{
                                            // https://www.cnblogs.com/KingJames/p/11763590.html
                                            //    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                            cMapUrl: `${window.location.origin}${window.location.pathname}public/js/`,
                                            //    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,// 缺乏字体包会导致pdf显示不全
                                            standardFontDataUrl: `${window.location.origin}${window.location.pathname}public/js/`,// 缺乏字体包会导致pdf显示不全
                                            cMapPacked: true,

                                        }}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                        {
                                            Array.from(new Array(numPages), (el, index) => {
                                                return <Page pageNumber={index + 1} scale={1.8} key={`page_${index}`} />

                                            })
                                        }
                                    </Document>
                                </div>

                            </div> : (
                                <div style={{ textAlign: 'center', fontSize: '20px' }}>
                                    {this.state.tips || '未上传打印模板，不可下载！'}
                                </div>
                            )
                        }

                    </Flex.Item>
                </Flex>


            </div>
        )
    }
}