import React from 'react'
import { Table, Input, Icon, Form, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import '../index.css'
import NHFetch from '@utils/NHFetch'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'
const FormItem = Form.Item

class ZyshgxInitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zyshgx: false,
      zyshgxAdd: false,
      zyshgxData: [],
      zzmmmList: [],
      tempStr: '',
      errorFiled: false,
      flag: false,
    }
  }

  componentDidMount() {
    NHFetch(
      'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XTGL_ZZMMM',
      'GET'
    ).then(res => {
      if (res && res.data) {
        let zzmmmList = []
        for (let obj of res.data) {
          zzmmmList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ zzmmmList: zzmmmList })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if ('zyshgxInitData' in nextProps && nextProps.zyshgxInitData) {
      if (nextProps.zyshgxInitData instanceof Array) {
        let temp = [...nextProps.zyshgxInitData]
        if (!this.state.flag && temp.length > 0)
          this.setState({ zyshgxData: temp, flag: true })
      }
    }
  }

  cacheZyshgxData = cb => {
    this.props.form.validateFields((err, values) => {
      const { zyshgxData, errorFiled } = this.state
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let dataList = []
      let errorPhone = ''
      let errorList = []
      if (zyshgxData && zyshgxData.length > 0) {
        for (let i = 0; i < zyshgxData.length; i++) {
          let data = zyshgxData[i]
          // let pattern = /^1\d{10}$/
          // if (data.lxfs) {
          //   if (!pattern.test(data.lxfs)) {
          //     if (errorList.length == 0) {
          //       errorPhone += data.lxfs
          //     } else {
          //       errorPhone += ',' + data.lxfs
          //     }
          //     errorList.push(data)
          //   }
          // }
          if (!data.gx && !data.xm && !data.gzxxdw && !data.lxfs) {
            return message.error(`请完善第${i + 1}行信息再保存`)
          } else {
            dataList.push(data)
          }
        }
      }
      // if (errorList && errorList.length > 0) {
      //   message.error(`${errorPhone}号码填写错误`)
      //   return
      // }
      const { cacheFormData, getChildComponent } = this.props
      getChildComponent(3, true)
      cacheFormData('zyshgx', dataList, true)
      cb(true)
      this.setState({
        zyshgx: false,
        zyshgxAdd: false,
      })
    })
  }

  //添加
  onzyshgxAdd = () => {
    let { zyshgxAdd, zyshgxData = [] } = this.state
    if (!zyshgxAdd) return message.error('操作逻辑异常!')
    zyshgxData.push({
      key: createUuid(),
      gx: '',
      xm: '',
      gzxxdw: '',
      zzmmm: '',
      lxfs: '',
      operation: '',
      errorFiled: false,
    })
    this.setState({
      zyshgxData,
    })
  }
  // 家庭成员 保存添加
  //   onzyshgxSave = () => {
  //     let { zyshgxData } = this.state
  //     zyshgxData.push()
  //     this.setState({
  //       zyshgxData: zyshgxData,
  //       zyshgxAdd: false,
  //     })
  //   }
  //成员删除
  onzyshgxClose = (e, index) => {
    e.preventDefault()
    let { zyshgxData } = this.state
    zyshgxData.splice(index, 1)
    this.setState({
      zyshgxData: zyshgxData,
      zyshgxAdd: true,
    })
  }

  handleChange = (value, index, key) => {
    const errorFiled = key === 'lxfs' && !regPhone(value)
    let zyshgxData = this.state.zyshgxData
    zyshgxData[index][key] = value
    zyshgxData[index]['errorFiled'] = errorFiled
    this.setState({ zyshgxData, errorFiled })
  }

  getzzmmmStr = value => {
    let temp = ''
    this.state.zzmmmList.forEach(i => {
      if (i.value === value) {
        temp = i.label
      }
    })
    return temp
  }

  //   deepCopy = obj => {
  //     if (typeof obj != 'object') {
  //       return obj
  //     }

  //     if (this.isArrayFn(obj)) {
  //       let newobj = []
  //       for (let i = 0; i < obj.length; i++) {
  //         newobj.push(this.deepCopy(obj[i]))
  //       }
  //       return newobj
  //     } else {
  //       let newobj = {}
  //       for (var attr in obj) {
  //         newobj[attr] = this.deepCopy(obj[attr])
  //       }
  //       return newobj
  //     }
  //   }

  //   isArrayFn = value => {
  //     if (typeof Array.isArray === 'function') {
  //       return Array.isArray(value)
  //     } else {
  //       return Object.prototype.toString.call(value) === '[object Array]'
  //     }
  //   }

  render() {
    const { zyshgx, zyshgxData, zyshgxAdd } = this.state
    const { getChildComponent, form } = this.props
    // const { getFieldDecorator } = form
    const zyshgxColumns = [
      {
        title: '关系',
        key: 'gx',
        dataIndex: 'gx',
        render: (text, record, index) => {
          return zyshgx ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'gx')
              }}
              style={{ width: 100 }}
              placeholder='请填写关系'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '姓名',
        dataIndex: 'xm',
        render: (text, record, index) => {
          return zyshgx ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'xm')
              }}
              style={{ width: 100 }}
              placeholder='请填写姓名'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '工作单位及职务',
        dataIndex: 'gzxxdw',
        render: (text, record, index) => {
          return zyshgx ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'gzxxdw')
              }}
              style={{ width: 140 }}
              placeholder='请填写单位和职务'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '联系电话',
        dataIndex: 'lxfs',
        render: (text, record, index) => {
          return zyshgx ? (
            <FormItem
              form={form}
              style={{ marginBottom: '0px', width: '140px' }}
            >
              <Input
                value={text}
                maxLength={11}
                onChange={e => {
                  this.handleChange(e.target.value, index, 'lxfs')
                }}
                style={{ width: 110 }}
                placeholder='请填写电话'
              />
              <div
                style={{
                  display: record.errorFiled ? 'block' : 'none',
                  color: '#ff4d4f',
                }}
              >
                {text ? FormRules.MOBILE_PHONE_NUMBER.message : '请填写电话'}
              </div>
            </FormItem>
          ) : (
            text
          )
        },
      },
      {
        title: 'QQ',
        dataIndex: 'qq',
        render: (text, record, index) => {
          return zyshgx ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'qq')
              }}
              style={{ width: 100 }}
              placeholder='请填QQ号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '微信',
        dataIndex: 'wx',
        render: (text, record, index) => {
          return zyshgx ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'wx')
              }}
              style={{ width: 100 }}
              placeholder='请填微信号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (zyshgx) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onzyshgxClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onzyshgxClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]
    if (!zyshgx) {
      zyshgxColumns.pop()
    }
    return (
      <div>
        <TitleTop
          title='主要社会关系'
          onEdit={() => {
            this.setState({
              zyshgx: true,
              zyshgxAdd: true,
              cacheData: deepCopy(zyshgxData),
            })
            getChildComponent(3, false)
          }}
          onSave={cb => {
            this.cacheZyshgxData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              zyshgx: false,
              zyshgxAdd: false,
              zyshgxData: deepCopy(this.state.cacheData),
            })
          }}
        />

        <Form>
          <div className='p-sw'>
            <Table
              bordered
              size='middle'
              pagination={false}
              dataSource={zyshgxData}
              columns={zyshgxColumns}
            />
            {zyshgxAdd && (
              <div
                className='mt-10'
                styleName='btn_add'
                onClick={() => {
                  this.onzyshgxAdd()
                }}
              >
                添加主要社会关系
              </div>
            )}
          </div>
        </Form>
      </div>
    )
  }
}
const ZyshgxForm = Form.create()(ZyshgxInitForm)
export default ZyshgxForm
