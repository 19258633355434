import React from "react";
import { Form, Select, Input, InputNumber, Upload, message } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import { Flex } from '@components';
import NHFormItem from '@components/common/NHFormItem/index';
import NHCascader from "@components/common/NHCascader"; 
import { createUuid } from '@utils/NHCore';
import NHFetch from '@utils/NHFetch';
import { FormRules } from "@utils/RegExp";

import './index.css';

const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"; //上传logo路径
import { getLoginUser } from "@utils/NHCore";

function beforeUpload(file) {

    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
        message.error("You can only upload JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
}

class JbxxInitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jbxx: false,
            xsid: '',
            userInfoData: null,
            zzmmmList: [],
            mzmList: [],
            textData: '',
            jgmStr: '',
            jtStr: '',
            xsSsInfo: ''
        }
    }
 
    componentDidMount() {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        }
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }

    onSavejbxxFormData = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                cb(false);
                return;
            }
            cb(true);
            const { cacheFormData } = this.props;
            if (this.state.jtStr !== '') {
                values.jtdzDetail = this.state.jtStr;
            } else {
                values.jtdzDetail = this.props.jbxxFormData ?
                    this.props.jbxxFormData.jtdzDetail ? this.props.jbxxFormData.jtdzDetail : ""
                    : "";
            }
            cacheFormData('jbxx', values);
            this.setState({
                jbxx: false
            });
        });

    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10';
                NHFetch(url, 'POST')
                    .then(res => {
                        if (res && res.data) {
                            message.success("上传成功!")
                        }
                    });
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }

    onNHCascaderChange = (value, selectedOptions, type) => {
        let label = '';
        if (selectedOptions) {
            selectedOptions.forEach((i) => {
                label += i.label;
            });
        }
        if (type == 'jg') {
            this.setState({ jgmStr: label });
        } else {
            this.setState({ jtStr: label });
        }

    }

    render() {
        const { form, jbxxFormData, getChildComponent, userInfoData, stuSsxx, editXszp } = this.props;
        const { jbxx, imageUrl, tempUrl, xsid } = this.state;
        const jtjddz = jbxxFormData ? jbxxFormData.jtjddz : '';
        const jtdzDetail = jbxxFormData ? jbxxFormData.jtdzDetail : '';
        let ssxx = '';
        if (stuSsxx && stuSsxx.xqmc && stuSsxx.ldmc && stuSsxx.fj && stuSsxx.cwh) {
            ssxx = stuSsxx.xqmc + stuSsxx.ldmc + ' - ' + stuSsxx.fj + ' - ' + stuSsxx.cwh;
        }
        return (
  
            <Form layout="inline">
                <TitleTop
                    title="基本信息"
                    onEdit={type => {
                        this.setState({
                            jbxx: true
                        });
                        getChildComponent(1, false);
                    }}
                    onSave={cb => {
                        this.onSavejbxxFormData(cb);
                        getChildComponent(1, true);
                    }}
                    onClose={() => {
                        this.setState({
                            jbxx: false
                        });
                    }}
                />

                <Flex className="p-sw">
                    <div styleName="jbxx_icon" className="_bg">
                        {jbxx && editXszp === "true" ?
                            <Upload
                                name="photo"
                                accept={'image/*'}
                                showUploadList={false}
                                action={uploadUrl + "?xsid=" + xsid}
                                beforeUpload={beforeUpload}
                                onChange={this.handleUploadChange}
                            >
                                {imageUrl || userInfoData ? (
                                    imageUrl ?

                                        <img
                                            src={imageUrl}
                                            alt=""
                                            className="avatar"
                                        />
                                        : tempUrl
                                ) : (
                                        <div styleName="upload-box">
                                            <div styleName="upload-title">
                                                上传图片
                                        </div>
                                        </div>
                                    )}
                            </Upload>
                            :
                            imageUrl ?
                                <img
                                    src={imageUrl}
                                    alt=""
                                    className="avatar"
                                />
                                :
                                tempUrl

                        }

                    </div>
                    <Flex.Item className="ml-20">
                        <div styleName="jbxx_item">
                            <span styleName="item-label">姓名：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.XM : ''}

                                {
                                    userInfoData && userInfoData.XB ?

                                        userInfoData.XB === '男' ?
                                            <img src={require('@images/boy.png')} alt="" />
                                            : userInfoData.XB === '女' ?
                                                <img src={require('@images/girl.png')} alt="" />
                                                :
                                                <img src={require('@images/weizhixb.png')} alt="" />
                                        : ''

                                }

                            </div>
                        </div>
                        <div styleName="jbxx_item">
                            <span styleName="item-label">学号：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.XH : ''}
                            </div>
                        </div>
                        <div styleName="jbxx_item">
                            <span styleName="item-label">考生号：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {jbxxFormData ? jbxxFormData.ksh : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">
                                身份证件号：
                            </span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.SFZJH : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">出生年月：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.CSRQ : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">政治面貌：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.ZZMM : ''}
                            </div>
                        </div>

                        <div styleName="jbxx_item">
                            <span styleName="item-label">民族：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.MZ : ''}
                            </div>
                        </div>

                        {userInfoData.JG?
                        <div styleName="jbxx_item">
                            <span styleName="item-label">籍贯：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData ? userInfoData.JG : ''}
                            </div>
                        </div>:''
                        }

                        {jbxxFormData.byzx?
                        <div styleName="jbxx_item">
                            <span styleName="item-label">毕业学校：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {jbxxFormData ? jbxxFormData.byzx : ''}
                            </div>
                        </div>:''
                        }
                        {jbxxFormData.gkcj?
                        <div styleName="jbxx_item">
                            <span styleName="item-label">高考分数：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {jbxxFormData ? jbxxFormData.gkcj : ''}
                            </div>
                        </div>:''
                        }
                    </Flex.Item>
                </Flex>
                <div className="p-swc">



                    <div styleName="jbxx_item2">
                        <span styleName="item-label">学院：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {userInfoData ? userInfoData.BMMC : ''}
                        </div>
                    </div>
                    <div styleName="jbxx_item2">
                        <span styleName="item-label">专业：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {userInfoData ? userInfoData.ZYMC : ''}
                        </div>
                    </div>

                    <div styleName="jbxx_item2">
                        <span styleName="item-label">班级：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {userInfoData ? userInfoData.BJMC : ''}
                        </div>
                    </div>

                    <div styleName="jbxx_item2">
                        {jbxx ?
                            <NHFormItem id={'sjhm'}
                                label={<span styleName="item-label">本人联系电话：</span>}
                                colon={false}
                                form={form}
                                required={true}
                                // hasFeedback={true} 
                                // pattern={{ pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/,  message: "请输入正确的手机号码或电话号码"}}
                                pattern={FormRules.MOBILE_PHONE_NUMBER}
                                initialValue={jbxxFormData ? jbxxFormData.sjhm : ""}
                            >
                                <Input style={{ width: 140 }} placeholder="请填写联系电话" />
                            </NHFormItem>
                            : <div>
                                <span styleName="item-label"><span style={{ color: 'red' }}>*</span>本人联系电话：</span>
                                <div
                                    className="t-clamp-1"
                                    styleName="item-name"
                                    style={{ display: 'inline' }}
                                >
                                    {jbxxFormData ? jbxxFormData.sjhm : ''}
                                </div></div>
                        }
                    </div>

                    <div styleName="jbxx_item2">
                        <span styleName="item-label">宿舍信息：</span>
                        <div
                            className="t-clamp-1"
                            styleName="item-name"
                            style={{ display: 'inline' }}
                        >
                            {ssxx}
                        </div>
                    </div>

                    <div styleName="detail_adrress">
                        <span styleName="item-label" style={{ lineHeight: '32px' }}><span style={{ color: 'red' }}>*</span>详细家庭住址：</span>
                        {jbxx ?
                            <Flex>
                                <NHFormItem id={'jtszdqArray'}
                                    label={''}
                                    colon={false}
                                    required={true}
                                    form={form}
                                    initialValue={jbxxFormData ? jbxxFormData.jtszdqArray : []}
                                >
                                    <NHCascader style={{ width: 432.63 }} sign={"zhxg_ssq"} onChange={(value, selectedOptions) => this.onNHCascaderChange(value, selectedOptions, 'jt')}

                                    />

                                </NHFormItem>
                                <NHFormItem id={'jtjddz'}
                                    label={''}
                                    colon={false}
                                    required={true}
                                    form={form}
                                    pattern={{ max: 100, message: "家庭住址长度不能超过100字符！" }}
                                    initialValue={jbxxFormData ? jbxxFormData.jtjddz : ''}>
                                    <Input style={{ width: 346.07 }} placeholder="请填写详细家庭地址，如：xx街道、xx小区、门牌号" />
                                </NHFormItem>
                            </Flex>

                            :
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {jtdzDetail ? jtdzDetail : ''}{jtjddz ? jtjddz : ''}
                            </div>
                        }
                    </div>
                    {userInfoData ? userInfoData.FDY && userInfoData.FDY.length > 0 ?
                        <div>
                            <span styleName="item-label">辅导员：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData.FDY.map((item, key) => {
                                    if(item) {
                                        let lxdh = item.LXDH ? "( " + item.LXDH + " )" : "";
                                        return item.XM + lxdh + " ";
                                    } else {
                                        return "";
                                    }
                                })}
                            </div>
                        </div> : null :
                        null
                    }
                    {userInfoData ? userInfoData.BZR && userInfoData.BZR.length > 0 ?
                        <div>
                            <span styleName="item-label">班主任：</span>
                            <div
                                className="t-clamp-1"
                                styleName="item-name"
                                style={{ display: 'inline' }}
                            >
                                {userInfoData.BZR.map((item, key) => {
                                    if(item) {
                                        let lxdh = item.LXDH ? "( " + item.LXDH + " )" : "";
                                        return item.XM + lxdh + " ";
                                    } else {
                                        return "";
                                    }
                                })}
                            </div>
                        </div> : null :
                        null
                    }
                </div>
                <style jsx="true" global="true">
                 {`
                          .ant-col-md-7 {  
                            width: 100px; 
                           }
                `}
          </style>
            </Form>
        );
    }
}


const JbxxForm = Form.create()(JbxxInitForm);

export default JbxxForm;