import React from 'react'
import {DatePicker, Form, Icon, Input, message, Table} from 'antd'
import moment from 'moment'
import TitleTop from '@pages/Assembly/titleTop'
import {createUuid} from '@utils/NHCore'
import {deepCopy} from '@utils/deepCopy'
import '../index.css';
import NHSelect from "@components/common/NHSelect";
import NHFetch from "@utils/NHFetch";

const FormItem = Form.Item
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD '
class HjxxInitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hjxx: false,
      hjxxAdd: false,
      hjxxData: [],
      showhjxxData: [],
      errorFiled: false,
      flag: false,
      jxdjList: [],   //奖项等级数据源
    }
  }
  componentDidMount() {
    this.setState({
      hjxxData: this.props.hjxxInitData || [],
      showhjxxData: this.props.hjxxInitData || []
    });
    this.queryDmk();
  }

  queryDmk = () =>{
    NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XTGL_HJDJ', 'GET').then(res => {
      if (res && res.data) {
        let jxdjList = [];
        for (let obj of res.data) {
          jxdjList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ jxdjList: jxdjList })
      }
    })
  }

  componentDidUpdate(preProps){
    if(preProps.refresh !== this.props.refresh){
      if(this.props.hjxxInitData ){
        let temp = [...this.props.hjxxInitData]
        this.setState({ hjxxData: temp, showhjxxData:[...temp] })
      }else {
        this.setState({ hjxxData: [],showhjxxData:[]  })
      }
     
    }
  }

  submitCheck =(flag) => {
    const submitCheck = this.props.submitCheck;
    if(typeof submitCheck ==="function"){
        submitCheck(flag);
    }
}

  cacheHjxxData = cb => {
    this.props.form.validateFields((err, values) => {
      const { hjxxData, errorFiled,showhjxxData } = this.state
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let dataList=[];
      let j =0;
      for(let i =0 ; i<hjxxData.length;){
        let orign = hjxxData[i]
        let newItem = showhjxxData[j] || {}
        if(orign.pkid === newItem.pkid){
          dataList.push({
            ...newItem
          })
          i++;
          j++;
        }else {
          // 旧的被删掉了
          dataList.push({
            ...orign,
            del: '1'
          })
          i++
        }
      }
      for(;j<showhjxxData.length;j++){
        let newItem = showhjxxData[j]
        if (!newItem.hjmc && !newItem.jxjb  && !newItem.bjdw && !newItem.hjrq){
          return message.error(`请完善第${j + 1}行信息再保存`)
          
        }
        dataList.push({
          ...newItem,
          del: '0'
        })
      }
      const { cacheFormData } = this.props
      cacheFormData('hjxx', dataList, true,1)
      cb(true)
      this.setState({
        hjxx: false,
        hjxxAdd: false,
        flag: false,
      })
    })
  }


  onhjxxAdd = () => {
    let { hjxxAdd, showhjxxData = [] } = this.state
    if (!hjxxAdd) return message.error('操作逻辑异常!')
    showhjxxData.push({
      key: createUuid(),
      hjmc: '',
      jxjb: '',
      bjdw: '',
      hjrq: '',
      operation: '',
      errorFiled: false,
    })
    this.setState({ showhjxxData })
  }

  

  onhjxxClose = (e, index) => {
    e.preventDefault()
    let { showhjxxData } = this.state
    showhjxxData.splice(index,1)
    this.setState({
      showhjxxData: showhjxxData,
      hjxxAdd: true,
    })
  }

  handleChange = (value, index, key) => {
    if (key === 'hjrq'){
      value = this.buildDate(value);
    }
    let showhjxxData = this.state.showhjxxData
    showhjxxData[index][key] = value;
    this.setState({ showhjxxData })
  }

  buildDate =(value) =>{
    return value ? moment(value).format(dateFormat) : undefined;
  }
  handleDateChange = (value, index) => {
    let showhjxxData = this.state.showhjxxData
    let kssj = value[0] ? moment(value[0]).format(dateFormat) : undefined
    let jssj = value[1] ? moment(value[1]).format(dateFormat) : undefined
    showhjxxData[index]['kssj'] = kssj
    showhjxxData[index]['jssj'] = jssj
    this.setState({ showhjxxData })
  }

  disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf('day')
  }



  render() {
    const {  form,editable,isRequired } = this.props
    const { hjxx, showhjxxData, hjxxAdd,hjxxData,jxdjList } = this.state
    let jxdjTransalate={}; //奖项等级
    for (let i = 0; i < jxdjList.length; i++) {
      jxdjTransalate[jxdjList[i].value] = jxdjList[i].label
    }
    const hjxxColumns = [
      {
        title: '获奖名称',
        dataIndex: 'hjmc',
        render: (text, record, index) => {
          return hjxx ? (
              <Input
                  value={text}
                  onChange={e => {
                    this.handleChange(e.target.value, index, 'hjmc')
                  }}
                  style={{ width: 140 }}
                  placeholder='请填写获奖名称'
              />
          ) : (
              text
          )
        },
      },
      {
        title: '奖项级别',
        dataIndex: 'jxjb',
        render: (text, record, index) => {
          return hjxx ? (
              <NHSelect
                  style={{ width: 100 }}
                  required={true}
                  sign='DMK_XTGL_HJDJ'
                  value={text}
                  dropdownMatchSelectWidth={false}
                  onChange={value => {
                    this.handleChange(value, index, 'jxjb')
                  }}
              />
          ) : (
              jxdjTransalate[text]
          )
        },
      },
      {
          title: '颁奖单位',
          dataIndex: 'bjdw',
          render: (text, record,index) => {
              return hjxx ? (
                  <Input
                      value={text}
                      onChange={(e) =>{this.handleChange(e.target.value,index,'bjdw')}}
                      style={{ width: 100 }}
                      placeholder="请填写颁奖单位"
                  />
              ) : (
                  text
              );
          }
      },
      {
        title: '获奖时间',
        dataIndex: 'hjrq',
        render: (text, record, index) => {
          return hjxx ? (
              <DatePicker
                  value={text ? moment(text, "YYYY-MM-DD") : null}
                  onChange={(value) =>{this.handleChange(value,index,'hjrq')}}
                  placeholder={"选择获奖时间..."}
                  format={"YYYY-MM-DD"}
              />
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (hjxx) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onhjxxClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onhjxxClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]

    if (!hjxx) {
      hjxxColumns.pop()
    }
    return (
      <div>
        <TitleTop
          extra={editable}
          title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
          onEdit={() => {
            this.setState({
              hjxx: true,
              hjxxAdd: true,
              cacheData: deepCopy(hjxxData),
            })
            this.submitCheck(false);
          }}
          onSave={cb => {
            this.cacheHjxxData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              hjxx: false,
              hjxxAdd: false,
              hjxxData: deepCopy(this.state.cacheData),
            })
            this.submitCheck(true);
          }}
        />
        <div className='p-sw'>
          <Table
            bordered
            size='middle'
            pagination={false}
            dataSource={showhjxxData}
            columns={hjxxColumns}
          />
          {hjxxAdd && (
            <div className='mt-10' styleName='btn_add' onClick={this.onhjxxAdd}>
              添加获奖信息
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default Form.create()(HjxxInitForm)