/**
 * @Description: 深拷贝
 * @Version: 1.0
 * @Author: 琴时
 * @Date: 2021-11-22 18:42:08
 * @LastEditors: 琴时
 * @LastEditTime: 2021-12-13 17:42:18
 */

/**
 * @description: 变量类型判断
 * @param {type} [需要判断的类型]
 * @param {value} [需要判断的值]
 * @return {Boolean} [是否该类型]
 * @author: 琴时
 */
 const IsType = (type, value) =>
 Object.prototype.toString.call(value) === `[object ${type}]`

/**
* @description: 深拷贝变量-递归算法(recursive algorithm)
* 支持 String,Number,Boolean,null,undefined,Object,Array,Date,RegExp,Error,Function 类型-进行深度拷贝
* @param {params}  [需要深拷贝的变量]
* @return {*} [靠背后的变量]]
* @author: 琴时
*/
export const deepCopy = params => {
 const newValue = IsType('Object', params) // 判断是否是对象
   ? {}
   : IsType('Array', params) // 判断是否是数组
   ? []
   : IsType('Date', params) // 判断是否是日期对象
   ? new params.constructor(+params)
   : IsType('RegExp', params) || IsType('Error', params) // 判断是否是正则对象或错误对象
   ? new params.constructor(params)
   : params
 // 判断是否是数组或对象
 if (IsType('Object', params) || IsType('Array', params)) {
   // 循环遍历
   for (const key in params) {
     // 防止原型链的值
     Object.prototype.hasOwnProperty.call(params, key) &&
       (newValue[key] = deepCopy(params[key]))
   }
 }
 return newValue
}
