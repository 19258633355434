import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import LoginForm from './loginForm.js';
import LoginStuInfoForm from './loginStuInfo.js';
import {getLoginUser} from "@utils/NHCore";
import Navigation from './navigation';
import './index.css';
import Phone from "./phone";
import LinkPage from "./link";
import Process from "./process";
import Notice from "./notice";

const _boxTop = props => {
    const { img, title, sub, extra } = props;
    return (
        <Flex justify="between" styleName="box_top">
            <Flex>
                <div styleName="box_top_l">
                    <img src={img} alt="" />
                    <span>{title}</span>
                </div>
                <div styleName="line" />
                <div styleName="box_top_r">{sub}</div>
            </Flex>
            <div>{extra}</div>
        </Flex>
    );
};

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount(){
    }





    render() {
        const xh = getLoginUser().userId;
        return (
            <div>
                <Flex className="w1180 mt-20" align="start">
                    <div styleName="grxx">
                        <_boxTop
                            img={require('@images/information.png')}
                            title={xh?"个人信息":"个人登录"}
                            sub="Personal Information"
                        />
                        {xh?<LoginStuInfoForm />:<LoginForm />}
                    </div>

                    {/*通知公告*/}
                    <Notice _boxTop={_boxTop}/>

                </Flex>

                <Process _boxTop={_boxTop}/>

                <Flex styleName="last" className="w1180" align="start">
                    {/*联系方式*/}
                    <Phone _boxTop={_boxTop}/>

                    {/*首页链接*/}
                    <LinkPage _boxTop={_boxTop}/>

                    { /*快速导航*/}
                    <Navigation  _boxTop={_boxTop}/>
                </Flex>
            </div>
        );
    }
}
