import React from 'react';
import './index.css';
import NHFetch from "../../../utils/NHFetch";
export default class Phone extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            lxfs: [], //联系方式
        }
    }

    componentWillMount(){
        this.getLxfs();
    }

    getLxfs = () => {
        NHFetch('api/zhxg-yxwz/lxfs/getLxfsList', 'GET').then(resData => {
            if (resData && resData.data) {
                const lxfs = this.state.lxfs;
                for (let i = 0; i < resData.data.length; i++) {
                    let data = resData.data;
                    lxfs.push(
                        <div key={i}>
                            {data[i]['LXFSMC']}：{data[i]['LXFSHM']}
                        </div>
                    );
                }
                this.setState({ lxfs: lxfs });
            }
        });
    };

    render() {
        const {_boxTop} = this.props;
        return (
            <div styleName="lxdh">
                <_boxTop
                    img={require('@images/contact.png')}
                    title="联系电话"
                    sub="Contact Number"
                />
                <div styleName="lxdh_con">{this.state.lxfs}</div>
            </div>
        );
    }
}