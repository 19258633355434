import {Modal} from 'antd'
import createHashHistory from 'history/createHashHistory'
const hashHistory = createHashHistory();

export const REQUEST_USERINFO = 'REQUEST_USERINFO'
export const RECEIVE_USERINFO = 'RECEIVE_USERINFO'
export const CLEAR_USERINFO = 'CLEAR_USERINFO'

const requestUserInfo = ()=> ({
    type:REQUEST_USERINFO,
    data: null
})

export const clearUserInfo = () => ({
    type:CLEAR_USERINFO,
    user: null
})

export const receiveUserInfo = (user) => ({
    type:RECEIVE_USERINFO,
    user
})

// 退出
// export const userLogout = (dispatch) => {
//     reqLogout().then(res => {
//         let { meta,data }= res.data;
//         if(meta.success){
//             sessionStorage.removeItem("access_user");
//             sessionStorage.removeItem("bpmUrl");
//             sessionStorage.removeItem("menuList");
//             hashHistory.push('/login')
//             dispatch()
//         }else{
//             Modal.error({ title: '退出失败', content: meta.message});
//         }
//     })
// }