import React from 'react';
import { Flex } from '@components';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import  './index.css';
import NHFetch from "../../../utils/NHFetch";
export default class LinkDhlj extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flmid:'',//父栏目id
        }
    }

    componentDidMount(){
        let pkid = this.getQueryString('pkid');
        this.getFlmid(pkid);
    }

    getFlmid=(pkid)=>{
        let params={
            pkid:pkid
        }
        NHFetch("/dhlm/getFlmid","GET",params)
            .then(resData => {
                if(resData && resData.data){
                    if(resData.data==-1){
                        this.setState({flmid:pkid});
                    }else{
                        this.setState({flmid:resData.data});
                    }

                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }


    render() {
        let pkid = this.getQueryString('pkid');
        return (
            <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu title="导航链接" menuType='导航链接' flmid={this.state.flmid} pkid = {pkid}/>
                    <Flex.Item styleName="con_r">
                        <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '导航链接' }
                            ]}
                        />
                        <div>
                        </div>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
