

/**
 * 虚拟表单提交
 * @param {*} url 请求路径
 * @param {*} target 请求方式，_blank:打开新页面，_self:打开本业
 * @param {*} map 请求参数
 */
export const NHVirtualForm = (url,target,map) => {
    var tempForm=document.createElement("form");
		tempForm.method="post";
		tempForm.action=url;
		document.body.appendChild(tempForm);
		//为模拟表单添加提交事件
		if(document.all){  //ie浏览器
			tempForm.fireEvent("onsubmit");
		}else {
			var evt = document.createEvent('HTMLEvents');
			evt.initEvent('onsubmit',true,true);
			tempForm.dispatchEvent(evt);
    }
    //添加参数
    if(map!==undefined && map!==''){
      for(let key in map){
        var hideInput=document.createElement("input");
				hideInput.type="hidden";
				hideInput.name=key;
				hideInput.value=map[key];
				tempForm.appendChild(hideInput);
      }
    }
    //执行虚拟提交操作
    tempForm.target=target?target:"_blank";
    tempForm.submit();
    document.body.removeChild(tempForm); //清除
}
