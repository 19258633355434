import React from 'react';
import './index.css';
import NHFetch from "../../../utils/NHFetch";
import { Flex } from '@components';
import createHashHistory from 'history/createHashHistory';
const hashHistory = createHashHistory();
export default class Notice extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            xxfbfl:'',//信息发布分类
            xxfb: [], //信息发布
        }
    }

    componentWillMount(){
        this.getXxfbnrList();
    }
    click=(pkid)=>{
        hashHistory.push({
            pathname: '/notice/detail',
            search:'pkid='+pkid,
        });
    }

    getXxfbnrList=()=>{
        let param = {flbz:this.state.xxfbfl}
        NHFetch("api/zhxg-yxwz/xxfb/getXxfbnrList","GET",param)
            .then(resData => {
                if(resData && resData.data){
                    let list = resData.data;
                    const xxfb = this.state.xxfb;
                    for(let i=0;i<list.length;i++){
                        let obj = {}
                        obj.title = list[i]['TZBT'];
                        obj.time = list[i]['FBSJ'];
                        obj.pkid = list[i]['PKID'];
                        xxfb.push(obj);
                    }
                    this.setState({xxfb:xxfb});
                }
            })
    }

    render() {
        const {_boxTop} = this.props;
        const settings = {
            dots: true,
            infinite: true,
            speed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div styleName="tzgg">
                <_boxTop
                    img={require('@images/notice.png')}
                    title="通知公告"
                    sub="Notice"
                    extra={
                        <a className="text-desc" onClick={()=>{
                            hashHistory.push({
                                pathname: '/notice',
                                search:'flbz='+this.state.xxfbfl
                            });
                        }}>
                            more>>
                        </a>
                    }
                />
                <div styleName="tzgg_list">
                    {this.state.xxfb.map((elem, index) => {
                        return (
                            <Flex
                                key={index}
                                styleName="tzgg_item"
                                justify="between"
                                onClick={()=>{this.click(elem.pkid) }}
                            >
                                <Flex.Item>
                                    <Flex>
                                        <Flex>
                                            <span className="t-clamp-1">
                                                <span styleName="tzgg_item_icon">
                                                    >
                                                </span> 
                                                {elem.title}
                                            </span>
                                        </Flex>
                                        <Flex.Item styleName="tzgg_line" />
                                    </Flex>
                                </Flex.Item>
                                <div className="ml-10">{elem.time}</div>
                            </Flex>
                        );
                    })}
                </div>
            </div>
        );
    }
}