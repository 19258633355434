import React from 'react';
import { Flex } from '@components';
import createHistory from 'history/createHashHistory';
const history = createHistory();
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import {Button, message, Table, Descriptions, Divider} from 'antd'
import  './index.css';
import NHFetch from "../../../utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";

//请求默认路径
const pathUrl = "api/zhxg-yxxt";

export default class ProvinceInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          provinceInfoList:[]
            // flmid:'',//父栏目id
        }
    }
    // 同省信息页面
    componentDidMount() {
      let userId = getLoginUser() ? getLoginUser().userId : undefined;
      if (userId) {
          this.getProvinceInfo(userId);
      }else{
        message.error('未获取登录信息')
          history.push({
              pathname: '/index',
          });
          
          return false;
      }
    }
  
    //加载信息
    getProvinceInfo = (xsid) => {
      NHFetch('/gdlm/tsxs/getProvinceInfo', 'GET', {xsid: xsid})
        .then(res => {
          if (res && res.data) {
            this.setState({
              provinceInfoList: res.data,
            })
          }
        });
    }
 
    render() {
      const provinceInfoList = this.state.provinceInfoList  
        const columns = [
         
            {title: '姓名',dataIndex: 'XM',key: 'XM',},
            {title: '院系',dataIndex: 'BMMC',key: 'BMMC',},
            {title: '专业',dataIndex: 'ZYMC',key: 'ZYMC',},
            {title: '省份',dataIndex: 'JG',key: 'JG',},
          ];
 
          // const provinceInfoList = [
          //   {
          //     key: '1',
          //     XM: '张伟',
          //     BMMC:'信科院',
          //     ZYMC:'通信工程',
          //     JG:'广东省'
          //   },
          // ];
          return (
            <div>
       
                <Flex className="w1180 mt-20" align="start">
                 
                    <Flex.Item styleName="con_r">
                        <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '同省来源地学生',url:'/provinceInfo' }
                            ]}
                        />
                <Table columns={columns} dataSource={provinceInfoList} />
                    </Flex.Item>
                </Flex>
            </div>
            );
    }
}
