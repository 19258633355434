import React from 'react';
import { Popover } from 'antd';
import './index.css';
import { Carousel } from 'antd';
import NHFetch from "../../../utils/NHFetch";
import { div } from '@components';
export default class Process extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bdlc: [], //办理流程
            size:7, //跑马灯的数量
        }
    }

    componentWillMount() {
        this.getBdlcList();
    }

    getBdlcList = () => {
        NHFetch('api/zhxg-yxwz/bdlc/getBdlcList', 'GET').then(resData => {
            if (resData && resData.data) {
                console.log('res.data',resData.data)
                const bdlc = this.state.bdlc;
                for (let i = 0; i < resData.data.length; i++) {
                    
                    let data = resData.data;
                    let obj = {};
                    obj.id = i;
                    obj.title = data[i]['BZMC'];
                    obj.img = data[i]['BZTP'];
                    obj.sub = data[i]['BZSM'];
                    bdlc.push(obj);
                }
                let size = this.state.size;
                let bdlcnew = this.sliceArr(bdlc, size);
                this.setState({ bdlc: bdlcnew });
            }
        });
    };

    sliceArr = (array, size) => {
        let result = [];
        let length = array.length;
        for (let x = 0; x < Math.ceil(array.length / size); x++) {
            var start = x * size;
            var end = start + size;
            let arr = array.slice(start, end);
            let fill = 0;
            if ((fill = size - arr.length) > 0) {
                for (let i = 0; i < fill; i++) {
                    if( length > size) {
                        arr.push({});
                    }
                    
                }
            }
            result.push(arr);
        }
        return result;
    }

    render() {
        const { _boxTop } = this.props;
        const size=this.state.size
        const settings = {
            dots: true,
            infinite: true,
            speed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div className="w1180 mt-20">
                <_boxTop
                    img={require('@images/process.png')}
                    title="报到流程"
                    sub="Registration Process"
                />
                <Carousel {...settings} autoplay>

                    {this.state.bdlc.map((elem, index) => {
                        console.log(elem);
                        return (
                            <div key={index}>
                                <div styleName="bdlc_con" style={{justifyContent: this.state.bdlc.length === 1 ? 'space-evenly' : 'flex-start'}} align="start">
                                    {elem.map((e, i) => {
                                        let isEmpty = JSON.stringify(e) == "{}";
                                        return (
                                            isEmpty
                                                ?
                                                <div key={index+i}></div>
                                                :
                                                <div key={index+i} styleName="bdlc_item">
                                                    <span styleName="bdlc_item_num">
                                                        {index===0?i+1:index*size+i+1}
                                                    </span>
                                                    <span styleName="bdlc_item_title">
                                                        {e.title}
                                                    </span>
                                                    <div
                                                        className="t-clamp-2"
                                                        styleName="bdlc_item_sub"
                                                    >
                                                        {e.sub && e.sub.length > 16 ? (
                                                            <Popover
                                                                overlayStyle={{ width: 300,wordBreak:'break-all'}}
                                                                content={e.sub}
                                                            >
                                                               {e.sub.substring(0,16)+'...'}
                                                            </Popover>
                                                        ) : (
                                                                e.sub
                                                            )}
                                                    </div>
                                                    <div styleName="bdlc_item_img">
                                                        <img
                                                            src={
                                                                'anonymity/docrepo/download?attachmentId=' +
                                                                e.img
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                        )


                                    })
                                    }
                                </div>
                            </div>
                        );
                    })}

                </Carousel>
            </div>
        );
    }
}