import React from 'react'
import { Table, Input, Icon, Form, message, InputNumber } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import '../../../index.css';
import NHSelect from '@components/common/NHSelect'
import NHFetch from '@utils/NHFetch'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'

const FormItem = Form.Item

class JtcyInitForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            jtcy: false,
            jtcyAdd: false,
            jtcyData: [],
            zzmmmList: [],
            tempStr: '',
            jtcyList: [], //家庭成员数据加载
            jkzkList: [], //健康状况数据加载
            errorFiled: false,
            flag: false,
            showJtcyData: [],
        }
    }

    componentDidMount() {
        NHFetch(
            'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JKZKM',
            'GET'
        ).then(res => {
            if (res && res.data) {
                let jkzkList = []
                for (let obj of res.data) {
                    jkzkList.push({ label: obj.LABEL, value: obj.VALUE })
                }
                this.setState({ jkzkList: jkzkList })
            }
        })
        NHFetch(
            'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JTCYGX',
            'GET'
        ).then(res => {
            if (res && res.data) {
                let jtcyList = []
                for (let obj of res.data) {
                    jtcyList.push({ label: obj.LABEL, value: obj.VALUE })
                }
                this.setState({ jtcyList: jtcyList })
            }
        })
        this.setState({
            jtcyData: this.props.jtcyInitData ? [...this.props.jtcyInitData] : [],
            showJtcyData: this.props.jtcyInitData ? [...this.props.jtcyInitData] : []
        })
    }

    submitCheck =(flag) => {
        const submitCheck = this.props.submitCheck;
        if(typeof submitCheck ==="function"){
            submitCheck(flag);
        }
    }

    // componentWillReceiveProps(nextProps) {
    //   if ('jtcyInitData' in nextProps && nextProps.jtcyInitData) {
    //     if (nextProps.jtcyInitData instanceof Array) {
    //       let temp = [...nextProps.jtcyInitData]
    //       if (!this.state.flag && temp.length > 0)
    //         this.setState({ jtcyData: temp, flag: true,showJtcyData:[...temp] })
    //     }
    //   }
    // }
    componentDidUpdate(preProps) {
        if (preProps.refresh !== this.props.refresh) {

            if (this.props.jtcyInitData && this.props.jtcyInitData.length > 0) {
                let temp = [...this.props.jtcyInitData]
                this.setState({ jtcyData: temp, flag: true, showJtcyData: [...temp] })
            } else {
                this.setState({ jtcyData: [], flag: true, showJtcyData: [] })
            }

        }
    }

    cacheJtcyData = cb => {
        this.props.form.validateFields((err, values) => {
            const { jtcyData, errorFiled, showJtcyData } = this.state
            if (err || errorFiled) return cb(false)
            //判断数据是否全部填写
            let errorPhone = ''
            let errorList = []
            let dataList = []
            let j = 0
            for (let i = 0; i < jtcyData.length;) {
                let orign = jtcyData[i]
                let newItem = showJtcyData[j] || {}
                if (orign.pkid === newItem.pkid) {
                    dataList.push({
                        ...newItem
                    })
                    i++;
                    j++;
                } else {
                    // 旧的被删掉了
                    dataList.push({
                        ...orign,
                        del: '1'
                    })
                    i++
                }
            }
            for (; j < showJtcyData.length; j++) {
                let newItem = showJtcyData[j]
                if (!newItem.gx || !newItem.xm || !newItem.nl || !newItem.zy || !newItem.gzxxdw || !newItem.nsr || !newItem.jkzk) {
                    return message.error(`请完善第${j + 1}行信息再保存`)

                }
                dataList.push({
                    ...newItem,
                    del: '0'
                })
            }


            const { cacheFormData } = this.props
            cacheFormData('hnsf_jtcy', dataList, true, 1)
            cb(true)
            this.setState({
                jtcy: false,
                jtcyAdd: false,
            })
        })
    }

    // 家庭成员 添加
    onjtcyAdd = () => {
        let { jtcyAdd, jtcyData, showJtcyData } = this.state
        if (!jtcyData) {
            jtcyData = []
        }
        if (!jtcyAdd) return message.error('操作逻辑异常!')
        let key = createUuid()
        showJtcyData.push({
            key: key,
            gx: '',
            xm: '',
            nl: '',
            zy: '',
            gzxxdw: '',
            nsr: '',
            jkzk: '',
            operation: '',
            errorFiled: false,
            del: '0',
        })

        this.setState({ jtcyData, showJtcyData })
    }
    // 家庭成员 关闭添加
    onjtcyClose = (e, index) => {
        e.preventDefault()
        let { jtcyData, showJtcyData } = this.state


        showJtcyData.splice(index, 1)
        this.setState({
            jtcyData: jtcyData,
            jtcyAdd: true,
            showJtcyData
        })
    }

    handleChange = (value, index, key) => {
        const errorFiled = key === 'lxfs' && !regPhone(value)
        let { showJtcyData, jtcyData } = this.state
        showJtcyData[index][key] = value
        showJtcyData[index]['errorFiled'] = errorFiled
        this.setState({ jtcyData, errorFiled, showJtcyData })
    }

    getzzmmmStr = value => {
        let temp = ''
        this.state.zzmmmList.forEach(i => {
            if (i.value === value) {
                temp = i.label
            }
        })
        return temp
    }

    render() {
        const { jtcy, jtcyData, jtcyAdd, jtcyList, jkzkList, showJtcyData } = this.state
        const { form, isRequired, editable } = this.props
        let jkzkTransalate = {} //健康状况翻译
        for (let i = 0; i < jkzkList.length; i++) {
            jkzkTransalate[jkzkList[i].value] = jkzkList[i].label
        }
        let jtcyTransalate = {} //家庭成员翻译
        for (let i = 0; i < jtcyList.length; i++) {
            jtcyTransalate[jtcyList[i].value] = jtcyList[i].label
        }
        const jtcyColumns = [
            {
                title: <span><span style={{ color: 'red' }}>*</span> 姓名</span>,
                key: 'xm',
                dataIndex: 'xm',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={e => {
                                this.handleChange(e.target.value, index, 'xm')
                            }}
                            style={{ width: 100 }}
                            placeholder='请填写姓名'
                        />
                    ) : (
                            text
                        )
                },
            }, {
                title: <span><span style={{ color: 'red' }}>*</span> 年龄</span>,
                key: 'nl',
                dataIndex: 'nl',
                render: (text, record, index) => {
                    return jtcy ? (
                        <InputNumber
                            defaultValue={text}
                            onChange={(value) => {
                                this.handleChange(value, index, 'nl')
                            }}
                            precision="0"
                            style={{ width: 70 }}
                            min={1} 
                            max={999}
                            placeholder='请填写年龄'
                        />
                    ) : (
                            text
                        )
                },
            }, {
                title: <span><span style={{ color: 'red' }}>*</span> 与本人关系</span>,
                key: 'gx',
                dataIndex: 'gx',
                render: (text, record, index) => {
                    return jtcy ? (
                        <NHSelect
                            style={{ width: 120 }}
                            required={true}
                            sign='DMK_XSXX_JTCYGX'
                            value={text}
                            onChange={value => {
                                this.handleChange(value, index, 'gx')
                            }}
                        />
                    ) : (
                            jtcyTransalate[text]
                        )
                },
            },
            {
                title: <span><span style={{ color: 'red' }}>*</span> 职业</span>,
                key: 'zy',
                dataIndex: 'zy',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={e => {
                                this.handleChange(e.target.value, index, 'zy')
                            }}
                            style={{ width: 100 }}
                            placeholder='请填写职业'
                        />
                    ) : (
                            text
                        )
                },
            },
            {
                title: <span><span style={{ color: 'red' }}>*</span> 工作（学习）单位</span>,
                key: 'gzxxdw',
                dataIndex: 'gzxxdw',
                render: (text, record, index) => {
                    return jtcy ? (
                        <Input
                            value={text}
                            onChange={e => {
                                this.handleChange(e.target.value, index, 'gzxxdw')
                            }}
                            style={{ width: 140 }}
                            placeholder='请填写工作（学习）单位'
                        />
                    ) : (
                            text
                        )
                },
            },
            {
                title: <span><span style={{ color: 'red' }}>*</span> 年收入（元）</span>,
                key: 'nsr',
                dataIndex: 'nsr',
                render: (text, record, index) => {
                    return jtcy ? (
                        <InputNumber
                            defaultValue={text}
                            onChange={value => {
                                this.handleChange(value, index, 'nsr')
                            }}
                            formatter={value => `${value} 元`}
                            precision="2"
                            style={{ width: 100 }}
                            min={0} 
                            max={999999999}
                            placeholder='请填年收入'
                        />
                    ) : (
                            text
                        )
                },
            },
            {
                title: <span><span style={{ color: 'red' }}>*</span> 健康状况</span>,
                key: 'jkzk',
                dataIndex: 'jkzk',
                render: (text, record, index) => {
                    return jtcy ? (
                        <NHSelect
                            style={{ width: 70 }}
                            required={true}
                            sign='DMK_XSXX_JKZKM'
                            value={text}
                            onChange={value => {
                                this.handleChange(value, index, 'jkzk')
                            }}
                        />
                    ) : (
                            jkzkTransalate[text]
                        )
                },
            },
            {
                title: '删除',
                dataIndex: 'operation',
                width: 60,
                render: (text, record, index) => {
                    if (jtcy) {
                        return (
                            <div className='editable-row-operations'>
                                <a href='#' onClick={e => this.onjtcyClose(e, index)}>
                                    <Icon type='close' style={{ color: 'red' }} />
                                </a>
                            </div>
                        )
                    }
                    return (
                        <a href='#' onClick={e => this.onjtcyClose(e, index)}>
                            <Icon type='delete' style={{ color: 'red' }} />
                        </a>
                    )
                },
            },
        ]
        if (!jtcy) {
            jtcyColumns.pop()
        }
        return (
            <div>
                <TitleTop
                    extra={editable}
                    title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
                    onEdit={() => {
                        this.setState({
                            jtcy: true,
                            jtcyAdd: true,
                            cacheData: deepCopy(jtcyData),
                        })
                        this.submitCheck(false);
                    }}
                    onSave={cb => {
                        this.cacheJtcyData(cb)
                    }}
                    onClose={() => {
                        this.setState({
                            errorFiled: false,
                            jtcy: false,
                            jtcyAdd: false,
                            jtcyData: deepCopy(this.state.cacheData),
                        })
                        this.submitCheck(true);
                    }}
                />

                <Form>
                    <div className='p-sw'>
                        <Table
                            bordered
                            size='middle'
                            pagination={false}
                            dataSource={showJtcyData}
                            columns={jtcyColumns}
                        />
                        {jtcyAdd && (
                            <div
                                className='mt-10'
                                styleName='btn_add'
                                onClick={this.onjtcyAdd}
                            >
                                添加家庭成员
                            </div>
                        )}
                    </div>
                </Form>
            </div>
        )
    }
}

const JtcyForm = Form.create()(JtcyInitForm)
export default JtcyForm
