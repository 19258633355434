// 获取元素距离可视区域顶部、左部的距离
export const offset = (ele) => {
    var top = ele.offsetTop;
    var left = ele.offsetLeft;
    while (ele.offsetParent) {
        ele = ele.offsetParent;
        if (window.navigator.userAgent.indexOf('MSTE 8') > -1) {
            top += ele.offsetTop;
            left += ele.offsetLeft;
        } else {
            top += ele.offsetTop + ele.clientTop;
            left += ele.offsetLeft + ele.clientLeft;
        }
    }
    return {
        left: left,
        top: top
    }
}

export const getSize = () => {
	let windowW,windowH,contentH,contentW,scrollT;
	windowH = window.innerHeight;
	windowW = window.innerWidth;
	scrollT = document.documentElement.scrollTop || document.body.scrollTop;
	contentH = (document.documentElement.scrollHeight > document.body.scrollHeight) ? document.documentElement.scrollHeight : document.body.scrollHeight;
	contentW = (document.documentElement.scrollWidth > document.body.scrollWidth) ? document.documentElement.scrollWidth : document.body.scrollWidth;
	return {windowW,windowH,contentH,contentW,scrollT}
}

// 获取url参数
export const getHashParam  = (name) =>{
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
        queryString = window.location.hash.split('?')[1] || '',
        result = queryString.match(reg);
    return result ? decodeURIComponent(result[2]) : null;
}

// 获取url参数
export const getQueryString =(name)=> {
    return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
}

// 时间格式化
export const dateFormat = (date = new Date(),format = 'yyyy-MM-dd hh:mm:ss') => {
    var o = {
      "M+" : date.getMonth()+1,                 //月份
      "d+" : date.getDate(),                    //日
      "h+" : date.getHours(),                   //小时
      "m+" : date.getMinutes(),                 //分
      "s+" : date.getSeconds(),                 //秒
      "q+" : Math.floor((date.getMonth()+3)/3), //季度
      "S"  : date.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(format)) {
      format=format.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
      if(new RegExp("("+ k +")").test(format)){
        format = format.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
    }
    return format;
}

// 超出截取,...显示
export const maxSlice = (v,maxLength) => {
    return v.length > maxLength ? v.slice(0,maxLength)+'...' : v;
}
  
