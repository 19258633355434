import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import createHistory from 'history/createHashHistory';
const history = createHistory();
import { getLoginUser, createUuid,checkLogin } from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
/*页面*/
import Index from '@pages/index'

export default class IndexRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            dhlm:{},
            nextUrl:'index',
        }
    }

    componentWillMount(){
        this.loadMrlm();
    }

    shouldComponentUpdate(nextProps,nextState){
        let dhlm = nextState.dhlm;
        if(JSON.stringify(this.state.dhlm)=='{}' && JSON.stringify(nextState.dhlm)!='{}'){
            let lmlx = dhlm['lmlx'];
            let flbz = dhlm['flbz'];
            let mkbz = dhlm['mkbz'];
            let flmid = dhlm['flmid'];
            let lmlj = dhlm['lmlj'];
            let pkid = dhlm['pkid'];
            let xsfs=dhlm['xsfs'];
            if(lmlx==3){
               if(xsfs==1){
                   history.push({
                            pathname:mkbz
                        })
               }else{
                let userId = getLoginUser() ? getLoginUser().userId : undefined;
                if (userId) {
                    history.push({
                        pathname:mkbz
                    })
                }else{
                    history.push({
                        pathname: '/index',
                    });
                }
               }
            }else if(lmlx==1){
                history.push({
                        pathname:'notice',
                        search:'flbz='+flbz,
                    }
                );
            }else if(lmlx==2){
                if(flmid==-1){

                }else{
                    history.push({
                            pathname:'link',
                            search:'pkid='+pkid,
                        }
                    );
                }
                window.open(lmlj);
            }
        }
        return true;
    }

    loadMrlm=()=>{

        NHFetch("front/zhxg-unauth/yxwz/getDhlmMrlm","GET")
            .then(resData => {
                if(resData && resData.data){
                    console.log('resData.data',resData.data)
    
                    let dhlm = resData.data || [];
                    this.setState({dhlm:dhlm});
                    // 若存在默认栏目 就跳转到对应的默认栏目界面  --在迎新管理-服务管理-导航管理-默认栏目
                    // console.log('mrlm',dhlm.filter(item=>item.mrlm === '1'))
                    // debugger
                    // let mrlm = dhlm.filter(item=>item.mrlm === '1');
             
                    // console.log('mrlm',dhlm.filter(item=>item.mrlm === '1'))
          
                    if(dhlm.mkbz !== 'index'&&dhlm.mkbz !== null) {
                        if(dhlm.xsfs==1){
                            history.push({
                                     pathname:mkbz
                                 })
                        }else{
                         let userId = getLoginUser() ? getLoginUser().userId : undefined;
                         if (userId) {
                             history.push({
                                 pathname:mkbz
                             })
                         }else{
                             history.push({
                                 pathname: '/index',
                             });
                         }
                        }
                        // history.push({
                        //     pathname: dhlm.mkbz
                        // })
                    }
                }
            });
    }

    render() {
        return (
            <Index/>
        );
    }
}