import React from 'react'
import { DatePicker, Table, Input, Icon, Form, message } from 'antd'
import moment from 'moment'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'
import '../index.css';
const FormItem = Form.Item
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD '
class JyjlInitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jyjl: false,
      jyjlAdd: false,
      jyjlData: [],
      showJyjlData: [],
      errorFiled: false,
      flag: false,
    }
  }
  componentDidMount() {
    this.setState({
      jyjlData: this.props.jyjlInitData || [],
      showJyjlData: this.props.jyjlInitData?[...this.props.jyjlInitData]:[]
    })
  }

  // componentWillReceiveProps(nextProps) {
  //   if ('jyjlInitData' in nextProps && nextProps.jyjlInitData) {
  //     if (nextProps.jyjlInitData instanceof Array) {
  //       let temp = [...nextProps.jyjlInitData]
  //       if (!this.state.flag && temp.length > 0)
  //         this.setState({ jyjlData: temp, flag: true })
  //     }
  //   }
  // }

  componentDidUpdate(preProps){
    if(preProps.refresh !== this.props.refresh){
      console.log('componentDidUpdate', this.props.jyjlInitData)
      if(this.props.jyjlInitData&&this.props.jyjlInitData.length>0 ){
        let temp = [...this.props.jyjlInitData]
        this.setState({ jyjlData: temp,flag: true, showJyjlData:[...temp] })
      }else {
        this.setState({ jyjlData: [],flag: true,showJyjlData:[]  })
      }
     
    }
  }

  
  submitCheck =(flag) => {
    const submitCheck = this.props.submitCheck;
    if(typeof submitCheck ==="function"){
        submitCheck(flag);
    }
}


  cacheJyjlData = cb => {
    this.props.form.validateFields((err, values) => {
      const { jyjlData, errorFiled,showJyjlData } = this.state
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let dataList=[];
      let j =0;
      for(let i =0 ; i<jyjlData.length;){
        let orign = jyjlData[i]
        let newItem = showJyjlData[j] || {}
        if(orign.pkid === newItem.pkid){
          dataList.push({
            ...newItem
          })
          i++;
          j++;
        }else {
          // 旧的被删掉了
          dataList.push({
            ...orign,
            del: '1'
          })
          i++
        }
      }
      for(;j<showJyjlData.length;j++){
        let newItem = showJyjlData[j]
        if (!newItem.xxjl && !newItem.qsny  && !newItem.zmr){
          return message.error(`请完善第${j + 1}行信息再保存`)
          
        }
        dataList.push({
          ...newItem,
          del: '0'
        })
      }
      const { cacheFormData } = this.props
      cacheFormData('jyjl', dataList, true,1)
      cb(true)
      this.setState({
        jyjl: false,
        jyjlAdd: false,
        // flag: false,
      })
    })
  }


  onjyjlAdd = () => {
    let { jyjlAdd, showJyjlData = [],jyjlData} = this.state
    if (!jyjlData) {
      jyjlData = []
    }
    if (!jyjlAdd) return message.error('操作逻辑异常!')
    showJyjlData.push({
      key: createUuid(),
      qsny: '',
      xxjl: '',
      zw: '',
      zmr: '',
      zmrlxfs: '',
      operation: '',
      errorFiled: false,
      del: '0',
    })
    this.setState({jyjlData, showJyjlData })
  }

  

  onjyjlClose = (e, index) => {
    e.preventDefault()
    let { showJyjlData,jyjlData } = this.state
    showJyjlData.splice(index,1)
    this.setState({
      showJyjlData: showJyjlData,
      jyjlAdd: true,
      jyjlData
    })
  }

  handleChange = (value, index, key) => {
    const errorFiled = key === 'zmrlxfs' && !regPhone(value)
    let showJyjlData = this.state.showJyjlData
    showJyjlData[index][key] = value
    showJyjlData[index]['errorFiled'] = errorFiled
    this.setState({ showJyjlData, errorFiled })
  }

  handleDateChange = (value, index) => {
    let showJyjlData = this.state.showJyjlData
    let kssj = value[0] ? moment(value[0]).format(dateFormat) : undefined
    let jssj = value[1] ? moment(value[1]).format(dateFormat) : undefined
    showJyjlData[index]['kssj'] = kssj
    showJyjlData[index]['jssj'] = jssj
    this.setState({ showJyjlData })
  }

  disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf('day')
  }



  render() {
    const {  form,editable,isRequired } = this.props
    const { jyjl, showJyjlData, jyjlAdd,jyjlData } = this.state
    // const { getFieldDecorator } = form
    const jyjlColumns = [
      {
        title: '起止年月',
        dataIndex: 'qsny',
        render: (text, record, index) => {
          let kssj = record.kssj
          let jssj = record.jssj
          return jyjl ? (
            <RangePicker
              // disabledDate={this.disabledDate}
              value={
                kssj && jssj
                  ? [moment(kssj, dateFormat), moment(jssj, dateFormat)]
                  : undefined
              }
              format={dateFormat}
              onChange={value => {
                this.handleDateChange(value, index)
              }}
            />
          ) : (
            (kssj ? kssj : '') + ' ~ ' + (jssj ? jssj : '')
          )
        },
      },
      {
        title: '在何地何学校',
        dataIndex: 'xxjl',
        render: (text, record, index) => {
          return jyjl ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'xxjl')
              }}
              style={{ width: 140 }}
              placeholder='请填写学校'
            />
          ) : (
            text
          )
        },
      },
      {
          title: '任何职',
          dataIndex: 'zw',
          render: (text, record,index) => {
              return jyjl ? (
                  <Input
                      value={text}
                      onChange={(e) =>{this.handleChange(e.target.value,index,'zw')}}
                      style={{ width: 100 }}
                      placeholder="请填写职位"
                  />
              ) : (
                  text
              );
          }
      },
      {
        title: '证明人',
        dataIndex: 'zmr',
        render: (text, record, index) => {
          return jyjl ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'zmr')
              }}
              style={{ width: 110 }}
              placeholder='请填写证明人'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '证明人联系电话',
        dataIndex: 'zmrlxfs',
        render: (text, record, index) => {
          return jyjl ? (
            <FormItem
              form={form}
              style={{ marginBottom: '0px', width: '140px' }}
            >
              <Input
                value={text}
                maxLength={11}
                onChange={e => {
                  this.handleChange(e.target.value, index, 'zmrlxfs')
                }}
                style={{ width: 110 }}
                placeholder='请填写电话'
              />
              <div
                style={{
                  display: record.errorFiled ? 'block' : 'none',
                  color: '#ff4d4f',
                }}
              >
                {text ? FormRules.MOBILE_PHONE_NUMBER.message : '请填写电话'}
              </div>
            </FormItem>
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (jyjl) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onjyjlClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onjyjlClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]

    if (!jyjl) {
      jyjlColumns.pop()
    }
    return (
      <div>
        <TitleTop
          extra={editable}
          title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
          onEdit={() => {
            this.setState({
              jyjl: true,
              jyjlAdd: true,
              cacheData: deepCopy(jyjlData),
            })
            this.submitCheck(false);
          }}
          onSave={cb => {
            this.cacheJyjlData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              jyjl: false,
              jyjlAdd: false,
              jyjlData: deepCopy(this.state.cacheData),
            })
            this.submitCheck(true);
          }}
        />
        <div className='p-sw'>
          <Table
            bordered
            size='middle'
            pagination={false}
            dataSource={showJyjlData}
            columns={jyjlColumns}
          />
          {jyjlAdd && (
            <div className='mt-10' styleName='btn_add' onClick={this.onjyjlAdd}>
              添加教育经历
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default Form.create()(JyjlInitForm)