import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from '@components'
import {
  Button,
  DatePicker,
  Progress,
  Popover,
  Modal,
  Input,
  Icon,
  Select,
  Spin,
  message,
} from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '@utils/NHFetch'
import { getLoginUser } from '@utils/NHCore'
import './index.css'
import JbxxForm from './fixedClass/JbxxForm'
import JtcyForm from './fixedClass/JtcyForm'
import YhkFormGdjtzy from './fixedClass/YhkForm_gdjtzy'
import DkqkFormYnzyydx from './fixedClass/DkqkForm_ynzyydx'
import JtcyFormGdjtzy from './fixedClass/JtcyForm_gdjtzy'
import JtcyFormGxstzy from './fixedClass/JtcyForm_gxstzy'
import HnsfFamilyInfo from './fixedClass/xmd/hnsf/JtcyForm';
import ZyshgxForm from './fixedClass/ZyshgxForm'
import JyjlForm from './fixedClass/JyjlForm'
import KzxxForm from './customClass/KzxxForm'
import SinglePhotoForm from './fixedClass/singlePhotoForm.js'
import Certificate from './fixedClass/certificate'
import AwardsCert from './fixedClass/awardsCert'
import IdentityCardForm from './fixedClass/identityCardForm'
import HjxxFrom from './fixedClass/HjxxForm';
import createHistory from 'history/createHashHistory'
import CommonTip from '../common'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import OneOnMany from './oneOnMany'
import ByglFormYnzyydx from './fixedClass/ByglForm_ynzyydx';

const history = createHistory()
const { RangePicker } = DatePicker
const Option = Select.Option
const stuInfoUrl = '/xxcj/'

export default class UpdateInfoV10 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mkbz: 'GD001_10',
      hjmc: '',
      jbxxFormData: {},
      yhkInitData: {},
      dkqkInitData: [],
      byglInitData:[],
      formInitData: {},
      jbxxInitData: {},
      jtcyInitData: [],
      zyshgxInitData: [],
      jyjlInitData: [],
      hjxxInitData: [],
      kzxxInitData: {},
      txscInitData: [], // 头像上传
      identityPhotoData: [],  //身份证正反面图片
      userBaseInfo: {},
      stuSsxx: {},
      xsid: '',
      jnzsVO: [],
      hjzsVO: [],
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      menuList: [],
      zzbdhjid: '',
      config: [],
      savedInfo: {}, // 已保存的信息
      isSubmiting: false, // 是否正在提交
      submitPopVisible: false,
      refresh: 0,
      resubmitAble: false, //是否可以重新提交
      submitCheck: true
    }
  }

  componentDidMount() {
    this.queryQztjAndYlhj()
    this.getData4Yxwz()
    this.getResubmitAble()
  }

  // 获取流程配置信息
  getConfig = (xsid, isSubmit) => {
    NHFetch(`/zzbd/getXscjPzxx?xsid=${xsid}`, 'GET').then(res => {
      if (res.code !== 200) {
        message.error('获取流程信息失败!')
      }
      const config = res.data.bzlc || []
      this.setState({ config }, () => {
        if (isSubmit) {
          this.handleSubmit()
        }
      })
    })
  }

  submitCheck = (flag) => {
    this.setState({ submitCheck: flag });
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false,
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')

    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', {
      xsid: xsid,
      zzbdhjid: zzbdhjid,
    }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        let operable = !(
          (kfsj && kfsj['YXSJ'] != '1') ||
          (ylhj && ylhj.length > 0) ||
          (qzhj && qzhj.length > 0) ||
          nohj
        )
        this.setState(
          {
            nonOperationalData: res.data,
            zzbdhjid: zzbdhjid,
            operable: operable,
          },
          () => {
            this.getConfig(xsid)
            this.getHjBlZt(xsid)
          }
        )
      }
    })
  }

  getQueryString = name => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.href
        ) || [, ''])[1].replace(/\+/g, '%20')
      ) || null
    )
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID,
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: '',
      })
    }
  }

  //缓存编写的信息，缓存完之后提交 ----
  cacheFormData = (type, values, isAdd, fllx) => {
    if (type == 'jbxx') {
      const { sjhm, jtdz, xxdz } = values
      const { jbxxInitData } = this.state
      if (sjhm !== null && jtdz !== null && jtdz.length > 0 && xxdz !== null) {
        this.setState(
          {
            jbxxInitData: { ...jbxxInitData, ...values },
          },
          () => {
            // values.mzm = [values.mzm]
            // values.zzmmm = [values.zzmmm]
            let saveVal = {
              jtdz: values.jtdz,
              sjhm: values.sjhm,
              xxdz: values.xxdz,
            }
            this.insertData4Yxwz(type, [saveVal], fllx)
          }
        )
      } else {
        message.error('存在必填信息未填写，请填写后再保存！')
      }
    } else if (type == 'jtcy' && isAdd) {
      const { zyshgxInitData } = this.state
      if (zyshgxInitData !== null && zyshgxInitData.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else if (values !== null && values.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else {
        message.error('家庭成员信息和主要社会关系至少要填一项！')
      }
    } else if (type == 'jtcy_2' && isAdd) {
      const { zyshgxInitData } = this.state
      if (zyshgxInitData !== null && zyshgxInitData.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else if (values !== null && values.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else {
        message.error('家庭成员信息和主要社会关系至少要填一项！')
      }
    } else if (type == 'yhk' && isAdd) {
      if (values !== null) {
        this.setState(
          {
            yhkInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else {
        message.error('银行卡信息填报有误！')
      }
    } else if (type == 'zyshgx' && isAdd) {
      const { jtcyInitData } = this.state
      if (jtcyInitData !== null && jtcyInitData.length > 0) {
        this.setState(
          {
            zyshgxInitData: values,
          },
          () => {
            this.insertData4Yxwz()
          }
        )
      } else if (values !== null && values.length > 0) {
        this.setState(
          {
            zyshgxInitData: values,
          },
          () => {
            this.insertData4Yxwz()
          }
        )
      } else {
        message.error('家庭成员信息和主要社会关系至少要填一项！')
      }
    } else if (type == 'jyjl') {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            jyjlInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else {
        message.error('教育经历信息不能为空！')
      }
    } else if (type == 'hjxx') {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            hjxxInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      } else {
        message.error('获奖信息不能为空！')
      }
    } else if (type === 'txsc') {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            txscInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      }
    } else if (type === 'jnzs') {
      const jnzsVO = values.filter(item => item.del !== 1)
      // 解决保存后刷新数据发生抖动的问题
      this.setState({ jnzsVO }, () => {
        this.insertData4Yxwz(type, values, fllx)
      })
    } else if (type === 'hjzs') {
      const hjzsVO = values.filter(item => item.del !== 1)
      // 解决保存后刷新数据发生抖动的问题
      this.setState({ hjzsVO }, () => {
        this.insertData4Yxwz(type, values, fllx)
      })
    } else if (type === 'sfzjzsc') {
      if (values != null && values.length > 0) {
        this.setState({

          identityPhotoData: values
        }, () => { this.insertData4Yxwz(type, values, fllx) })
      }
    } else if (type == 'hnsf_jtcy' && isAdd) {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      }
    } else if (type == 'dkqk') {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            jtcyInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      }
    } else if (fllx === 3) {
      // 一对多
      this.insertData4Yxwz(type, values, fllx)
    }
    // 扩展信息
    else {
      if (values !== null && values.length > 0) {
        this.setState(
          {
            txscInitData: values,
          },
          () => {
            this.insertData4Yxwz(type, values, fllx)
          }
        )
      }
    }
  }

  // 获取各环节信息
  getData4Yxwz = () => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined
    if (userId) {
      this.setState({ xsid: userId })
      NHFetch('/newStudent/queryXsInfo', 'GET', { xsid: userId }).then(res => {
        if (res && res.data) {
          let stuInfo = res.data.stuInfo
          let stuXxcj = res.data.stuXxcj
          let stuSsxx = res.data.stuSsxx
          let editXszp = res.data.editXszp
          let yhkInfo = res.data.yhkInfo
          let dkqkInfo = res.data.dkqkInfo
          let byglInfo = res.data.byglInfo
          console.log('yhkInfo',yhkInfo)
          console.log('dkqkInfo',dkqkInfo)
          this.setState({
            userBaseInfo: stuInfo.userBaseInfo, // 基本信息
            jbxxInitData: stuInfo.xsJbxx4YxwzVO,
            jtcyInitData: stuInfo.jtcyVo,
            yhkInitData: yhkInfo,
            dkqkInitData: dkqkInfo,
            byglInitData:byglInfo,
            zyshgxInitData: stuInfo.zyshgxVO,
            jyjlInitData: stuInfo.jyjlVO,
            hjxxInitData: stuInfo.hjqkVO,
            kzxxInitData: { ...stuXxcj },
            stuSsxx: stuSsxx, // 基本信息
            editXszp: editXszp, // 基本信息
            jnzsVO: stuInfo.jnzsVO || [], //技能证书
            hjzsVO: stuInfo.hjzsVO || [], //获奖证书
            refresh: this.state.refresh === 1 ? 0 : 1
          })
        }
      })
    } else {
      message.error('登录超时,请重新登录！')
    }
  }

  // 提交
  insertData4Yxwz = (type, values, fllx) => {
    let postData = {
      [type]: values,
    }
    const userId = getLoginUser().userId
    NHFetch(
      `/zzbd/createOrUpdateXscjXx?xsid=${userId}&fllx=${fllx}`,
      'POST',
      postData
    ).then(res => {
      if (res) {
        if (res.code == 200) {
          message.success('保存成功')
          // 更新环节状态
          this.getData4Yxwz()
          this.queryQztjAndYlhj()
          this.reloadMenu()
          if (type === 'txsc') {
            // 要更新个人信息的头像
            this.refJbxxForm.getStudentPhotoBase64()
          } else if (type === 'jbxx') {
            // 更新
            this.refTxscForm.getStudentPhotoBase64()
          } else if (type === 'sfzjzsc') {
            this.refIdentityCardForm.getStudentPhotoBase64('70');
            this.refIdentityCardForm.getStudentPhotoBase64('71');
          }
          this.setState({ submitCheck: true })
        } else {
          message.error(res.message)
        }
      } else {
        message.error('保存失败')
      }
    })
  }

  // 获取自助报到环节办理状态
  getHjBlZt = xsid => {
    NHFetch(`/zzbdCommon/getBlztByHjdm`, 'GET', {
      xsid,
      hjdm: this.state.mkbz,
    }).then(res => {
      if (res && res.code !== 200) return
      this.setState({ editable: res.data !== '1' })
    })
  }

  //重新加载菜单
  reloadMenu = () => {
    console.log('reload')
    this.refs.siderMenu.reloadMenuList()
  }

  // 获取配置（包含各环节是否已填）后提交
  handleSubmit = () => {
    const { config, zzbdhjid } = this.state
    const temp = config.find(item => item.FLSFBT === '1' && item.SFYT === '0')
    if (temp) {
      this.setState({
        isSubmiting: false,
      })
      message.error(`请填写${temp.FLMC || temp.FLDM}分类模块!`)
      return
    }
    const userId = getLoginUser().userId
    NHFetch(`/zzbd/updateXscjXxZt?xsid=${userId}&zzbdhjid=${zzbdhjid}`, 'POST')
      .then(res => {
        if (res.code === 200) {
          message.success('提交成功')
          this.getConfig(userId)
          this.getHjBlZt(userId)
          this.reloadMenu()
          this.setState({
            isSubmiting: false,
          })
        } else {
          this.setState({
            isSubmiting: false,
          })
        }
      })
      .catch(err => {
        message.error('提交失败')
        this.setState({
          isSubmiting: false,
        })
      })
  }
  // 先获取配置
  onSubmit = () => {
    this.setState({
      isSubmiting: true,
      submitPopVisible: false,
    })
    const userId = getLoginUser().userId
    this.getConfig(userId, true)
  }

  showSubmitPop = () => {
    this.setState({
      submitPopVisible: true,
    })
  }

  hideSubmitPop = () => {
    this.setState({
      submitPopVisible: false,
    })
  }

  showConfirm = () => {
    const { confirm } = Modal
    const submitCheck = this.state.submitCheck;
    if (submitCheck) {
      confirm({
        title: '确定提交？',
        //   content: 'When clicked the OK button, this dialog will be closed after 1 second',
        onOk: () => {
          this.onSubmit()
        },
        onCancel() { },
      })
    } else {
      message.info("存在未保存的分类!");
    }
  }

  // 获取配置：是否可以重新提交
  getResubmitAble = () => {
    NHFetch('/zzbdCommon/getCssz?csbz=YXWZ_ZZBD_GRXX_SFYXCXTJ', 'GET').then((res) => {
      if (res && res.code === 200) {
        this.setState({ resubmitAble: res.data === 'true' })
      } else {
        message.error('获取系统参数失败')
      }
    })
  }

  // 取消办理
  cancel = () => {
    const { confirm } = Modal
    confirm({
      title: '确定取消？',
      onOk: () => {
        const { xsid, zzbdhjid } = this.state
        const userId = getLoginUser().userId
        this.setState({
          isSubmiting: true,
        })
        NHFetch(`/zzbd/cancelXscjxxZt?xsid=${xsid}&zzbdhjid=${zzbdhjid}`, 'POST').then((res) => {
          if (res && res.code === 200) {
            message.success('取消办理成功')
            this.getConfig(userId)
            this.getHjBlZt(userId)
            this.reloadMenu()
          } else {
            message.error('取消办理失败')
          }
          this.setState({
            isSubmiting: false,
          })
        })
      }
    })
  }

  render() {
    const { config, editable, isSubmiting, refresh, resubmitAble } = this.state
    const componentList = []
    // 排序
    config.sort((a, b) => {
      return a.FLPXH - b.FLPXH
    })
    config.forEach(item => {
      if (item.FLLX === '1') {
        // 固定分类
        switch (item.FLDM) {
          case 'jbxx': //基本信息
            componentList.push(
              <JbxxForm
                setRef={ref => {
                  this.refJbxxForm = ref
                }}
                jbxxFormData={this.state.jbxxInitData}
                cacheFormData={this.cacheFormData}
                userInfoData={this.state.userBaseInfo}
                stuSsxx={this.state.stuSsxx}
                editXszp={this.state.editXszp}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'jtcy':
            componentList.push(
              <JtcyForm
                ref='refJtcyForm'
                jtcyInitData={this.state.jtcyInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'jtcy_2':
            componentList.push(
              <JtcyFormGdjtzy
                ref='refJtcyForm'
                jtcyInitData={this.state.jtcyInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'yhk':
            componentList.push(
              <YhkFormGdjtzy
                ref='refYhkForm'
                yhkInitData={this.state.yhkInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          // 广西生态职院
          case 'jtcy_gxstzy':
            componentList.push(
              <JtcyFormGxstzy
                ref='refJtcyForm'
                jtcyInitData={this.state.jtcyInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'jyjl':
            componentList.push(
              <JyjlForm
                ref='refJyjlForm'
                jyjlInitData={this.state.jyjlInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'txsc':
            componentList.push(
              <SinglePhotoForm
                setRef={ref => {
                  this.refTxscForm = ref
                }}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                editXszp={this.state.editXszp}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'jnzs': //技能证书
            componentList.push(
              <Certificate
                ref='certForm'
                cacheFormData={this.cacheFormData}
                editable={editable}
                isRequired={item.FLSFBT === '1'}
                initData={item}
                initFormData={this.state.jnzsVO}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'hjzs': //获奖证书
            componentList.push(
              <AwardsCert
                ref='awardstForm'
                cacheFormData={this.cacheFormData}
                editable={editable}
                isRequired={item.FLSFBT === '1'}
                initData={item}
                initFormData={this.state.hjzsVO}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break;
          case 'sfzjzsc': //身份证照上传
            componentList.push(
              <IdentityCardForm
                setRef={ref => { this.refIdentityCardForm = ref }}
                cacheFormData={this.cacheFormData}
                editable={editable}
                isRequired={item.FLSFBT === '1'}
                initData={item}
                initFormData={this.state.hjzsVO}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'hjxx':
            componentList.push(
              <HjxxFrom
                ref='refHjxxForm'
                hjxxInitData={this.state.hjxxInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'hnsf_jtcy':
            componentList.push(
              <HnsfFamilyInfo
                ref='refJtcyForm'
                jtcyInitData={this.state.jtcyInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'dkqk':
            componentList.push(
              <DkqkFormYnzyydx
                ref='refDkqkForm'
                dkqkInitData={this.state.dkqkInitData}
                cacheFormData={this.cacheFormData}
                editable={editable} // 如果已提交，就不可编辑
                isRequired={item.FLSFBT === '1'}
                initData={item}
                refresh={refresh}
                submitCheck={this.submitCheck}
              />
            )
            break
          case 'bygl_ynzyy':
            componentList.push(
                <ByglFormYnzyydx
                    ref='refByglForm'
                    byglInitData={this.state.byglInitData}
                    cacheFormData={this.cacheFormData}
                    editable={editable} // 如果已提交，就不可编辑
                    isRequired={item.FLSFBT === '1'}
                    initData={item}
                    refresh={refresh}
                    submitCheck={this.submitCheck}
                />
            )
            break
          default:
            // 给还没编写的界面占位
            componentList.push(null)
            break
        }
      } else if (item.FLLX === '2') {
        // 自定义分类
        console.log('自定义分类', item)
        componentList.push(
          <KzxxForm
            ref='refKzxxForm'
            reloadMenu={this.reloadMenu}
            xsid={this.state.xsid}
            zzbdhjid={this.state.zzbdhjid}
            initData={item}
            editable={editable} // 如果已提交，就不可编辑
            isRequired={item.FLSFBT === '1'}
            cacheFormData={this.cacheFormData}
            refresh={refresh}
            submitCheck={this.submitCheck}
          />
        )
      } else if (item.FLLX === '3') {
        componentList.push(
          <OneOnMany
            reloadMenu={this.reloadMenu}
            xsid={this.state.xsid}
            zzbdhjid={this.state.zzbdhjid}
            initData={item}
            editable={editable} // 如果已提交，就不可编辑
            isRequired={item.FLSFBT === '1'}
            cacheFormData={this.cacheFormData}
            refresh={refresh}
            submitCheck={this.submitCheck}
          />
        )
      }
    })
    return (
      <div>
        {this.state.operable ? (
          <Flex className='w1180 mt-20 ' styleName='updateInfo' align='start' style={{ width: '1400px' }}>
            <SiderMenu
              ref={'siderMenu'}
              setMenuList={this.setMenuList}
              title='自助报到'
              /*   active={0}*/
              mkbz={this.state.mkbz}
              menuType='自助报到'
            />
            <Spin spinning={isSubmiting} wrapperClassName='updateInfoSpin'>
              <Flex.Item className='page_con_r'>
                <Breadcrumb
                  noLine
                  list={[
                    { name: '首页', url: '/index' },
                    { name: '自助报到', url: '/register' },
                    { name: this.state.hjmc },
                  ]}
                />
                <div className='box'>
                  <Hint />
                  {/* 流程 */}
                  {/* {componentList} */}
                  {componentList && componentList.length != 0 ? componentList
                    : <span style={{ margin: 20 }}>暂未配置个人信息采集环节参数</span>}

                  <div className='p-sw text-center mt-30'>
                    {/* {editable && (
                                    <Popover
                                        content={<div styleName="submitPop"><div style={{marginBottom: '10px'}}><Icon style={{color:'rgb(241, 179, 26)',marginRight:'10px'}} type="info-circle" />确认提交？</div><Button style={{marginRight: '10px'}} onClick={this.hideSubmitPop}>否</Button><Button onClick={this.onSubmit} type="primary">是</Button></div>}
                                        trigger='click'
                                        visible={this.state.submitPopVisible}
                                    >
                                         <Button type="primary" onClick={this.showSubmitPop}>提交</Button>
                                    </Popover>
                                )} */}
                    {editable && (componentList && componentList.length != 0) && (
                      <Button type='primary' onClick={this.showConfirm}>
                        提交
                      </Button>
                    )}

                    {
                      resubmitAble && !editable && (
                        <Button type='primary' onClick={this.cancel}>
                          取消提交
                        </Button>
                      )
                    }
                    <NextButton
                      nextUrl={this.state.nextUrl}
                      search={this.state.search}
                    />
                  </div>
                </div>
              </Flex.Item>
            </Spin>
          </Flex>
        ) : (
          <CommonTip
            hjmc={'个人信息填写'}
            key={'GD001_10'}
            mkbz='GD001_10'
            nonOperationalData={this.state.nonOperationalData}
          />
        )}
      </div>
    )
  }
}
