import React from 'react';
import { Form, Icon, Input, Button } from 'antd';
import { userLogin } from '../../actions/login.js';
import NHFetch from "../../../utils/NHFetch";
import NHSelect from '../../components/common/NHSelect/index';
import {Modal,message } from 'antd';
const FormItem = Form.Item;

const URL_PREFIX = ''; //统一前缀    
const reqGetCaptcha = `${URL_PREFIX}captchaServlet?v=`;//验证码下载路径  

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mmts: '',//密码提示
            mrzhlx: '', //默认帐号类型
            validateUrl: '',
            captchaVisible: false,//验证码是否可见
        }
    }

    componentDidMount() {
        this.getLoginTip();
        this.getDefaultAccountType();
        this.getSetting();//获取配置，是否开启验证码
    }

    getLoginTip = () => {
        NHFetch('api/zhxg-yxwz/yxwz/login/getLoginTip', 'GET').then(resData => {
            if (resData && resData.data) {
                this.setState({ mmts: resData.data });
            }
        });
    };

    /**
     * * 获取迎新默认帐号类型
     * @memberof LoginForm
     */
    getDefaultAccountType = () => {
        NHFetch('api/zhxg-yxwz/yxwz/login/getDefaultAccountType', 'GET').then(resData => {
            if (resData && resData.data) {
                this.setState({ mrzhlx: resData.data });
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            if (values.typeNum && values.username && values.password) {
                values.vaildationService = 'ly-zhxg-yxwz-svc';
                let params = values;
                params.username = params.username.trim();
                params.password = params.password.trim();
                //验证码开启
                if (this.state.captchaVisible) {
                  params = {
                      ...params,
                      captcha: values.captcha,
                      systemId: 'ly-zhxg'
                  }
              }
              userLogin(params,() => { this.getSetting() });
            }
        });
    };
      //获取配置，是否开启验证码
      getSetting() {
        fetch(`${URL_PREFIX}ng/captcha/status`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": "XMLHttpRequest",
                "appId": "ly-zhxg"
            }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.meta && res.meta.statusCode === 200) {
                    this.setState({
                        captchaVisible: res.data,
                    }, () => {
                        if (res.data) {
                            this.changeCaptcha();
                        }
                    })
                } else {
                    this.setState({
                        captchaVisible: false,
                    })
                }
                return res;
            })
            .catch((error) => {
                // 网络请求失败返回执行该回调函数，得到错误信息
                //Modal.error({ title: '错误提示', content: '网络请求异常,请联系管理员' });
                return error;
            })
      }

    changeCaptcha() {
    this.setState({
        validateUrl: reqGetCaptcha + (new Date().getMilliseconds()),
    })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { mrzhlx } = this.state;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <div className="text-sub font-12 justify">
                    {this.state.mmts}
                </div>
                <FormItem>
                    {getFieldDecorator('typeNum', {
                        rules: [
                            {
                                required: true
                            }
                        ],
                        initialValue: mrzhlx
                    })(
                        <NHSelect sign={'dmk_yxxt_zhlx'} style={{ width: '100%' }} />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('username', {
                        rules: [
                            {
                                required: true
                            }
                        ]
                    })(
                        <Input
                            style={{ width: '100%' }}
                            prefix={
                                <Icon
                                    type="user"
                                    style={{ color: '#a5c7d7', fontSize: 18 }}
                                />
                            }
                            placeholder="请输入账号"
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true
                            }
                        ]
                    })(
                        <Input.Password
                            style={{ width: '100%' }}
                            prefix={
                                <Icon
                                    type="lock"
                                    style={{ color: '#a5c7d7', fontSize: 18 }}
                                />
                            }
                            type="password"
                            placeholder="请输入密码"
                        />
                    )}
                </FormItem>
                {
                    this.state.captchaVisible ?
                        <FormItem>
                            {getFieldDecorator('captcha', {
                                rules: [{ required: true, message: '请输入验证码！' }],
                            })(
                                <div>
                                    <Input size="large" type="text" placeholder="请输入验证码" style={{ width: '58%', float: 'left', marginRight: '10px' }} />
                                    <div>
                                        <span onClick={() => this.changeCaptcha()}>
                                            <img src={this.state.validateUrl} />
                                        </span>
                                    </div>
                                </div>
                                )}
                        </FormItem>
                        : null
                }
                <FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                    >
                        登录
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(LoginForm);
