// import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router,browserHistory,hashHistory } from 'react-router'
import { LocaleProvider } from 'antd';
import 'lodash'
import store from './redux/store/configureStore'
import GlobalRoute from './route/globalRoute'
import "../assets/css/global.css"
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/lib/locale-provider/zh_CN';
import {tryLoginUserInfo} from './app/actions/login';
import {browserJudge} from './utils/NHCore';

//解决移动端300毫秒延迟
var FastClick = require('fastclick');
FastClick.attach(document.body);

if (process.env.NODE_ENV !== 'production' && process.env.GATEWAY_VERSION) {
    window.gateway_version = process.env.GATEWAY_VERSION;
}

const render = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <LocaleProvider locale={zhCN}>
                <Component />
            </LocaleProvider>
        </Provider>,
        document.getElementById('root')
    );
}
//首先判断浏览器内核版本，如果内核版本是IE，直接不让用户访问
browserJudge(()=>{ 
    //先获取登录信息
    tryLoginUserInfo(() => {
        // 限制教师登录
        setTimeout(()=>{
            const userLogin = sessionStorage.getItem('userLogin')
            if(userLogin && JSON.parse(userLogin).userType === 'teacher') {
                sessionStorage.removeItem('userLogin')
            }
            render(GlobalRoute)
            if(module.hot) {
                module.hot.accept('./route/globalRoute', () => { render(GlobalRoute) });
            }
        })
    })
});


