import React from 'react';
import loadable from 'react-loadable'

/**
 * @author qianzhiwei
 * @date 2020/09/25
 */
function asyncImport( loader ){
    function Loading(props) {
        if (props.error) 
            return <div>Error!</div>;
        else if (props.pastDelay) 
            return <div>加载页面中!</div>;
        else 
            return null;
    }

    return loadable({
        loader,
        loading: Loading,
    })
}

export {
    asyncImport
}