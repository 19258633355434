import React from 'react';
import './index.css';
import { Select} from 'antd';
import NHFetch from "../../../utils/NHFetch";
import createHashHistory from 'history/createHashHistory';
const hashHistory = createHashHistory();
export default class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            ksdh: {
                //快速导航
            },
        }
    }
    //快速导航链接跳转
    handleChange = (url,ljlx,flbz)=> {
        if(ljlx=='1'){
            hashHistory.push({
                pathname: '/notice',
                search:'flbz='+flbz,
            });
        }else{
            window.open(url);
        }
    };

    componentWillMount(){
        this.getLjlx();
    }

    getLjlx = () => {
        NHFetch('api/zhxg-yxwz/proData/selectDataList', 'GET', {
            sign: 'DMK_YXXT_LJLX'
        }).then(resData => {
            if (resData && resData.data) {
                this.setState({
                     ljlxData: resData.data
                }, () => {
                    this.getKsdh();
                });
            }
        });
    }

    getKsdh = () => {
        NHFetch('api/zhxg-yxwz/proData/selectDataList', 'GET', {
            sign: 'zhxg_yxwz_ksdh'
        }).then(resData => {
            if (resData && resData.data) {
                const ksdh = this.state.ksdh;
                for (let { LJLX, LABEL, VALUE } of resData.data) {
                    this.state.ljlxData.map((item,index) => {
                        if(index < 4) {
                            if(LJLX == item.VALUE) {
                                ksdh[item.VALUE] = ksdh[item.VALUE] ? ksdh[item.VALUE] : [];
                                ksdh[item.VALUE].push(
                                    <Option key={VALUE} value={VALUE}>
                                        {LABEL}
                                    </Option>
                                );
                            }
                        }
                    })
                }
                this.setState({ ksdh: ksdh });
            }
        });
    };

    //快速导航链接跳转
    handleChange = (url,ljlx,flbz)=> {
        if(ljlx=='1'){
            hashHistory.push({
                pathname: '/notice',
                search:'flbz='+flbz,
            });
        }else{
            window.open(url);
        }
    };

    render() {
        const {_boxTop} = this.props;
        const { ljlxData } = this.state;
        return (
            <div styleName="ksdh">
                <_boxTop
                    img={require('@images/rapid.png')}
                    title="快速导航"
                    sub="Rapid Navigation"
                />
                <div styleName="ksdh_con">
                    {
                        ljlxData ? 
                            ljlxData.map((item,index) => {
                                if(index < 4) {
                                    return (
                                        <Select
                                            showSearch
                                            styleName="ksdh_select"
                                            placeholder={`----------${item.LABEL}----------`}
                                            optionFilterProp="children"
                                            onChange={this.handleChange}
                                            value={[]}
                                        >
                                            {this.state.ksdh[item.VALUE]}
                                        </Select>
                                    )
                                }
                            })
                            :null
                    }
                </div>
            </div>
        );
    }
}