import React from 'react'
import { Table, Input, Button, Modal, Upload, Icon, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import NHFetch from '@utils/NHFetch'
import '../index.css'
import default_img from '../../../../../../assets/images/cert.png'
import { deepCopy } from '@utils/deepCopy'
/**
 * 获奖证书
 */
export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      previewVisible: false,
      judgeImgList: [
        '.png',
        '.PNG',
        '.jpg',
        '.JPG',
        '.bpm',
        '.BPM',
        '.jpeg',
        '.JPEG',
      ], //可以导入的图片格式
      editFlag: false,
      dataSource: [],
      delData: [],
    }
  }

  componentDidMount() {
    const { initFormData } = this.props
    this.getData(initFormData, false)
  }
  componentWillReceiveProps(nextProps) {
    if ('initFormData' in nextProps) {
      if (nextProps.initFormData instanceof Array) {
        this.getData(nextProps.initFormData, false)
      }
    }
  }

  submitCheck =(flag) => {
    const submitCheck = this.props.submitCheck;
    if(typeof submitCheck ==="function"){
        submitCheck(flag);
    }
}

  getData = (data, flag) => {
    const initFormData = deepCopy(data)
    const dataSource = initFormData.map(item => {
      item.fjidList = item.fjidList || []
      if (item.fjidList.length > 0) {
        item.fjidList[0].uid = item.fjidList[0].fjid
        item.fjidList[0].name = item.fjidList[0].fjmc
        item.fjidList[0].url = `anonymity/docrepo/download/file?attachmentId=${item.fjidList[0].fjid}`
      }
      return item
    })
    this.setState({ dataSource, editFlag: flag })
  }
  // 提交
  handleSubmit = cb => {
    const dataSource = [...this.state.dataSource, ...this.state.delData]
    if (this.state.dataSource.length === 0) {
      message.warning('获奖证书不能为空!')
      cb(false)
      return
    }
    const flag = dataSource.some((item, index) => {
      let { fjidList, fzjg, zsbh, zsmc, del } = item
      if (fjidList.length === 0 && !fzjg && !zsbh && !zsmc && del !== '1') {
        message.warning(`第${index + 1}行信息不能全为空!`)
        return true
      }
      if (fjidList.length > 0) {
        let { fjid, fjmc } = fjidList[0]
        item.fjidList = [{ fjid, fjmc }]
      }
    })
    if (flag) return cb(false)
    const { cacheFormData } = this.props
    cacheFormData('hjzs', dataSource, true, 1)
    cb(true)
    this.setState({ editFlag: false })
  }

  // 转化base64
  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  // 点击文件链接或预览图标时的回调
  handlePreview = file => {
    const isImage = this.setJudge(file.name, this.state.judgeImgList)
    if (isImage) {
      if (file.url || file.thumbUrl) {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
        })
      } else if (file.response && file.response.data) {
        let ssbh = file.response.data.ssbh
        this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`)
      } else {
        this.getBase64(file, imageUrl => {
          this.setState({
            previewImage: imageUrl,
            previewVisible: true,
          })
        })
      }
    } else {
      message.error('不支持预览非图片格式附件')
    }
  }

  showPreview = url => {
    this.setState({
      previewImage: url,
      previewVisible: true,
    })
  }

  // 关闭图片弹窗
  handleCancel = () => this.setState({ previewVisible: false })

  //判断文件类型是否正常  name：文件名称  list：类型范围数组  true正常
  setJudge = (name, list) => {
    return list.some(item => name.indexOf(item) !== -1)
  }

  beforeUpload = (file, index) => {
    const { dataSource } = this.state
    let fileSize = file.size / 1024 / 1024
    if (dataSource[index].fjidList.length > 0) {
      return false
    } else if (!this.setJudge(file.name, this.state.judgeImgList)) {
      message.error('只能上传图片格式附件')
      return false
    } else if (fileSize > 1) {
      let msg = `抱歉，上传文件大小最大不能超过1M!`
      message.error(msg)
      return false
    } else {
      dataSource[index].fjidList = [file]
      this.setState({ dataSource })
      return true
    }
  }

  handleChange = ({ fileList }, index) => {
    let file = fileList[0]
    const { dataSource } = this.state
    if ((file.status === 'done', file.response && file.response.data)) {
      let ssbh = file.response.data.ssbh
      file.url = `anonymity/docrepo/download/file?attachmentId=${ssbh}`
      file.fjid = ssbh
      file.fjmc = file.name
      dataSource[index].fjidList = [file]
      this.setState({ dataSource })
    }
  }

  handleRemove = (file, index) => {
    const { dataSource } = this.state
    dataSource[index].fjidList = []
    this.setState({ dataSource }, () => {
      if (file.fjid) this.remove(file.fjid)
    })
  }

  remove = ssbh => {
    let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`
    NHFetch(url, 'POST').then(res => {
      if (res.data && res.data > 0) {
        message.success('已删除附件.')
      }
    })
  }

  handleAdd = () => {
    const { dataSource } = this.state
    dataSource.push({
      pkid: createUuid(),
      zsmc: '',
      fzjg: '',
      fjidList: [],
      isAdd: true, //新增行标识
    })
    this.setState({ dataSource })
  }

  inputChange = (value, index, key) => {
    const { dataSource } = this.state
    dataSource[index][key] = value
    this.setState({ dataSource })
  }

  handleDelete = (e, index) => {
    e.preventDefault()
    let { dataSource, delData } = this.state
    if (!dataSource[index].isAdd) {
      dataSource[index].del = '1'
      delData.push(dataSource[index])
    }
    dataSource.splice(index, 1)
    this.setState({ dataSource })
  }

  render() {
    const { editable, isRequired, initData, initFormData } = this.props
    const { dataSource, editFlag, previewImage, previewVisible } = this.state
    const columns = [
      {
        title: '证书名称',
        dataIndex: 'zsmc',
        render: (text, record, index) => {
          return editFlag ? (
            <Input
              value={text}
              onChange={e => {
                this.inputChange(e.target.value, index, 'zsmc')
              }}
              style={{ width: 140 }}
              placeholder='证书名称'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '发证机关',
        dataIndex: 'fzjg',
        render: (text, record, index) => {
          return editFlag ? (
            <Input
              value={text}
              onChange={e => {
                this.inputChange(e.target.value, index, 'fzjg')
              }}
              style={{ width: 140 }}
              placeholder='发证机关'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '附件',
        dataIndex: 'fjidList',
        render: (text, record, index) => {
          const props = {
            name: 'file',
            action: 'zuul/docrepo/upload',
            data: { uuid: createUuid(), type: '1' },
            // onRemove: file => {
            //   this.handleRemove(file, index)
            // },
            beforeUpload: file => {
              this.beforeUpload(file, index)
            },
            onChange: file => {
              this.handleChange(file, index)
            },
            fileList: text,
          }
          let url = default_img
          let file = text[0]
          if (file) url = file.url || file.thumbUrl || default_img
          return editFlag ? (
            <div className='upload_box'>
              <div className={file ? 'imageBox' : 'defBox'}>
                <img
                  className='image'
                  src={url}
                  alt='附件'
                  onClick={() => {
                    this.showPreview(url)
                  }}
                />
                {file && (
                  <span className='icon-li'>
                    <i
                      aria-label='图标: eye-o'
                      className='icon icon-eye-o'
                      onClick={() => {
                        this.showPreview(url)
                      }}
                    >
                      <svg
                        viewBox='64 64 896 896'
                        focusable='false'
                        data-icon='eye'
                        width='1em'
                        height='1em'
                        fill='currentColor'
                        aria-hidden='true'
                      >
                        <path d='M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z'></path>
                      </svg>
                    </i>
                    <i
                      aria-label='图标: delete'
                      title='删除文件'
                      className='icon icon-delete'
                      onClick={() => {
                        this.handleRemove(file, index)
                      }}
                    >
                      <svg
                        viewBox='64 64 896 896'
                        focusable='false'
                        data-icon='delete'
                        width='1em'
                        height='1em'
                        fill='currentColor'
                        aria-hidden='true'
                      >
                        <path d='M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z'></path>
                      </svg>
                    </i>
                  </span>
                )}
              </div>

              <Upload {...props} className='upload-btn'>
                <Button>
                  <Icon type='upload' /> 点击上传
                </Button>
              </Upload>
            </div>
          ) : (
            <img
              className='image'
              src={url}
              alt='附件'
              onClick={() => {
                this.showPreview(url)
              }}
            />
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          return (
            <a href='#' onClick={e => this.handleDelete(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]
    if (!editFlag) columns.pop()
    return (
      <div style={{ position: 'relative' }} id='fixed-award'>
        <TitleTop
          extra={editable}
          title={
            <span>
              {isRequired && <span style={{ color: 'red' }}>*</span>}
              {initData.FLMC}
            </span>
          }
          onEdit={() => {
            this.getData(initFormData, true)
            this.submitCheck(false);
          }}
          onSave={cb => {
            this.handleSubmit(cb)
          }}
          onClose={() => {
            this.getData(initFormData, false)
            this.submitCheck(true);
          }}
        />
        <div className='p-sw'>
          <Table
            bordered
            size='middle'
            pagination={false}
            dataSource={dataSource}
            columns={columns}
          />
          {editFlag && (
            <div className='mt-10' styleName='btn_add' onClick={this.handleAdd}>
              添加证书
            </div>
          )}
        </div>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <style jsx='true' global='true'>
          {`
            #fixed-award .ant-upload {
              margin-left: 5px;
            }
            #fixed-award .ant-upload-list {
              display: none;
            }
            #fixed-award .upload_box {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            #fixed-award .image {
              width: 50px;
              height: 50px;
              cursor: pointer;
            }
            #fixed-award .imageBox,
            .defBox {
              position: relative;
            }
            #fixed-award .imageBox::before {
              position: absolute;
              z-index: 1;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              opacity: 0;
              transition: all 0.3s;
              content: ' ';
              cursor: pointer;
            }
            #fixed-award .imageBox:hover::before {
              opacity: 1;
            }
            #fixed-award .icon-li {
              color: #fff;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              white-space: nowrap;
              transform: translate(-50%, -50%);
              opacity: 0;
              transition: all 0.3s;
              cursor: pointer;
            }
            #fixed-award .icon-li:hover {
              opacity: 1;
            }
            #fixed-award .icon-li .icon-delete {
              margin-left: 4px;
            }
          `}
        </style>
      </div>
    )
  }
}
