import React from 'react';
import { Form,Input } from 'antd';
import NHFormItem from "@components/common/NHFormItem";
const FormItem = Form.Item;

class PasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type:'password',
        }
    }

    render() {
        const {form} = this.props;
        const {type} = this.state;
        return (
            <Form>
               <NHFormItem id={'oldPassWord'} label={'旧密码'} form={form} required={true}>
                   <Input  type={type} placeholder={'请输入旧密码'}/>
               </NHFormItem>
                <NHFormItem id={'newPassWord'} label={'新密码'} form={form}
                            pattern={{pattern:/^[a-zA-Z0-9_]{0,16}$/, message: "请输入：a-zA-Z0-9_ 范围的值,不能超过16字符！"}}
                            required={true}>
                    <Input type={type}  placeholder={'请输入新密码'}/>
                </NHFormItem>
                <NHFormItem id={'verifyPassWord'} label={'确认密码'} form={form}
                            pattern={{pattern:/^[a-zA-Z0-9_]{0,16}$/, message: "请输入：a-zA-Z0-9_ 范围的值,不能超过16字符！"}}
                            required={true}>
                    <Input type={type}  placeholder={'确认新密码'}/>
                </NHFormItem>
            </Form>
        );
    }
}

export default Form.create()(PasswordForm);
