import React from 'react';
//import { Route , Switch , withRouter } from 'react-router-dom'
import {bdd_school,types,base_indexComponent,base_printComponent} from '@pages/SelfHelp/Bdd/Config'
import AuthorizedRoute from "../../route/AuthorizedRoute";

/**
 * @author qianzhiwei
 * @date 2020/09/25
 * @description 报到单动态路由
 */
export function RouterComponents(){
    let routes = bddRouter();
    return (routes.map(
        router => {
            return (
                <AuthorizedRoute 
                    path={ router.path }
                    key={ router.path }
                    component={ router.component } 
                /> 
            )
        }
    ))
}

function bddHistory(data, type) {
    return ({
        path: '/' + data.dm + type.value.replace(type.value.substr(0, 1), type.value.substr(0, 1).toUpperCase()),
        meta: data.mc + type.name,
        role: '',
        component: type.value === 'index' ? data.indexComponent : data.printComponent
    });
}

//组合路由
const bddRouter = () => {
    let routers = [];
    bdd_school.map(school => {
        types.map(type => {
            routers.push((bddHistory(school, type)))
        })
    })
    return routers;
}

//根据schoolcode获取相应的组件
export const getBddComponent = (school,type)=> {
    let res = undefined;
    var arr = [...bdd_school];
    arr = arr.filter(item => item.dm == school)
    if(arr.length>0){
        Object.getOwnPropertyNames(arr[0]).some((key)=>{
            if(key.includes(type)){
                res = arr[0][key];
                return true;
            }
        })
    }
    return res;
}

export const baseIndexComponent = base_indexComponent;
export const basePrintComponent = base_printComponent;

// function bddComponent(school, type) {
//     return '@pages/SelfHelp/Bdd/pages/' + school + '/' + type;
// }

// @withRouter
// class RouterComponents extends React.Component{
    
//     render () {
//         return (
//             //     <Switch>
//             //     {routes.map(
//             //         router => {
//             //             return (
//             //                 <AuthorizedRoute 
//             //                     path={ router.path }
//             //                     key={ router.path }
//             //                     component={ 
//             //                         () => {
//             //                             return props.router.component
//             //                         } 
//             //                     } 
//             //                 /> 
//             //             )
//             //         }
//             // )}
//             // </Switch>
//         )
//     }
// }

export default RouterComponents;