import React from 'react';
import { Form, Alert,Modal  } from 'antd';
import NHFetch from "@utils/NHFetch";
import moment from 'moment';
import './index.css';
import TitleTop from '@pages/Assembly/titleTop';

class KzxxFormcComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dmkmcList: {},
            previewVisible: false
        }
    }

    //获取下拉列表value对应的名称
    getDmkMc = (dmbz, zdbz) => {
        NHFetch("/proData/selectDataList", "GET", { sign: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[zdbz] = res.data;
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
  //获取联级下拉列表value对应的名称
  getCascaderDmkMc = (level,sign, cascaderValue,zdbz) => { 
    NHFetch("/proData/selectCascaderList", "GET", { level:level,sign: sign ,cascaderValue:cascaderValue})
        .then(res => {  
            if (res) {
                if (res.data) {
                    let dmkmcList = this.state.dmkmcList;
                    dmkmcList[level+zdbz] = res.data; 
                    this.setState({
                        dmkmcList: dmkmcList
                    })
                }
            }
        });
}
// 点击文件链接或预览图标时的回调
handlePreview = (fjmc, fjid) => { 

    if (fjmc==="isImage") {
        this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
    } else {
        window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
    }
};

//打开图片弹窗
showPreview = (url) => {
    this.setState({
        previewImage: url,
        previewVisible: true
    });
}
// 关闭图片弹窗
closeModal = () => this.setState({ previewVisible: false });
// 文件展示 
fileLists = (fileLists) => {
        {
            let items=[];
            for(let key in fileLists){
                let fileList = typeof fileLists[key] == 'string'?fileLists[key].split(";"):fileLists[key]; 
                items.push (
                    <div >
                        <a onClick={() => {
                         this.handlePreview("file",fileList[0]);
                        }}>{fileList[1]}</a> 
                    </div>
                )
            }
        return items;
        }
    };
//图片展示
imageLists = (imageLists) =>{
    {
        let items=[];
        for(let key in imageLists){
            let imageList = typeof imageLists[key] == 'string'?imageLists[key].split(";"):imageLists[key];
            items.push (
                <span>
                   <img style={{height:"80px" ,width:"80px"}} src={"anonymity/docrepo/download/file?attachmentId=" + imageList}
                    onClick={() => {
                        this.handlePreview("isImage",imageList[0]);
                    }}/>
                </span>
            )
        }
        return items;
    }
}
    render() {
        const { form, initData } = this.props;
        const { previewVisible,previewImage  } = this.state;
        //提示信息
        let AlertParams = {
            description: '',
            type: 'info'
        }
        //信息采集字段分类
        const infoItems = [];
        let reactid = 0;

        if (initData) {
            //表单信息、表单字段信息
            const { zdglVOs = [] } = initData;
            if (zdglVOs != null) {
                if (zdglVOs.length > 0) {
                    let array = [];
                    //循环过滤出多行文本框和附件的key，偶数在左，奇数在右，相应的同行的另一条文本也需要加宽
                    for (let key in zdglVOs) {
                        let { kjcs } = zdglVOs[key];
                        let kjcsjson = JSON.parse(kjcs);
                        // debugger
                        if (kjcsjson.type === "textarea" || kjcsjson.type === "oldupload" || kjcsjson.type === "upload" ) {
                            if (key % 2 === 0) { 
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) + 1);
                            } else {
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) - 1);
                            }
                        }
                    }
                    //显示表单字段信息
                    for (let key in zdglVOs) {
                        //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
                        let { zdbz, zdmc, kjcs, zdz, zdzt} = zdglVOs[key]; //表单字段
                        let zdzmc = '';
                        let kjcsjson = JSON.parse(kjcs);
                        if(kjcsjson.type === 'datepicker'||kjcsjson.type === 'datetimepicker'||kjcsjson.type === 'timepicker'||kjcsjson.type === 'monthpicker'||kjcsjson.type === 'rangepicker'){    //日期值展示阶段，从moment转为文字 
                            if(zdz){
                                zdzmc=moment(zdz).format(kjcsjson.format);
                            }
                        } else if (kjcsjson.type === "select") {
                            if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                                this.getDmkMc(kjcsjson.dataTag, zdbz);
                            }
                            let list = this.state.dmkmcList[zdbz];
                            list && list.map(item => {
                                if (item.VALUE === zdz) {
                                    zdzmc = item.LABEL;
                                }
                            })
                        }else if (kjcsjson.type === "multiselect") { 
                            if (kjcsjson.dataTag && !this.state.dmkmcList[zdbz]) {
                                this.getDmkMc(kjcsjson.dataTag, zdbz);
                            }
                            let list = this.state.dmkmcList[zdbz]; 
                            if(zdz ){ 
                                let multiselect=typeof zdz == 'string'?zdz.split(","):zdz;    
                                let multiselectList=[];
                                for(let i in multiselect){
                                    list && list.map(item => {
                                        if (item.VALUE === multiselect[i]) {
                                            multiselectList.push(item.LABEL);
                                        }
                                    })
                                }
                                zdzmc=multiselectList.join(",");
                         }
                        }else if (kjcsjson.type === "nhcascader") {   
                            if(zdz){ 
                                let nhcascaderList=typeof zdz == 'string'?zdz.split(","):zdz;     
                                let nhcascaderLists=[];    
                                for(let i in nhcascaderList){  
                                    let level=i;
                                    let c=i;
                                    let d=i;
                                    let a=++level;
                                    let b=--d;
                                    if (kjcsjson.dataTag && !this.state.dmkmcList[a+zdbz] ) {
                                        if(i == "0"){
                                            this.getCascaderDmkMc(a,kjcsjson.dataTag,'',zdbz) 
                                        }
                                        else{
                                            this.getCascaderDmkMc(a, kjcsjson.dataTag,nhcascaderList[b],zdbz) 
                                        } 
                                    }
                                        let list=this.state.dmkmcList[level+++zdbz];
                                        
                                        list && list.map(item => {   
                                            if (item.value === nhcascaderList[c]) { 
                                                nhcascaderLists.push(item.label);
                                            }
                                        }) 
                                }
                                zdzmc=nhcascaderLists.join("/");
                            }
                        }else if(kjcsjson.type === "switch"){
                            zdzmc = (zdz === "1" || zdz === true) ? kjcsjson.checkedText:kjcsjson.uncheckedText;
                        } else if(kjcsjson.type === "radio"){
                            kjcsjson.options&&kjcsjson.options.map((item,key)=>{
                                if(item.value === zdz){
                                    zdzmc = item.label;
                                }
                            })
                        }else if(kjcsjson.fileType === "file" && zdz  ){  
                            let fileLists=typeof zdz == 'string'?zdz.split(","):zdz;   
                            zdzmc=(
                                this.fileLists( fileLists)  
                            )
                        }else if(kjcsjson.fileType === "image" && zdz ){    
                            if (typeof zdz === 'object') { 
                                let array = [];
                                for (let item of zdz) {
                                    if (item.hasOwnProperty('uuid') && item['uuid']) {
                                        array.push(item['uuid']) 
                                    }
                                }
                                zdz= array
                            }else{
                                zdz=zdz.split(",");
                            } 
                            zdzmc=(
                                this.imageLists(zdz)  
                            )  
                        }else {
                            zdzmc = zdz;
                        }

                        let formItem;
                        formItem = (kjcsjson.type === 'textarea'||kjcsjson.type === "oldupload" ||kjcsjson.type === "upload" ? 
                        (
                            <div key={'div' + reactid++} 
                                style={ zdzt==="3" ? {display:"none"} : {}} 
                                styleName={"kzxx_item_textarea"}
                            >
                                <div styleName="kzxx_label">{zdmc}：</div>
                                <div styleName="kzxx_name_textarea">
                                    {
                                        zdzmc
                                    }
                                </div>
                            </div>

                        )
                        :
                        (
                            <div key={'div' + reactid++} 
                                style={ zdzt==="3" ? {display:"none"} : {}} 
                                styleName={"kzxx_item"}
                            >
                                <div styleName="kzxx_label">{zdmc}：</div>
                                <div styleName="kzxx_name">
                                    {
                                        zdzmc
                                    }
                                </div>
                            </div>

                        ));
                        infoItems.push(formItem);
                    }
                } else {
                    //未找到表单配置的采集字段信息
                    AlertParams['description'] = '当前未配置采集信息项';
                    AlertParams['type'] = 'warning';
                }
            }
        }


        return (
            <div>
                {infoItems.length > 0 &&
                    <div>
                        <TitleTop
                            title="扩展信息"
                            extra={false}
                        />
                        {
                            infoItems.length > 0 ?
                                <Form>
                                    <div className="p-sw-big">
                                        <div styleName="kzxx_list">
                                            {infoItems}
                                        </div>
                                    </div>
                                </Form>
                                :
                                <Alert style={{ marginTop: 20 }}
                                    {...AlertParams}
                                    showIcon
                                />
                        }
                         <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.closeModal}
                    >
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                        />
                    </Modal>
                    </div>
                }
            </div>
        )
    }

}

const KzxxFormc = Form.create()(KzxxFormcComponent);
export default KzxxFormc;