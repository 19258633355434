import React from 'react';

import NHFetch from '../../utils/NHFetch';


export default class Footer extends React.Component {

    constructor (props) {
        super(props)
        
        this.state = {
            copyright: ''
        }
    }

    componentDidMount() {
        const params = {}
        let url = 'api/zhxg-yxwz/getCopyrightInformation'

        // 根据环境变量确定使用哪个版权信息
        if(window.copyrightType === 'html') {
            url= '/zzbdCommon/getCssz'
            params.csbz = 'XTGL_BQXX_HTML'
        }

        NHFetch(url, 'GET', params).then(res => {
            if (res&&res.data) {
                this.setState({copyright: res.data});
            }
        });
    }

    render() {

        const {copyright} = this.state;

        return window.copyrightType === 'html' ? (
            <div style={{fontSize:'12px',minWidth: '1180px',color: '#fff',backgroundColor:'#05a4ff',textAlign:'center',lineHeight:'50px'}} dangerouslySetInnerHTML={{__html: copyright}}/>
        ) : (
            <div style={{fontSize:'12px',minWidth: '1180px',color: '#fff',backgroundColor:'#05a4ff',textAlign:'center',lineHeight:'50px'}}>
                {copyright}
            </div>
        )
    }

}