import React from 'react'
import { Table, Input, Icon, Form, message, Select,Checkbox,Row,Col } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { Flex } from '@components';
import { createUuid } from '@utils/NHCore'
import NHFetch from "@utils/NHFetch";
import '../index.css';

import NHFormItem from '@components/common/NHFormItem/index';
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'


const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};
const formItemLayout2 = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};

const messageLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 44 }
};

/**
 * 贷款情况-云南中医药大学
 */
class DkqkInitForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initFlag: false,
            dkqkData: {},
            cacheData: {},
            editFlag: false,
            dkqkShow: false,
            errorFiled: false,
            tsLxData:[],
            sfdk:'',
            checkedList:[]
        }
    }

    componentDidMount() {
        this.loadTsLxData();
        if (this.props.dkqkInitData) {
            let jttsqk = this.props.dkqkInitData.jttsqk;
            let checkedList =  jttsqk?jttsqk.split(',').map(String):[];
            this.setState({
                dkqkData: this.props.dkqkInitData,
                dkqkShow: this.props.dkqkInitData.sfdk == '1',
                checkedList: checkedList
            })
        }
    }

    submitCheck = (flag) => {
        const submitCheck = this.props.submitCheck;
        if (typeof submitCheck === "function") {
            submitCheck(flag);
        }
    }

    componentDidUpdate(preProps) { }

    componentWillReceiveProps(nextProps) {

        if ('dkqkInitData' in nextProps) {
            const dkqkInitData = nextProps.dkqkInitData;
            if (dkqkInitData) {
                let jttsqk = dkqkInitData.jttsqk;
                let checkedList =  jttsqk?jttsqk.split(',').map(String):[];
                this.setState({
                    dkqkData: dkqkInitData,
                    checkedList: checkedList
                });
                if (!this.state.initFlag) {
                    this.setState({
                        dkqkShow: dkqkInitData.sfdk == '1',
                        initFlag: true
                    })
                }
            }
        }
    }

    // 家庭特殊类型
    loadTsLxData = () => {
        this.getDmkData("DMK_YNZYYDX_YXXT_JTTSQK", (tsLxData) => {
        this.setState({ tsLxData });
        });
    };
    // 获取代码库数据
    getDmkData = (sign, callback) => {
        NHFetch("front/zhxg-unauth/yxwz/proData/selectDataList", "GET", {
        sign,
        }).then((res) => {
        if (res && res.code == 200) {
            const dataList = res.data.map((item) => {
            return {
                value: item.VALUE,
                label: item.LABEL,
            };
            });
            if (callback) callback(dataList);
        }
        });
    };

    cacheDkqkData = cb => {
        this.props.form.validateFields((err, values) => {
            const { errorFiled, sfdk, dkqkData, dkqkShow } = this.state
            if (err || errorFiled) return cb(false)

            let dataList = []

            if (dkqkShow) {
                dataList.push({
                    pkid: dkqkData.pkid,
                    sfdk: sfdk,
                    jttsqk: values.jttsqk
                })
            } else {
                dataList.push({
                    pkid: dkqkData.pkid,
                    sfdk: sfdk,
                    yhkh: []
                })
            }


            this.setState({
                editFlag: false,
            })

            const { cacheFormData } = this.props
            cacheFormData('dkqk', dataList, true, 1)
            cb(true)
        })
    }

    onJttsqkChangeListener = (checkedValues) => {
        console.info(checkedValues)
        this.setState({
            checkedList: checkedValues
        })

    };

    onSfdkChange = (value) => {
        this.setState({
            sfdk: value,
            dkqkShow: value == '1'
        })
    }

    render() {
        const { dkqkShow, dkqkData, editFlag,sfdk,tsLxData,checkedList } = this.state
        const { getFieldDecorator } = this.props.form;
        const { form, isRequired, editable } = this.props
        return (
            <div>
                <TitleTop
                    extra={editable}
                    title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
                    onEdit={() => {
                        this.setState({
                            editFlag: true,
                            cacheData: deepCopy(dkqkData),
                            sfdk: this.state.dkqkData.sfdk,
                            dkqkShow: this.state.dkqkData.sfdk == '1'
                        })
                        this.submitCheck(false);
                    }}
                    onSave={cb => {
                        this.cacheDkqkData(cb)
                    }}
                    onClose={() => {
                        this.setState({
                            editFlag: false,
                            errorFiled: false,
                            sfdk: this.state.dkqkData.sfdk,
                            dkqkShow: this.state.dkqkData.sfdk == '1'
                        })
                        this.props.form.setFieldsValue({
                            sfdk: this.state.dkqkData.sfdk
                        })
                        this.submitCheck(true);
                    }}
                />


                {
                    <div className="p-sw" >
                        <Form>
                            <div className="p-sw">
                                <div className="form_item" style={{}}>
                                    <FormItem
                                        {...formItemLayout}
                                        label="是否申请国家助学贷款"
                                    >
                                        {getFieldDecorator('sfdk', {
                                            initialValue: dkqkData.sfdk,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请选择（必选）"
                                                }],
                                        })(
                                            editFlag ?
                                                <Select placeholder="请选择" onChange={this.onSfdkChange}>
                                                    <Option value="1">
                                                        是
                                                    </Option>
                                                    <Option value="2">
                                                        否
                                                    </Option>
                                                </Select> : <span>{dkqkData.sfdk ? (dkqkData.sfdk == '1' ? '是' : '否') : '未填写'}</span>
                                        )}
                                    </FormItem>
                                </div>

                                {dkqkShow &&
                                    <div style={{display:'block',width:'100%'}}>
                                        <FormItem
                                            {...formItemLayout2}
                                            
                                            label="家庭是否存在以下特殊情形（可多选）"
                                        >
                                            {getFieldDecorator('jttsqk', {
                                                initialValue: checkedList,
                                                
                                            })(
                                                <CheckboxGroup
                                                    style={{ width: '100%' }}
                                                    onChange={this.onJttsqkChangeListener}
                                                    disabled={!editFlag}
                                                >
                                                    <Row>
                                                        {tsLxData.map(
                                                            (elem, index) => {
                                                                return (
                                                                    <Col
                                                                        key={index}
                                                                        span={6}
                                                                    >
                                                                        <Checkbox
                                                                            value={
                                                                                elem.value
                                                                            }
                                                                        >
                                                                            {elem.label}
                                                                        </Checkbox>
                                                                    </Col>
                                                                );
                                                            }
                                                        )}
                                                    </Row>
                                                </CheckboxGroup>
                                            )}
                                        </FormItem>
                                    </div>}
                                
                            </div>
                        </Form>
                    </div>
                }

            </div>
        )
    }
}

const DkqkFormYnzyydx = Form.create()(DkqkInitForm)
export default DkqkFormYnzyydx
