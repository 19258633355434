import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Input, Pagination, Icon, message,Spin } from 'antd';
import createHistory from 'history/createHashHistory';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import {getLoginUser,checkLogin} from '@utils/NHCore';
import { NHConfirm } from "@components/common/NHModal";
const Search = Input.Search;

import './index.css';
import NHFetch from "../../../../utils/NHFetch";
import NHUeditor from "../../../components/common/NHUeditor";

const history = createHistory();

const _Empty = props => {
    return (
        <div styleName="empty">
            <img src={require('@images/empty.png')} alt="" />
            <div className="mt-10">暂无相关问题的解答，你可以：</div>
            <div>
                换个<span className="text-blue">关键词</span>或点击“<span className="text-blue">
                    我要提问
                </span>”
            </div>
        </div>
    );
};

export default class Answer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isShow: false,
            search: '',
            wtfl:[],//问题分类
            searchParams:{},//自定义的搜索条件
            searchTextMap: {},//下拉框查询的值,用作查询
            sqlParams:{},//
            filters: {},//多选方式过滤的值
            sorter: {},//排序字段
            loading: false,
            pageData: {//分页设置的是三个参数
                total:0,
                pageSize: 4,//当前页默认多少条数据
                page: 1
            },
            getContent:undefined,//获取富文本编辑器的值
            searchVal: null,//是否搜索
        };
    }

    componentDidMount() {
        this.getWtfl();
        this.filterTableData();
    }

    getWtfl=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'GET', {
            sign: 'DMK_YXXT_WTFL'
        }).then(resData => {
            if (resData && resData.data) {
                this.setState({ wtfl: resData.data });
            }
        });
    }

    //重新加载数据数据的方法（从第一页开始）
    filterTableData = () => {
        this.setState((prevState, props) => ({
            pageData: {
                total: this.state.pageData.total,
                pageSize: this.state.pageData.pageSize,
                page: 1
            }
        }));
        this.readTableData();
    }

    //在当前页刷新数据
    readTableData = () => {
        let params = this.getFilterParams();
        this.setState({ loading: true});
        let url = this.props.url ? this.props.url : "/proData/gridList";
        return NHFetch(url, "POST", params)
            .then(res => {
                if (res) {
                    this.setState({
                        data: res.data.list,
                        pageData: {
                            total: res.data.total,
                            pageSize: this.state.pageData.pageSize,
                            page: this.state.pageData.page
                        }
                    })
                }
                this.setState({ loading: false })
            }).catch((res) => {
            this.setState({ loading: false })
            return res;
        });
    }

    getFilterParams = () => {
        let pagination = this.state.pageData;//分页的参数
        let filters = this.state.filters;//多选过滤的数据
        let sorter = this.state.sorter;//过滤的数据
        let searchTextMap = this.state.searchTextMap;//字段上搜索栏的值
        let searchParams = this.state.searchParams;//传递过来的查询参数
        let sqlParams = this.state.sqlParams;//在sql语句中的参数
        let initParams = this.state.initParams;//初始化参数
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            xsid = 'null';
        }
        sqlParams['xsid'] = xsid ;
            let params = {
            pagination: pagination,
            filters: filters,
            sorter: sorter,
            searchTextMap: searchTextMap,
            searchParams: searchParams,
            sqlParams: sqlParams,
            initParams: initParams,
            sign: 'zhxg_yxwz_cjwt_queryPage'
        };

        return params;
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }


    onChange = pageNumber => {
        this.setState((prevState, props) => ({
            pageData: {
                total: this.state.pageData.total,
                pageSize:this.state.pageData.pageSize,
                page: pageNumber
            }
        }), function () {
            let { searchVal } = this.state;
            if(searchVal) {
                this.onSearch(searchVal);
            }else {
                this.readTableData();
            }
        });
    };

    onSave = () => {

        let xsid = getLoginUser()?getLoginUser().userId:'';
        // if(!xsid){
        //     message.warn("未获取登录信息");
        //     return false;
        // }

        let content = this.state.getContent();
        if(!content){
            message.warn("请填写信息后再提交。");
            return ;
        }
        let params = {
            twr:xsid,
            wt:content
        }
        this.setState({ loading: true });
        NHFetch("/cjwt/insert", "POST", params)
            .then(res => {
                if (res && res.data ==1) {
                   message.success('问题已提交，等待管理员处理。');
                   this.setState({isShow:false},()=>{
                       this.readTableData();
                   })
                }else{
                    message.warn("问题提交有误，请重试");
                }
                this.setState({ loading: false })
            }).catch((res) => {
            message.warn("提交异常，请稍后再试。");
            this.setState({ loading: false })
            return res;
        });
    };

    onSearchRed = (text, search) => {
        //search有值时，做高亮匹配
        let text_new = this.replaceData(text);
        let _title = null;
        if(search) {
            _title = text_new;
        }else {
            _title = text;
        }
        if (search && text) {
            const reg = new RegExp(search, 'gi');
            const match = (_title && _title.match(reg)) || [];

            _title =
                _title &&
                _title.split(reg).map(
                    (item, index) =>
                        index > 0
                            ? [`<span class="text-red">${match[0]}</span>`,
                                  item
                              ]
                            : item
                );
            _title = _title.toString().replace(/&/g, "&amp;");
        }
        return _title;
    };

    onSearch = async(value) => {
        let pageData1 = this.state.pageData;
        await new Promise((resolve,reject)=> {
            this.setState({
                pageData: {
                    pageSize: 1073741824,
                }
            }, async() => {
                await this.readTableData();
                resolve();
            });
        }) 
        let { data } = this.state;
        let dataNew = [], dataNew1 = [];
        if(value && data){
            const reg = new RegExp(value, 'gi');
            const pageNum = pageData1.page;
            let startNum = pageNum == 1 ? 0 : (pageNum - 2)*4+4;
            let endNum = startNum + 4;
            data.forEach((item,index) => {
                let wt = this.replaceData(item.WT);
                let wtda = this.replaceData(item.WTDA);
                let wtfl = item.WTFL;
                let match = (wt && wt.match(reg));
                let match1 = (wtda && wtda.match(reg));
                let match2 = (wtfl && wtfl.match(reg));

                if(match || match1 || match2) {
                    dataNew.push(item);
                }
            })
            dataNew.forEach((item,index) => {
                if(index >= startNum && index < endNum) {
                   dataNew1.push(item); 
                }
            })

            this.setState({
                data: dataNew1,
                pageData: {
                    total: dataNew.length,
                    pageSize: 4,
                    page: pageNum
                },
                searchVal: value
            });
        }else {
            this.setState({ 
                searchVal: null,
                pageData: {
                    page: 1,
                    pageSize: 4,
                }
            }, () => {
                this.readTableData();
            })
        }
    };

    replaceData = (val) => {
        let valNew = val.replace(/<[^>]+>/ig, '');
        valNew = valNew.replace(/&lt;/g, "<");
        valNew = valNew.replace(/&gt;/g, ">");
        valNew = valNew.replace(/&nbsp;/g, " ");
        valNew = valNew.replace(/&#39;/g, "\'");
        valNew = valNew.replace(/&quot;/g, "\"");
        valNew = valNew.replace(/&amp;/g, "&");
        return valNew;
    }

    operationDzStatus=(pkid)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            message.warn("未获取登录信息!!!");
            return false;
        }
        let params={
            xsid:xsid,
            cjwtid:pkid,
        }
        NHFetch("/cjwt/operationDzStatus", "POST", params)
            .then(res => {
                this.readTableData();
            });
    }

    getContent=(getContent)=>{
        this.setState({getContent:getContent});
    }

    show=()=>{
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if(!xsid) {
            NHConfirm("当前未登录，是否继续提问？", () => {
                this.setState({
                    isShow: true
                });
            }, "warn");
        }else {
            this.setState({
                isShow: true
            });
        }
    }

    render() {
        const { loading, data, isShow, search } = this.state;

        return (
            <div styleName="qaa">
                <div styleName="bread">
                    <div className="w1180" >
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/' },
                                { name: '在线问答' }
                            ]}
                        />
                    </div>
                </div>
                <div className="w1180" style={{position:'relative'}}>
                    <div styleName="con">
                        <Flex className="mt-10" styleName="search_box">
                            <div styleName="text-sub label">问题咨询</div>
                            <Search
                                placeholder="请搜索问题"
                                value={search}
                                onChange={e => {
                                    this.setState({
                                        search: e.target.value
                                    });
                                }}
                                onSearch={value => {
                                    this.onSearch(value);
                                }}
                                style={{ width: 300 }}
                            />
                        </Flex>
                        <Flex className="mt-10" align="start">
                            <div styleName="text-sub label">相关搜索</div>
                            <Flex.Item styleName="tab_list">
                                {this.state.wtfl.map(
                                    (elem, index) => {
                                        return (
                                            <div
                                                key={index}
                                                styleName="tab_item"
                                                onClick={() => {
                                                    this.onSearch(elem.LABEL);
                                                }}
                                            >
                                                {elem.LABEL}
                                            </div>
                                        );
                                    }
                                )}
                            </Flex.Item>
                        </Flex>
                        {data[0] ? (
                            <div className="mt-20" styleName="list">
                                {data.map((elem, index) => {
                                    const { search } = this.state;
                                    const _title = this.onSearchRed(
                                        elem.WT,
                                        search
                                    );
                                    return (
                                        <div key={index} styleName="item">
                                            <Flex>
                                                <Flex.Item styleName="wt">
                                                    {/*{_title}*/}
                                                    <div dangerouslySetInnerHTML={{ __html: _title }}/>
                                                </Flex.Item>
                                                <div styleName="text-sub time">
                                                    {elem.TWSJ}
                                                </div>
                                            </Flex>
                                            <div
                                                styleName="nr"
                                                style={{paddingBottom:0}}
                                                dangerouslySetInnerHTML={{ __html: elem.WTDA }}
                                            />
                                            <div styleName="text-sub time time_2">
                                                {elem.HDSJ}
                                            </div>
                                            <Flex>
                                                {elem.DZ&&elem.DZ>0?<img
                                                    style={{marginTop:'5px'}}
                                                    styleName="zan"
                                                    src={require('@images/zan1.png')}
                                                    alt=""
                                                    onClick={ ()=>{this.operationDzStatus(elem.PKID)} }
                                                />:<img
                                                    style={{marginTop:'5px'}}
                                                    styleName="zan"
                                                    src={require('@images/zan.png')}
                                                    alt=""
                                                    onClick={()=>{this.operationDzStatus(elem.PKID)}}
                                                />}
                                               <div styleName="text-sub" style={{marginTop:'5px'}}>
                                                   {elem.DZCS>0?elem.DZCS:''}
                                                </div>
                                            </Flex>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <_Empty />
                        )}
                        <Flex justify="between" styleName="page_box">
                            <Flex>
                                <div>问题没有</div>
                                <img src={require('@images/jj.png')} alt="" />
                                <div
                                    styleName="tw_btn"
                                    onClick={this.show}
                                >
                                    我要提问!
                                </div>
                            </Flex>
                            <Pagination
                                defaultCurrent={1}
                                defaultPageSize={this.state.pageData.pageSize}
                                total={this.state.pageData.total}
                                onChange={this.onChange}
                            />
                        </Flex>
                        {isShow && (
                            <div>
                                <div styleName="tw_box">
                                    <Flex
                                        styleName="tw_box_top"
                                        justify="between"
                                    >
                                        <div>我要提问</div>
                                        <Icon
                                            type="close"
                                            onClick={() => {
                                                this.setState({
                                                    isShow: false
                                                });
                                            }}
                                        />

                                    </Flex>
                                    <Flex>
                                        <div style={{width:'100%'}}>
                                        <NHUeditor id="content"
                                                   getContent={this.getContent}
                                                   width={'100%'}
                                                   height={'420px'}
                                                   autoFloatEnabled={false}
                                        />
                                        </div>
                                    </Flex>
                                </div>
                                <Button
                                    className="ml-20 mt-20"
                                    type="primary"
                                    loading={loading}
                                    onClick={() => this.onSave()}
                                >
                                    提交
                                </Button>
                            </div>
                        )}
                    </div>
                    <div style={{width:'100%',position:'absolute',textAlign:'center',top:'300px',display:this.state.loading?'block':'none'}}><Spin size={'large'}/></div>
                </div>
            </div>
        );
    }
}
