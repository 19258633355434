import React, { Component, PropTypes } from 'react';
import { Button, message } from 'antd';
import './index.css';
import NHFetch from '@utils/NHFetch';
import { getLoginUser } from "@utils/NHCore";
import { query } from "@utils/request";
import { userLogout } from '../../actions/login.js';
import NHModal from "@components/common/NHModal";
import { createUuid } from '@utils/NHCore';
import UpdatePasswordForm from './updatePasswordForm';
const baseUrl = 'api/zhxg-yxwz/'

class LoginStuInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentInfo: {}, //学生信息
            imgSrc: '',//学生图片
            index: 1,
        };
    }

    componentDidMount() {
        const xsid = getLoginUser().userId;
        this.getStudentInfo(xsid);
        this.getStudentPhotoBase64(xsid, (data => {
            if (!data) return;
            this.setState({
                imageUrl: `data:image/jpeg;base64,${data}`
            })
        }))
        
        NHFetch('/zzbdCommon/getCsxx', 'GET', { csbz: 'YX_ZZBD_SFQYXGMM' }).then(
            resData => {
                if (resData && resData.data) {
                    console.log("?????????",resData.data)
                    this.setState({
                        index: resData.data,
                        //imgSrc:baseUrl +'newStudent/getImgByParam?pkid='+xsid,
                    });
                }

            }
        );

    }
    // 获取学生头像
    getStudentPhotoBase64 = async (userId, callback) => {
        const res = await query('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', { xsid: userId, type: 10 })
        if (!res || res.code != 200) return;
        callback(res.data)
    }
    getStudentInfo = (xsid) => {
        NHFetch('/newStudent/getStudentInfo', 'GET', { xsid: xsid }).then(
            resData => {
                if (resData && resData.data) {
                    this.setState({
                        studentInfo: resData.data,
                        //imgSrc:baseUrl +'newStudent/getImgByParam?pkid='+xsid,

                    });
                }

            }
        );
    };

    handleLogout = () => {
        userLogout();
    }
    handleUpdatePassword = () => {
        this.nhModal.show();
    }

    handleModalOk = () => {
        this.updatePasswordForm.validateFields((err, formData) => {
            if (err) {
                this.nhModal.stopLoading();
                return;
            }
            let oldPassWord = formData['oldPassWord'].trim();
            let newPassWord = formData['newPassWord'].trim();
            let verifyPassWord = formData['verifyPassWord'].trim();
            let i = 0;
            if (!oldPassWord) {
                i++;
                this.setFields('oldPassWord', '', '密码不能为空');
            }
            if (!newPassWord) {
                i++;
                this.setFields('newPassWord', '', '新密码不能为空');
            }
            if (!verifyPassWord) {
                i++;
                this.setFields('verifyPassWord', '', '确认密码不能为空');
            }

            if (i > 0) {
                return;
            }

            if (newPassWord !== verifyPassWord) {
                this.setFields('verifyPassWord', verifyPassWord, '新密码与确认密码不一致，请检查输入');
                return;
            }
            let params = {
                oldPassWord: oldPassWord,
                newPassWord: newPassWord,
            }
            NHFetch('/newStudent/updatePassword', 'POST', params).then(resData => {
                if (resData && resData.data) {
                    let data = resData.data;
                    if (data > 0) {
                        message.success('密码修改成功');
                        this.nhModal.close();
                    } else if (data == -2) {
                        message.warning('密码为空');
                    } else if (data == -3) {
                        message.warning('旧密码验证不通过，密码错误');
                        this.updatePasswordForm.setFields({
                            oldPassWord: {
                                value: oldPassWord,
                                errors: [new Error('旧密码验证不通过，密码错误。')],
                            },
                        });
                    } else {
                        message.warning('密码修改失败');
                    }
                } else {
                    message.warning('密码修改失败');
                }
                this.nhModal.stopLoading();
            }).catch(res => {
                message.error('密码修改异常');
                this.nhModal.stopLoading();
                return res;
            });
        });
    }

    setFields = (name, value, message) => {
        let obj = {};
        obj[name] = {
            value: value,
            errors: [new Error(message)],
        }
        this.updatePasswordForm.setFields(obj);
        this.nhModal.stopLoading();
    }

    handleImageErrored() {
        this.setState({
            imgSrc: require('@images/index/default.jpg')
        });
    }

    render() {
        const xsid = getLoginUser() ? getLoginUser().userId : undefined;
        const { studentInfo, imageUrl, index } = this.state;
        let showImg = false;
        if (studentInfo) {
            showImg = true;
        }
        return (
            <div styleName="grxx_con">
                <div className="_bg" styleName="grxx_con_l">
                    {showImg && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                            src={imageUrl ? imageUrl : ''}
                            onError={this.handleImageErrored.bind(this)}
                        />
                    )}
                    {!showImg && (
                        <img
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                            src={require('@images/index/default.jpg')}
                            alt=""
                        />
                    )}
                </div>
                <div styleName="grxx_con_r">
                    <div styleName="grxx_con_item">
                        姓名：{studentInfo['XM']}
                    </div>
                    <div styleName="grxx_con_item">
                        性别：{studentInfo['XB']}
                    </div>
                    {/*<div styleName="grxx_con_item">
                        学号：{studentInfo['XH']}
                    </div>*/}
                    <div styleName="grxx_con_item">
                        民族：{studentInfo['MZ']}
                    </div>
                    <div styleName="grxx_con_item">
                        专业：{studentInfo['ZY']}
                    </div>
                    <div styleName="grxx_con_item">
                        学院：{studentInfo['BM']}
                    </div>
                    <Button type={'primary'} style={{ marginTop: '4px' }} onClick={this.handleLogout}>退出登录</Button>
                    <Button type={'primary'} style={{ marginTop: '4px', marginLeft: '10px', display: index == 0 ?'none' : 'inline'}} onClick={this.handleUpdatePassword}>修改密码</Button>
                </div>
                <NHModal
                    ref={modal => this.nhModal = modal} title={'密码修改'} onOk={this.handleModalOk}
                >
                    <UpdatePasswordForm ref={modal => this.updatePasswordForm = modal} />
                </NHModal>
            </div>
        );
    }
}

export default LoginStuInfoForm;
