import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Button, Table, Icon, InputNumber, Pagination, message, Modal} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import '../pay/index.css';
import NHFetch from "@utils/NHFetch";
import {getLoginUser} from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import Qrcode from './qrCode';
const history = createHistory();
export default class WeChatPay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_3',
            hjmc: '',
            data: [],
            loading: false,
            pageData: {//分页设置的是三个参数
                total: 0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            zxjfInfo: {},                    //在线缴费相关信息
            hjje: 0,                          //缓交金额
            jfxxHz: {},                        //缴费信息汇总
            visible: false,
            flage: true,                        //是否存在需要缴费的项目
            selectData: [],          //选择的缴费项目
            token: '',               //防重令牌
            qrcode: '',              //缴费二维码
            selectedRowKeys: [],
            isSelectAll: false
        };
    }

    componentDidMount() {
        this.getCssz("YXXT_GD008_3_QBXM", flag => {
            this.setState({isSelectAll: flag !== null && flag === 'true'})
        })
        this.checkIsZzbdHjLinked();
        this.loadZxjfInfo();
        this.queryToken();
        this.queryQztjAndYlhj((operable) => {
            if (operable) {
            }
        });
    }

    getCssz = (csbz, callback) => {
        let params = { csbz: csbz };
        NHFetch('/zzbdCommon/getCssz', 'GET', params)
            .then(res => {
                if (res && res.code === 200) {
                    callback && callback(res.data)
                }
            });
    }

    //获取防重令牌
    queryToken = () => {
        NHFetch('/wxChatPay/queryToken', 'GET').then(res => {
            if (res && res.code === 200) {
                this.setState({
                    token: res.data
                })
            } else {
                message.error("获取令牌失败，请稍后再试")
            }
        })
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.code == 200) {
                    if (!res.data) {
                        this.queryOrderInfo();
                    }
                } else {
                    message.error("检查环节办理接口调用失败，请稍后再试")
                }
            });
    };

    //同步学生缴费情况信息
    queryOrderInfo = () => {
        const {mkbz} = this.state;
        NHFetch('/wxChatPay/queryOrderInfo', 'GET', {hjdm: mkbz}).then(res => {
            if (res && res.code == 200) {
                if (res.data == 1) {
                    this.reloadMenu();
                    this.getSfxxList();
                }
            }
        })
    }

    //获取缴费信息
    getSfxxList = () => {
        const {isSelectAll} = this.state;
        NHFetch('/jfxtUnauth/yxwzjfxx/getJfxxList', 'GET')//所有的收费数据
            .then(resData => {
                if (resData && resData.data) {
                    this.setState(
                        {data: resData.data}
                        , () => {
                            let list = this.state.data.filter((item) => item.QFJE > 0);
                            if (isSelectAll) {
                                this.setState({
                                    selectData: this.state.data.filter(item => item.QFJE > 0),
                                    selectedRowKeys: this.state.data.filter(item => item.QFJE > 0).map(item => item.SFXMID)
                                })
                            }
                            if (resData.data.length === 0) {
                                this.setState({flage: false})
                            } else {
                                if (list.length <= 0) {
                                    this.setState({flage: false})
                                }
                            }

                        });
                }
            });
    }

    //关闭微信支付订单
    closeWxPayOrder = () => {
        const {mkbz} = this.state;
        NHFetch('/wxChatPay/closeWxPayOrder', 'GET', {hjdm: mkbz}).then(res => {
            if (res && res.code === 200) {
                if (res.data === 1) {
                    this.createWxPayOrder();
                } else {
                    message.error("微信订单关闭失败，请稍后再试")
                }
            } else {
                message.error("微信订单关闭失败，请稍后再试");
            }
        })
    }

    //添加订单到业务系统
    createWxPayOrder = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const {data, selectData, token} = this.state;
        let jfje = 0.0; // 缴费金额
        let list = selectData.length > 0 ? selectData : data.filter(item => item.QFJE > 0 ? true : false);
        list.map(item => {
            jfje += item.QFJE;
        });
        jfje = Math.round(jfje * Math.pow(10, 2)) / Math.pow(10, 2);
        //需要支付的收费项目订单信息
        let params = {};
        params['xsid'] = xsid;
        params['paymentAmount'] = jfje;
        let sfxmList = [];
        for (let item of list) {
            let sfxmObj = {};
            sfxmObj['sfxmid'] = item.SFXMID;
            sfxmObj['qfje'] = item.QFJE;
            sfxmList.push(sfxmObj);
        }
        params['sfxmList'] = sfxmList;
        params['token'] = token;
        NHFetch('/wxChatPay/addOrderInfo', 'POST', params).then(res => {
            if (res && res.code === 200) {
                if (res.data == 1) {
                    //微信支付Native下单
                    this.toNativeOrder();
                }
            } else {
                message.error("创建业务订单操作失败，请稍后再试")
            }
        })
    }

    //Native下单
    toNativeOrder = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        const {mkbz} = this.state;
        let params = {
            xsid: xsid,
            hjdm: mkbz
        }
        NHFetch('/wxChatPay/toNativeOrder', 'POST', params).then(res => {
            if (res && res.code === 200) {
                if (res.data) {
                    this.setState({
                        qrcode: res.data,
                        visible: true
                    })
                }
            } else {
                message.error("创建微信订单操作失败，请稍后再试")
            }
        })
    }

    //获取在线缴费相关信息
    loadZxjfInfo = () => {
        const {mkbz} = this.state;
        NHFetch('/jfxtUnauth/yxwzjfxx/getCurrDdxx', 'GET', {hjdm: mkbz})
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        zxjfInfo: res.data,
                    });
                }

            });
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({nonOperationalData: res.data});
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({operable: false});
                    } else if (ylhj && ylhj.length > 0) {
                        this.setState({operable: false});
                    } else if (qzhj && qzhj.length > 0) {
                        this.setState({operable: false});
                    } else if (nohj) {
                        this.setState({operable: false});
                    } else {
                        this.getSfxxList();
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    onSave = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //获取选择的项目
    onRowSelect = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectData: selectedRows.filter(item => item.QFJE > 0),
        })
    }

    //网上支付点击事件
    showModal = () => {
        this.closeWxPayOrder();
    };

    //model关闭事件
    handleCancel = e => {
        this.setState({
            visible: false,
        }, () => {
            this.getSfxxList();
            this.reloadMenu();
            this.queryToken();
        });
    };

    render() {
        const {data, zxjfInfo, qrcode, selectedRowKeys, isSelectAll} = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'XH'
            },
            {
                title: '收费项目',
                dataIndex: 'SFXMMC',
            },
            {
                title: '应收金额（元）',
                dataIndex: 'YSJE',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'SSJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '缓交金额（元）',
                dataIndex: 'HJJE',
            },
            {
                title: '欠费金额（元）',
                dataIndex: 'QFJE',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            }
        ];

        let selectAll = {}
        if (isSelectAll) {
            selectAll = {
                selectedRowKeys,
                getCheckboxProps: record => ({
                    disabled: record !== null
                })
            }
        }

        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    {name: '首页', url: '/index'},
                                    {name: '自助报到', url: '/register'},
                                    {name: this.state.hjmc}
                                ]}
                            />
                            <div className="box">
                                <Hint/>
                                <TitleTop title={this.state.hjmc} extra={false}/>
                                <div className="p-sw mt-20">
                                    <Table
                                        ref={"scrollContainerRef"}
                                        bordered
                                        size="middle"
                                        rowKey={record => record.SFXMID}
                                        rowSelection={{
                                            ...selectAll,
                                            onChange: (selectedRowKeys, selectedRows) => this.onRowSelect(selectedRowKeys, selectedRows)
                                        }}
                                        pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                    />
                                    <div className="text-center mt-50">
                                        <div className="text-center mt-50">
                                            {this.state.flage == true && zxjfInfo.ZFD != '0' ? <Button onClick={() => this.showModal()}
                                                                                                       type="primary"
                                                                                                       htmlType="submit">网上支付</Button> : null}
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"网上缴费"} key={'GD008'} mkbz='GD008' nonOperationalData={this.state.nonOperationalData} />}
                {/*<Modal*/}
                {/*    title="支付详情信息"*/}
                {/*    visible={this.state.visible}*/}
                {/*    onOk={this.handleOk}*/}
                {/*    onCancel={this.handleCancel}>*/}
                {/*    <Order zxjfInfo={zxjfInfo} ddh={zxjfInfo.DDH} sfxmInfo={selectData.length > 0 ? selectData:data.filter(item => item.QFJE > 0 ? true : false)} />*/}
                {/*</Modal>*/}
                <Modal
                    title="二维码信息"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button onClick={this.handleCancel}>
                            关闭
                        </Button>,
                    ]}>
                    <Qrcode qrcode={qrcode}/>
                </Modal>
            </div>
        );
    }
}
