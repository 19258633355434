import React from 'react';
import { Route} from 'react-router-dom'
import {checkLogin} from "@utils/NHCore";

/**
 * @author weishihuai
 * @date 2018/7/19
 * @time 14:34
 * @Description: 为路由提供登录过滤，访问某些页面时，如果没有登录则跳转到登录页
 */
class  AuthorizedRoute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
      checkLogin(true);//登录检查
  }

  render() {
    const {component: Component, ...rest} = this.props;
    return (
      <Route {...rest} render={
        (props) => {
          return <Component {...props} {...rest} />
        }}
      />
    );
  }
}

export default AuthorizedRoute
