import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Pagination, Icon } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import './index.css';
import NHFetch from "@utils/NHFetch";
import createHashHistory from 'history/createHashHistory';
const hashHistory = createHashHistory();

export default class Notice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParams:{},//自定义的搜索条件
            searchTextMap: {},//下拉框查询的值,用作查询
            sqlParams:{},//
            filters: {},//多选方式过滤的值
            sorter: {},//排序字段
            loading: false,
            data: [],//数据
            pageData: {//分页设置的是三个参数
                total:0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            flbz:'',
            menuList:[],
        }
    }
    componentWillMount(){
        let flbz = this.getQueryString('flbz');
        this.setState({
            flbz:flbz
        })
    }


    componentDidMount(){
        let sqlParams = this.state.sqlParams;
        let flbz = this.getQueryString('flbz');
        sqlParams['flbz'] = flbz;
       this.filterTableData();
    }

    setMenuList=(menuList)=>{
        this.setState({menuList:menuList});
    }

    reload=()=>{
        let flbz = this.getQueryString('flbz');
        if(this.state.flbz != flbz){
            this.setState({
                flbz:flbz,
            },()=>{
               this.filterTableData();
            });
        }
    }

    //重新加载数据数据的方法（从第一页开始）
    filterTableData = () => {
        this.setState((prevState, props) => ({
            pageData: {
                total: this.state.pageData.total,
                pageSize: this.state.pageData.pageSize,
                page: 1
            }
        }));
        this.readTableData();
    }

    //在当前页刷新数据
    readTableData = () => {
        let params = this.getFilterParams();
        this.setState({ loading: true});
        let url = this.props.url ? this.props.url : "front/zhxg-unauth/yxwz/proData/gridList";
        NHFetch(url, "POST", params)
            .then(res => {
                if (res) {
                    this.setState({
                        data: res.data.list,
                        pageData: {
                            total: res.data.total,
                            pageSize: this.state.pageData.pageSize,
                            page: this.state.pageData.page
                        }
                    })
                }
                this.setState({ loading: false })
            }).catch((res) => {
            this.setState({ loading: false })
            return res;
        });
    }

    getFilterParams = () => {
        let pagination = this.state.pageData;//分页的参数
        let filters = this.state.filters;//多选过滤的数据
        let sorter = this.state.sorter;//过滤的数据
        let searchTextMap = this.state.searchTextMap;//字段上搜索栏的值
        let searchParams = this.state.searchParams;//传递过来的查询参数
        let sqlParams = this.state.sqlParams;//在sql语句中的参数
        let initParams = this.state.initParams;//初始化参数
        let flbz = this.getQueryString('flbz');
        sqlParams['flbz']= flbz;
        let params = {
            pagination: pagination,
            filters: filters,
            sorter: sorter,
            searchTextMap: searchTextMap,
            searchParams: searchParams,
            sqlParams: sqlParams,
            initParams: initParams,
            sign: 'zhxg_yxwz_xxfb_tzgg_queryPage'
        };

        return params;
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    click=(pkid)=>{
        hashHistory.push({
            pathname: '/notice/detail',
            search:'pkid='+pkid,
        });
    }

    onChange = pageNumber => {
        this.setState((prevState, props) => ({
            pageData: {
                total: this.state.pageData.total,
                pageSize:this.state.pageData.pageSize,
                page: pageNumber
            }
        }), function () {
            this.readTableData();
        });
    };
    render() {
        this.reload();
        let flbt =  '';
        if(this.state.menuList && this.state.menuList.length>0 && this.state.flbz){
            let bz = this.state.flbz;
            this.state.menuList.map((elem)=>{
                if(elem['FLBZ']==bz){
                    flbt = elem['TZBT'];
                }
            })
        }
        return (
            <div>

                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        title="消息发布"
                        setMenuList={this.setMenuList}
                        menuType='通知公告' flbz={this.state.flbz} />
                    <Flex.Item styleName="con_r">
                        <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '消息发布',url:'/notice' },
                                { name: flbt},
                            ]}
                        />
                        <div>
                            {this.state.data.map((elem, index) => {
                                return (
                                    <Flex onClick={()=>{this.click(elem.PKID) }} styleName="item" key={index}>
                                        <Icon
                                            type="caret-right"
                                            style={{ fontSize: 12 }}
                                        />
                                        <Flex.Item className="t-clamp-1 ml-10">
                                            {elem ? elem.TZBT : ''}
                                        </Flex.Item>
                                        <div styleName="time">{elem ? elem.FBSJ : ''}</div>
                                    </Flex>
                                );
                            })}
                        </div>
                        <Flex className="mt-50" justify="center">
                            <span className="mr-20">总共有 {this.state.pageData.total ? this.state.pageData.total : 0} 条数据</span>
                            <Pagination
                                defaultCurrent={1}
                                defaultPageSize={this.state.pageData.pageSize}
                                total={this.state.pageData.total}
                                onChange={this.onChange}
                            />
                        </Flex>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
