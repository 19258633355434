/*
 * @Description: 封装axios方法
 * @Author: admin
 * @Date: 2021-7-31 11:21:43
 * @LastEditors: piante
 * @LastEditTime: 2021-7-31 16:24:51
 */
import axios from 'axios';
import qs from 'qs';
window.flag = false; //弹出层标记
// 请求超时[config]:showTimeout    1.[true]:显示提示 2.["again"]:显示再次提示弹出框
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';
axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest'; //Ajax get请求标识
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'; //Ajax post请求标识
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'; //POST请求参数获取不到的问题
axios.defaults.headers.post['Accept'] = 'application/json'; //POST请求参数获取不到的问题
axios.defaults.headers.put['X-Requested-With'] = 'XMLHttpRequest'; //Ajax put请求标识
axios.defaults.headers.delete['X-Requested-With'] = 'XMLHttpRequest'; //Ajax delete请求标识


axios.interceptors.request.use(function (config) {
  let user = window.sessionStorage.getItem('userLogin');
  if (user) {
    user=JSON.parse(user)
    config.headers.post['loginUserOrgId'] = user.orgId;
    config.headers.post['loginUserId'] = user.userId;
    config.headers.get['loginUserId'] = user.userId;
    config.headers.get['loginUserOrgId'] = user.orgId;

    const Authorization = user.tokenId;
    if (Authorization != null && Authorization != '') {
      config.headers.delete['Authorization'] = Authorization;
      config.headers.get['Authorization'] = Authorization;
      config.headers.post['Authorization'] = Authorization;
      config.headers.put['Authorization'] = Authorization;
    }
  }
  //添加get请求参数
  if (config.method == 'get') {
    config.params = {
      ...config.params,
      _t: Date.parse(new Date()) / 1000,
    };
  } else {
    config.params = {
      ...config.params,
      _t: Date.parse(new Date()) / 1000,
    };
  }
  return config;
});

export default axios;
export function query(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
//post请求会有post参数也可能有get参数
export function post(url, datas, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function postForm(url, datas, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(datas), { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
// 使用Authorization统一验证登录
export function authLoginForm(url, headers, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(params), { headers })
      .then(res => {
        console.log(res);
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
export function get(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
export function insert(url, datas, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function update(url, datas, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function remove(url, datas, params, config = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, datas, { params: params, ...config })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function requestAll(...paramsFun) {
  return new Promise((resolve, reject) => {
    axios
      .all(...paramsFun)
      .then(
        axios.spread(function (...response) {
          let responseList = [];
          for (let res of response) {
            if (!res.status && res.response) {
              responseList.push(res.response.data);
            } else {
              responseList.push(res.data);
            }
          }
          return responseList;
        })
      )
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}