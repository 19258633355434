import React from 'react'
import {Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select} from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from "@utils/NHFetch";
import '../index.css';
import {deepCopy} from '@utils/deepCopy'
import NHFormItem from "@components/common/NHFormItem";
import moment from "moment/moment";


const FormItem = Form.Item
const CheckboxGroup = Checkbox.Group;

const formItemLayout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16}
};
const formItemLayout2 = {
  labelCol: {span: 6},
  wrapperCol: {span: 16}
};

const messageLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 44}
};

/**
 * 贷款情况-云南中医药大学
 */
class ByglInitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initFlag: false,
      byglData: {},
      cacheData: {},
      editFlag: false,
      byglShow: false,
      errorFiled: false,
      tsLxData: [],
      sffby: '',
      checkedList: []
    }
  }

  componentDidMount() {
    this.loadTsLxData();
    if (this.props.byglInitData) {
      let jttsqk = this.props.byglInitData.jttsqk;
      let checkedList = jttsqk ? jttsqk.split(',').map(String) : [];
      this.setState({
        byglData: this.props.byglInitData,
        byglShow: this.props.byglInitData.sffby == '1',
        checkedList: checkedList
      })
    }
  }

  submitCheck = (flag) => {
    const submitCheck = this.props.submitCheck;
    if (typeof submitCheck === "function") {
      submitCheck(flag);
    }
  }

  componentDidUpdate(preProps) {
  }

  componentWillReceiveProps(nextProps) {

    if ('byglInitData' in nextProps) {
      const byglInitData = nextProps.byglInitData;
      if (byglInitData) {
        let jttsqk = byglInitData.jttsqk;
        let checkedList = jttsqk ? jttsqk.split(',').map(String) : [];
        this.setState({
          byglData: byglInitData,
          checkedList: checkedList
        });
        if (!this.state.initFlag) {
          this.setState({
            byglShow: byglInitData.sffby == '1',
            initFlag: true
          })
        }
      }
    }
  }

  // 家庭特殊类型
  loadTsLxData = () => {
    this.getDmkData("DMK_YNZYYDX_YXXT_JTTSQK", (tsLxData) => {
      this.setState({tsLxData});
    });
  };
  // 获取代码库数据
  getDmkData = (sign, callback) => {
    NHFetch("front/zhxg-unauth/yxwz/proData/selectDataList", "GET", {
      sign,
    }).then((res) => {
      if (res && res.code == 200) {
        const dataList = res.data.map((item) => {
          return {
            value: item.VALUE,
            label: item.LABEL,
          };
        });
        if (callback) callback(dataList);
      }
    });
  };

  cacheByglData = cb => {
    this.props.form.validateFields((err, values) => {
      const {errorFiled, sffby, byglData, byglShow} = this.state
      if (err || errorFiled) return cb(false)

      let dataList = []
      console.log(values)
      let rwsj = values.rwsj ? moment(values.rwsj).format('YYYY-MM-DD') : null;
      let tysj = values.tysj ? moment(values.tysj).format('YYYY-MM-DD') : null;
      let byjyzz = values.byjyzz;
      let byjyzznx = values.byjyzznx
      let byjyzzje = values.byjyzzje
      if (byglShow) {
        dataList.push({
          pkid: byglData.pkid,
          sffby: sffby,
          jttsqk: values.jttsqk,
          rwsj,
          tysj,
          byjyzz,
          byjyzznx,
          byjyzzje
        })
      } else {
        dataList.push({
          pkid: byglData.pkid,
          sffby: sffby,
          yhkh: []
        })
      }


      this.setState({
        editFlag: false,
      })

      const {cacheFormData} = this.props
      cacheFormData('bygl_ynzyy', dataList, true, 1)
      cb(true)
    })
  }
  

  onSfdkChange = (value) => {
    this.setState({
      sffby: value,
      byglShow: value == '1'
    })
  }

  render() {
    const {byglShow, byglData, editFlag, sffby, tsLxData, checkedList} = this.state
    const {getFieldDecorator} = this.props.form;
    const {form, isRequired, editable} = this.props
    return (
        <div>
          <TitleTop
              extra={editable}
              title={<span><span
                  style={{display: isRequired ? '' : 'none', color: 'red'}}>*</span>{this.props.initData.FLMC}</span>}
              onEdit={() => {
                this.setState({
                  editFlag: true,
                  cacheData: deepCopy(byglData),
                  sffby: this.state.byglData.sffby,
                  byglShow: this.state.byglData.sffby == '1'
                })
                this.submitCheck(false);
              }}
              onSave={cb => {
                this.cacheByglData(cb)
              }}
              onClose={() => {
                this.setState({
                  editFlag: false,
                  errorFiled: false,
                  sffby: this.state.byglData.sffby,
                  byglShow: this.state.byglData.sffby == '1'
                })
                this.props.form.setFieldsValue({
                  sffby: this.state.byglData.sffby
                })
                this.submitCheck(true);
              }}
          />


          {
            <div className="p-sw">
              <Form>
                <div className="p-sw">
                  <div className="form_item" style={{}}>
                    <FormItem
                        {...formItemLayout}
                        label="是否有当兵入伍经历"
                    >
                      {getFieldDecorator('sffby', {
                        initialValue: byglData.sffby,
                        rules: [
                          {
                            required: true,
                            message: "请选择（必选）"
                          }],
                      })(
                          editFlag ?
                              <Select placeholder="请选择" onChange={this.onSfdkChange}>
                                <Option value="1">
                                  是
                                </Option>
                                <Option value="2">
                                  否
                                </Option>
                              </Select> : <span>{byglData.sffby ? (byglData.sffby == '1' ? '是' : '否') : '未填写'}</span>
                      )}
                    </FormItem>
                  </div>

                  {byglShow &&
                      <div style={{display: 'block', width: '100%'}}>
                        <NHFormItem  formItemLayout={formItemLayout2} id={'rwsj'} required={true} form={form}  label="入伍时间" initialValue={byglData.rwsj ? moment(byglData.rwsj, 'YYYY-MM-DD') : null}>
                          <DatePicker disabled={!editFlag} onChange={this.timeLqsjData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} />
                        </NHFormItem>
                        
                        <NHFormItem  formItemLayout={formItemLayout2} id={'tysj'} required={true} form={form}  label="退役时间" initialValue={byglData.tysj ? moment(byglData.tysj, 'YYYY-MM-DD') : null}>
                          <DatePicker disabled={!editFlag} onChange={this.timeTysjData} placeholder={'选择日期...'} format={'YYYY-MM-DD'} />
                        </NHFormItem>
                        
                        <NHFormItem formItemLayout={formItemLayout} id={'byjyzz'} required={true} form={form}  label="是否享受过兵役教育资助" initialValue={byglData.byjyzz}>
                          <Radio.Group  disabled={!editFlag}   style={{display:"flex",margin:"10px"}} >
                            <Radio value={'1'}>是</Radio>
                            <Radio value={'0'}>否</Radio>
                          </Radio.Group>
                        </NHFormItem>
                        <NHFormItem formItemLayout={formItemLayout} id={'byjyzznx'} required={true} form={form}  label="服兵役教育资助年限" initialValue={byglData.byjyzznx}>
                          <InputNumber  disabled={!editFlag} />
                        </NHFormItem>
                        <NHFormItem formItemLayout={formItemLayout} id={'byjyzzje'} required={true} form={form}  label="服兵役教育资助金额（元）" initialValue={byglData.byjyzzje}>
                          <InputNumber
                              disabled={!editFlag}
                          />
                        </NHFormItem>
                      </div>}

                </div>
              </Form>
            </div>
          }

        </div>
    )
  }
}

const ByglFormYnzyydx = Form.create()(ByglInitForm)
export default ByglFormYnzyydx
