import NHFetch from "@utils/NHFetch";
import { message} from 'antd';
import createHistory from 'history/createHashHistory';
const history = createHistory();
//获取高度
export const getSuitHeight = (node) => {
    var height=SuitHeight(node)-2;//设置合适高度的时候减去两个边框的高度，可以保证紧密镶嵌式不会因为上下边框产生滚动条
    return height;
}
//设置高度
/*setSuitHeight=function(node,flag){
    var height=SuitHeight(node)-2;//设置合适高度的时候减去两个边框的高度，可以保证紧密镶嵌式不会因为上下边框产生滚动条
    node.outerHeight=height;
    if(flag!==false){
        height.style.display='auto';
    }
    //this.addClass("nh-needsuit")
}*/

function SuitHeight(node){
    var parentObj=node.parentNode;
    //获得parentObj所有直接非grid子节点
    var childrenObj=parentObj.childNodes;
    var elseChildrenHeight=0;
    for(var i=0;i<childrenObj.length;i++){
        if(childrenObj[i].nodeName == "#text" && !/\s/.test(childrenObj.nodeValue)){
            continue;
        }
        if(childrenObj[i]!=node && childrenObj[i].tagName!="SCRIPT"&& childrenObj[i].tagName!="STYLE"
            && childrenObj[i].style.display!="none" && childrenObj[i].style.position!="absolute"
            ){//吧script的高度也排除掉
            let marginTop=getDomStyle(childrenObj[i],"marginTop")?parseInt(getDomStyle(childrenObj[i],"marginTop")):0;
            let marginBottom=getDomStyle(childrenObj[i],"marginBottom")?parseInt(getDomStyle(childrenObj[i],"marginBottom")):0;
            if(childrenObj[i].offsetHeight && childrenObj[i].offsetHeight>0){
                //获得所有非本元素的其他父类子元素的margin的值
                elseChildrenHeight+=childrenObj[i].offsetHeight+marginTop+marginBottom;
            }
        }
    }
    //父级对象的高度-其他对象的高度
    var gridSuitHeight=parentObj.clientHeight-elseChildrenHeight;
    return gridSuitHeight;
}

//获取UUID
export const createUuid= () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

export const getStyle = (element, attr) => {
    return getDomStyle(element, attr);
}

function getDomStyle(curEle,attr){
    if("getComputedStyle" in window){
        return window.getComputedStyle(curEle,null)[attr];
    }else{
        if(attr === "opacity"){
            let val = curEle.currentStyle["filter"];
            let reg = /^alpha\(opacity=(\d+(?:\.\d+)?)\)$/i;
            return reg.test(val)?reg.exec(val)[1]/100:1;
        }else{
            return curEle.currentStyle[attr];
        }
    }
}

//判断该用户是否存在该权限
export const hasAuth = (auth) => {
    const initState = sessionStorage.getItem('userLogin') ? JSON.parse(sessionStorage.getItem('userLogin')) : {}
    const auths = initState.authorization?initState.authorization:{};
    return auths[auth]!==undefined;
}

//获取登陆用户信息
export const getLoginUser = (auth) => {
    const loginUser = sessionStorage.getItem('userLogin') ? JSON.parse(sessionStorage.getItem('userLogin')) : {authorization:{},menus:[]}
    return loginUser;
}

export const checkLogin = (isLink,index)=>{
    let userId = getLoginUser() ? getLoginUser().userId : '';
    if(!userId){

        if(window.casLogoutUrl && window.casLogoutUrl!=''){
            window.location=window.casLogoutUrl;
        }

        // message.warning("未获取登录信息。");
        if(isLink){
            history.push({
                pathname:index?index:'/index',
            });
        }
        return false;
    }
    return true;
}

/**
 * 根据学生ID获取学生信息
 * @author weishihuai
 * @date 2018/7/13
 * @time 11:43
 * @param xsid 学生ID
 * @param callback 回调方法
 */
export const getLoginUserInfo = (xsid, callback) => {
    if (!xsid) {
        return;
    }
    NHFetch('api/zhxg-yxwz/newStudent/' + xsid + '/getUserInfoByXsid', 'GET')
        .then(response => {
            if (response && response.data) {
                if (callback) {
                    callback(response.data);
                }
            } else {
                message.error("抱歉，未能查询到相关学生信息，请稍后重试！");
            }
        })
};


/**
 * 判断浏览器是否是IE内核，如果是IE内核就不让访问
 */
function isIE() {
    if (!!window.ActiveXObject || "ActiveXObject" in window)
     return true;
     else
     return false;
  }
  export const browserJudge = (func) => {
    if (isIE()) {
      var str = "<font size='5'>你的浏览器版本过低，无法完整的体验本系统的所有功能！</font>";
      var str2 = "推荐使用:<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:#cc0;font-size:15px;font-weight:600;'>谷歌</a>,"
        + "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%81%AB%E7%8B%90%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:#cc0;font-size:15px;font-weight:600;'>火狐</a>,"
        + "<a href='https://www.baidu.com/s?ie=UTF-8&wd=%E7%8C%8E%E8%B1%B9%E6%B5%8F%E8%A7%88%E5%99%A8' target='_blank' style='color:#cc0;font-size:15px;font-weight:600;'>猎豹</a>," +
        "<a href='https://www.baidu.com/s?ie=UTF-8&wd=360%E6%B5%8F%E8%A7%88%E5%99%A8%0A' target='_blank' style='color:#cc0;font-size:15px;font-weight:600;'>360浏览器</a>等双核急速模式";
      document.getElementById("root").innerHTML="<table style='width:100%;height:100%;background-color:#fff;z-index:99999;position:absolute;'><tr><td align='center'>" +
      "<div style='text-align:center; width:800px;border:1px solid #0099CC;background-color:#99CCFF;top:0;left:0;z-index:1234;border-radius:5px;padding:2px;'>" +
      "<div align='left' style='padding:2px;padding: 8px;font-size: 15px;font-weight:700;border-radius: 5px 5px 0px 0px;color:#fff;'>Web项目体验性研究提示：</div>" +
      "<div style='background-color:#fff;height:300px;border-radius:0px 0px 5px 5px;border:1px solid #0099CC'><h2 style='padding-top:80px;margin:0'><strong>" + str + "<br/></strong></h2><p>" +
      str2 + "</p><h2 style='margin:0'><strong>如果你的使用的是双核浏览器,请切换到极速模式访问<br/></strong></h2></div></div></td></tr></table>";
    }else{
      func && func();
    }
  }