import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import './index.css';
import NHFetch from "@utils/NHFetch";

export default class NoticeDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            data:{},
            loading:false,
            flbz:'',
            pkid:'',
        }
    }

    componentWillMount(){
        let pkid=this.getQueryString("pkid");
        this.setState({pkid:pkid});
    }

    componentDidMount(){
        let pkid=this.getQueryString("pkid");
        this.setState({loading:true});
        this.reload(pkid);
    }

    shouldComponentUpdate(){
        let pkid=this.getQueryString("pkid");
        if(pkid && pkid!=this.state.pkid){
            this.setState({pkid:pkid},()=>{
                this.reload(pkid);
            });
        }
        return true;
    }

    reload=(pkid)=>{
        NHFetch("front/zhxg-unauth/yxwz/getXxfbnrDetail", "GET",{pkid:pkid})
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        data: res.data,
                    });
                }
                this.setState({ loading:false})
            })
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    render() {
        const data = this.state.data;
        return (
            <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu title="消息发布" menuType='通知公告' flbz={data.flbz}/>
                    <Flex.Item styleName="con_r">
                        <Breadcrumb
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '消息发布', url:'/notice'},
                                { name: data.flbt, url:'/notice',value:{flbz:data.flbz}},
                                { name: data.tzbt }
                            ]}
                        />
                        <div styleName="top">
                            <div styleName="title">{data.tzbt}</div>
                            <div styleName="sub">
                                <span>发布人：{data.yhbh}</span>
                                <span className="ml-20">发布时间：{data.fbsj}</span>
                            </div>
                        </div>
                        <div styleName="con">
                            <div  dangerouslySetInnerHTML={{__html:data.tznr}} ></div>
                        </div>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}
