import React from 'react';
import './index.css';
import {Carousel} from 'antd';
import NHFetch from "../../../utils/NHFetch";
import { Flex } from '@components';
import createHashHistory from 'history/createHashHistory';
const hashHistory = createHashHistory();
export default class Link extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            sylj: [], //首页链接
        }
    }

    componentWillMount(){
        this.getSyljList();
    }

    getSyljList = () => {
        NHFetch('api/zhxg-yxwz/sylj/getSyljList', 'GET').then(resData => {
            if (resData && resData.data) {
                let size=4;
                let sylj = this.sliceArr(resData.data,size);
                this.setState({ sylj:sylj});
            }
        });
    };

    //快速导航链接跳转
    handleChange = (url,ljlx,flbz)=> {
        if(ljlx=='1'){
            hashHistory.push({
                pathname: '/notice',
                search:'flbz='+flbz,
            });
        }else{
            window.open(url);
        }
    };

    sliceArr=(array, size)=>{
        let result = [];
        for (let x = 0; x < Math.ceil(array.length/size); x++) {
            var start = x * size;
            var end = start + size;
            let arr = array.slice(start,end);
            let fill=0;
            if((fill = size-arr.length)>0){
                for(let i=0;i<fill;i++){
                    arr.push({});
                }
            }
            result.push(arr);
        }
        return result;
    }

    render() {
        const {_boxTop} = this.props;
        const settings = {
            dots: true,
            infinite: true,
            speed: 10000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div styleName="cjyy">
                <_boxTop
                    img={require('@images/app.png')}
                    title="首页链接"
                    sub="Home page link"
                />
                <Carousel {...settings} autoplay>
                    {this.state.sylj.map((elem, index) => {
                        return (
                            <div key={index}>
                                <Flex styleName="ksdh_con">
                                    {elem.map((e,i) => {
                                        let img = e['LJTP'];
                                        let url = e['LJDZ'];
                                        let ljlx = e['LJLX'];
                                        let flbz = e['FLBZ'];
                                        let ljmc = e['LJMC'];
                                        let isEmpty = JSON.stringify(e) == "{}";
                                        return(
                                            isEmpty
                                                ?
                                                <Flex.Item key={index+i}></Flex.Item>
                                                :
                                                <Flex.Item key={index+i} styleName="ksdh_item">
                                                    <img
                                                        src={img?require(`@images/index/${img}`):''}
                                                        alt=""
                                                        style={{display:'inline-block'}}
                                                        onClick={() => {
                                                            this.handleChange(url,ljlx,flbz);
                                                        }}
                                                    />
                                                    <p
                                                        onClick={() => {
                                                            this.handleChange(url,ljlx,flbz);
                                                        }}
                                                    >
                                                        {ljmc}
                                                    </p>
                                                </Flex.Item>
                                        )
                                    })
                                    }
                                </Flex>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}